import React, { useState, useEffect, useImperativeHandle } from "react";
import { mixArray, isEqual, sleep , isEmpty, element} from "../../../Utils/helper";
import "../../Common/commonStyle.css";  

const FindOddOne = (  { data },ref ) => {
const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data

const [score, setScore] = useState(0); 
const [MATRIX, setMatrix] = useState(Settings?.layoutrange?.split("x")); 
const [frameIndex, setFrameIndex] = useState(1); 
const [frameQuiz, setFrameQuiz] =useState([]);
const [isGameOver, setGameOver] =useState(false);
const totalFrames= Object.keys(Settings?.gameFrames)?.length || 0

useImperativeHandle(ref, () => ({
  GameStart: () => {  gameSetup();  },
  getScore: () => { return score; }
}));

useEffect(() => {  if (Settings?.gameFrames?.Frame1 > 0) gameSetup(); setLoadContent(false); }, []);  
const gameSetup = () => { setScore(0); setFrameIndex(1); prepareQuiz("Frame1"); setGameOver(false) };
// next frame move
useEffect(()=>{ if(frameIndex>totalFrames) {GameFinsh(true); return; }  setGameOver(false); prepareQuiz("Frame"+frameIndex)   },[frameIndex] ) 



const prepareQuiz = (index)=>{
  let finalQuiz=[];  
  // mixArray(Settings?.gameFrames[index])?.map((val, inx)=>{ 
  // let finalRow=[]
  // // getting odd image and even image
  // let oddVal=val.filter(obj => obj.type === "oddElem");  oddVal[0]={...oddVal[0],isSelected:false};
  // // three image and fourth odd image fill     
  // ([...new Array(parseInt(MATRIX[0]) || 4)]).map(()=>{
  // let evenVal=val.filter(obj => obj.type === "evenElem");  evenVal[0]={...evenVal[0],isSelected:false};  
  // finalRow.push(Object.create(evenVal[0])) 
  // })
  // finalRow[0]=oddVal[0] 
  // // pushing one row
  // finalQuiz.push(mixArray(finalRow))   
  // })
  // // setting final rows
  (Settings?.gameFrames[index])?.map((val, inx)=>{  finalQuiz.push(mixArray([...val]))  }) 
  setFrameQuiz(finalQuiz);  
  } 

const checkAnswer = async (row,col,type) => {   
  setActionCount(obj=>obj+1);   
  let temp=Object.create(frameQuiz); 
  
  //  temp[row]?.map((valRow)=>{ return {...valRow,isSelected:false}    }) 
for(let i=0;i< temp[row].length;i++){  temp[row][i]={...temp[row][i],isSelected:false}} 
  temp[row][col]={...temp[row][col],isSelected:true};  
 
  setFrameQuiz(temp);   
  let isCompleted=true, tempScore=score || 0;
  frameQuiz?.map((obj, inx)=>{ //  checking each row
  let isRowSelected=false;
  obj?.map((val,inx)=>{ if(val?.isSelected){ isRowSelected=true; if(val?.type==="oddElem") tempScore+=1; return true }  }) // checking columns in row 
  if(isRowSelected===false)isCompleted=false  // if one row not selected the frame game not finished
  })
  if(isCompleted){ setGameOver(true)
    setScore(tempScore)

  await sleep(1000)
  setFrameIndex(obj => obj+1)
  } 
  }; 



  return ( 
      <div className="flex flex-1 w-[90%] min-w-[300px]  sm:w-[75%]  h-[85%] m-auto justify-evenly flex-col "   draggable={false} id={"gamePanel"+DIVID}>   
        {frameQuiz?.map((obj, row) => { 
          let rowLen=100/obj.length;rowLen-=1;
      return (<div key={"ImageRow_"+row} className="flex  justify-evenly items-center p-[3px] mt-[1%] bg-white shadow-sm  w-full rounded-xl"  draggable={false} >
                 <div className="w-4/5 transition-all duration-500 flex justify-evenly items-center ">
        {obj?.map((val, col) => {
          return (  <div  key={"imageCard_"+col} onClick={()=>checkAnswer(row,col,val.type)} style={{minWidth:rowLen+"%"}}
          className={`cursor-pointer  transition-all duration-500  h-full max-h-[140px]  p-2 flex grow  aspect-square rounded-md hover:shadow-lg  border-3 `

+( 
(val?.isSelected && isGameOver)?(
  (val?.type==="oddElem")? (`shadow-md   border-[#38af38f0]` ): (`shadow-md   border-[#e17c7c]`)
):
(val?.isSelected)? `shadow-md border-[lightgray]` : `shadow-none border-white`) 
          
          } >  
          <div className="flex m-auto max-h-full aspect-square">
          <img src={val?.imageUrl} alt={"img_" + col} className="noselect "  draggable={false}  /> </div></div>);
        })}
        </div>

        </div>)
        })} 
      </div> 
  );
};

export default React.forwardRef(FindOddOne);
