import React, { useEffect, useState, useImperativeHandle } from "react"; 
import { element,  getImageOrignalSize,  isEmpty,  isNotEmpty,  mixArray,  sleep } from "../../../Utils/helper"; 
import "../../Common/commonStyle.css";
import { getImageBase64 } from "../../../../backend/templates";


const FindFlow = ( { data },ref ) => { 
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID,setStartTime} = data
   
  const LayoutRange = Settings?.layoutrange?.split("x");
  const [score, setScore] = useState(0);
  const [gameStage, setGameStage] = useState(0);
  const [Action, setAction] = useState(0);
  const [answerFlow, setAnswerFlow] = useState([]);
  const [userAnswer, setUserAnswer] = useState([]);
  const [isBlinking, setIsBlinking] = useState(false);
  const [curGameState,setcurGameState] = useState(false) 
  const [isStarted,setStarted] = useState(false) 
  const [base64, setBase64] = useState("")

  let CuurentGameFrame = Settings?.gameStage[gameStage];  
  let levels={ "easy":800,  "normal":500,  "medium":400, "hard":150 } 
  let flowSpeed = levels[CuurentGameFrame?.flowSpeed] || 1000; 
  const NoOfCard = [...new Array(LayoutRange[0] * LayoutRange[1])]

useImperativeHandle(ref, () => ({ 
  GameStart: () => { gameSetup();blinkCard();   },
  getScore : ()=>{return score}
}));  

async function preloadBase64(){   
  if(isEmpty(base64) ) {
  let size = await getImageOrignalSize(Settings?.imageSrc)
  let bas=await getImageBase64(Settings?.imageSrc,size[0],size[1])
  setBase64(bas) 
}}

useEffect(() => { if (Settings?.gameStage.length > 0) gameSetup(); preloadBase64();  setLoadContent(false);}, []); 
const gameSetup = () => { setStarted(false);  setGameStage(0);   setScore(0);preloadBase64()   }; 

const blinkCard = async () => { 
  setIsBlinking(true); 
  await sleep(250); setUserAnswer([]); await sleep(250); setStarted(true); 
  let inx = 0, card = [];
  let RandomNumber=mixArray([...Array(LayoutRange[0] * LayoutRange[1]).keys()])
//   while (card.length < CuurentGameFrame?.flowCount) {
//     let RandomNumber = Math.round(Math.random() * (LayoutRange[0] * LayoutRange[1]));
//     if (card.indexOf(RandomNumber) < 0 && RandomNumber != CuurentGameFrame?.flowCount) {
//     card.push(RandomNumber);
//     } 
// }  
  while (inx < parseInt(CuurentGameFrame?.flowCount)) {
    card.push(RandomNumber[inx]);
      setAction(card[inx])
      await sleep(flowSpeed)
      inx++
  }  
  setAction(null); setAnswerFlow(card); setIsBlinking(false);
}
 
  
const flowClick = async (val) => { 
  if (isBlinking) return;
  setcurGameState((answerFlow[+userAnswer.length] === val) ? false : true)

  setUserAnswer(obj => [...obj, val])
  let tempUserAnswer=[...userAnswer, val]
  
  if(answerFlow.length === userAnswer.length + 1 ) {
    setIsBlinking(true);
      await sleep(flowSpeed)
      setActionCount(obj => obj +1)
      if(tempUserAnswer.toString() === answerFlow.toString()) setScore(obj => obj+1);
      if(Settings?.gameStage?.length  === gameStage + 1 ) {GameFinsh(true);setGameStage(0)}
      else {  setGameStage(obj => obj + 1); blinkCard() }
  } 

}
 
 
    return ( 
      <div className={`w-[90%] sm:w-[85%] md:w-[75%] m-auto h-[60%] sm:h-[75%] min-h-[250px] min-w-[250px] md:h-[85%] gap-2 sm:gap-3  md:gap-4  grid flex-col`} style={{ gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`, gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)`, }} id={"gamePanel"+DIVID} >
          {
              NoOfCard.map((val, inx) => { 

                  let bgback = Settings?.cardtype === "image" ? `url(` + (isEmpty(base64)? Settings?.imageSrc : base64) + `) 0% 0% / 50% 63%, white` : (Settings?.color || "#568AFC")
                  if(!isStarted) bgback="white";

                  let BorderColor 

                  if(userAnswer.length !== answerFlow.length){
                      BorderColor =  ((userAnswer.indexOf(inx) > -1) || Action === inx) ? Settings?.color : "white" ;
                  } 
                  
                  return (
                      <div
                          key={inx}
                          style={{ background: (userAnswer.indexOf(inx) > -1 || Action === inx) ? bgback : "white", duration: flowSpeed, iterations: 1,borderColor:BorderColor }}
                          className={`  
                          fitImageBg  transition-all  border-2 rounded-[10px]
                            ${answerFlow.length  === userAnswer.length  ? (userAnswer.indexOf(inx) > -1)  ? curGameState ? "border-red-600" : "border-green-600" :"" :"border-["+Settings?.color+"]" }   sm:rounded-[14px] FindFlowgamecard transition-all " } 
                              sm:rounded-[14px] gamecard transition-all  `}
                          data-active="0"
                          onClick={(e) => flowClick(inx)}  >
                      </div>
                  );
              })
          }
      </div> 
  );
};

export default React.forwardRef(FindFlow);
