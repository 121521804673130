import React from 'react';
import { CgSpinner } from "react-icons/cg";
import "./engageLoadingStyle.css";

export const EngageLoading = ({display='flex',background="",opacity='opacity-100',height='h-full',className='',text}) => {
    return(
        <div className="engageLoadingDiv" ><div className="engageLoading"></div>
        <div  className='max-w-[265px] w-[60%]'  >
            <img src="https://asserts.engage.gozen.io/v1/public/gozen-engage.png" alt="Engage Logo" className='w-full'/></div>
        </div>
        )
}