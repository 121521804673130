import React, { useState, useEffect, useImperativeHandle } from "react"; 
import { element } from "../../../Utils/helper"; 
import Iframe from "react-iframe";

const SpinWheel = ({ data },ref) => { 

  const {Settings,GameFinsh, setLoadContent,DIVID,LoadContent,gameInit,BrandLabel=true} = data  
  
  const [panelSize, setPanelSize] = useState([400,400]);    
  const [result, setresult]  =useState({})
 
  useImperativeHandle(ref, () => ({ 
  GameStart: () => {         },
  getScore: () => {return null},
  SpinTheWheel : () =>{   element("spinIframe"+DIVID).contentWindow.postMessage("spinTheWheel","*"); },
  getSpinResult : () =>{ return result; }
}
  ));
  
  function updateSize() {
    let gamePanelHeight=element("gamePanel"+DIVID).clientHeight
    let gamePanelWidth=element("gamePanel"+DIVID).clientWidth 
    let temp;
    if(gamePanelHeight<gamePanelWidth)  temp=gamePanelHeight
    if(gamePanelWidth<gamePanelHeight)  temp=gamePanelWidth
    if(temp>700) temp=700
    if(temp<300) temp=300
    setPanelSize([temp,temp+10])
  }

    // GET PANEL AND CIRCLE SIZE 
    useEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => {
     window.removeEventListener('resize', updateSize);
      }
    },
      [])





  useEffect(()=>{      
 
    updateSize()
  var frame=element("spinIframe"+DIVID);
  frame.contentWindow.Settings = Settings;
  frame.contentWindow.DIVID = DIVID;

  // element("submitBtn"+DIVID)?.addEventListener("click", (e)=>{ 
  // frame.contentWindow.postMessage("spinTheWheel","*");
  // });

  if(window.addEventListener)   window.addEventListener("message", onMessage, false) 
  else if (window.attachEvent)  window.attachEvent("onmessage", onMessage, false) 

  function onMessage(event) {  
  //if (event.origin !== "http://example.com") return; 
  var data = event.data;  
  if (data?.func === "stopGame"+DIVID)   {
    GameFinsh(true) 
     let dataResult=JSON.parse(data?.message);
     let index = Settings?.segmentValuesArray?.findIndex(function(obj) {
       return obj?.slicenumber === dataResult.results[0]?.winningSliceNumber
     });
     let tempResult=Settings?.segmentValuesArray[index]
     setresult(tempResult)

     
    //  console.log(result) 
  }
  } 
   },[]) 
 
const frameLoaded = () =>{  setLoadContent(false) }
return ( 
  
  <div  className="flex justify-center w-full h-full">
  <div className=" w-full h-full flex justify-center items-center
  
  lg:w-[90%]
  xl:w-[65%]  inner-frame  relative "  id={"gamePanel"+DIVID}>
  <Iframe title="Spin Wheel" id={"spinIframe"+DIVID} src={"/asset/game/SpinWheel/default.html"} width={panelSize[0]+"px"} height={panelSize[1]+"px"} onLoad={frameLoaded}></Iframe>
 
  </div> 
  {BrandLabel &&
          <div className="flex w-fit flex-col space-y-2  bg-white rounded-lg py-1 sm:py-2 h-fit px-2 sm:px-4 my-auto shadow-sm absolute right-[31px] bottom-[50px]">
              <p className="flex flex-row space-x-1 sm:space-x-2 text-[12px] leading-[17px] sm:text-[14px] sm:leading-[20px] m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto text-base">GoZen</span></p>
          </div>
  }
 
  </div>
 
);
}; 

export default React.forwardRef(SpinWheel);