import React from 'react';
import { CgSpinner } from "react-icons/cg";

export const LoadingSpinner = ({display='flex',background,opacity='opacity-100',height='h-full',className='',text}) => {
    return(
        <div className={` z-[100] ${display} items-center justify-center ${height} w-full  fade-in  ${background} ${opacity} ${className}`}>
            
            <div className="flex items-center">

                <CgSpinner className="w-8 h-8 animate-spin text-primary" />   

                <p className="text-sm font-medium m-2" >
                    {text ?? "Fetching Your Data"}
                </p>
 
            </div>

        </div>
        )
}