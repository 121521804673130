import React, { useState, useImperativeHandle,useEffect, useRef } from "react";
import { mixArray, element, sleep,  isEmpty, isNotEmpty, get2DnullMatrix, isEqual} from "../../../Utils/helper";
import "../../Common/commonStyle.css";
import "./style.css";

const CharDragAndDrop = ({ data },ref) => { 
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID,ActionCount} = data

  
  var BOX = 0, wordlist = Settings.wordlist
  wordlist = wordlist?.map(f => { if(f.length>BOX)  BOX=f.length; return  f.toUpperCase(); });
  const  rows= parseInt(wordlist?.length); 
  let tempCols=(Settings?.layoutrange?.toLowerCase())?.split("x")
  // const cols = parseInt(BOX); MAX LEN OF WORD
  const cols = parseInt(tempCols[0]);

   const [score, setScore] = useState(0);   
   const LeftRef = useRef(null)
   const [boxSize, setBoxSize] = useState([])
   const [dragObj, setDragObj] = useState([])
   const [isLoaded, setLoaded] = useState(false)


   const [LeftBase64, setLeftBase64] = useState(get2DnullMatrix(rows,cols,null))
   const [RightBase64, setRightBase64] = useState(get2DnullMatrix(rows,cols,null))

   const [iconSize, setIconSize] = useState([100,100])

   

  useImperativeHandle(ref, () => ({ 
    GameStart: () => {    
     
      gameSetUp()
    },
    getScore : ()=>{return score}
    }));   
     
 useEffect(()=>{ setLoadContent(true); gameSetUp() },[]) 
 useEffect(()=>{ 
let  newScore=0;

LeftBase64.map((obj,i)=>{ 
  if(isNotEmpty(obj)){
    let userWord=obj.join("")
    if(isEqual(userWord.length,cols)){ 
        wordlist?.map((jval,j)=>{  if(isEqual(userWord,jval) )  newScore+=1;   })
    } }
  }) 

setScore(newScore)
if(newScore===wordlist?.length) {GameFinsh(true);}  },[ActionCount]) 



function clearStyle(icon){
  if(isEmpty(icon)) return
  icon.remove()
  setDragObj([])
  }

const gameSetUp = () => {
  setScore(0)
  setLeftBase64(get2DnullMatrix(rows,cols,null))
  setRightBase64(get2DnullMatrix(rows,cols,null))
  
  let tempWidth = LeftRef.current.clientWidth,
    tempHeight = LeftRef.current.clientHeight,
    tempSize = tempHeight;
  if (tempHeight > tempWidth) tempSize = tempWidth;
  setBoxSize([tempSize, tempSize]);
  setTimeout(() => {
    element("gamePanel" + DIVID).style.width = "fit-content";
 
element("gamePanel" + DIVID).style.height = "fit-content";

  }, 1000);
  loadBase64(tempSize);
};
 

async function loadBase64(localSize) {
  // if(isLoaded){ setLoadContent(false); return }
  // else setLoaded(true)

  let DELTA_WIDTH = localSize / cols,
    DELTA_HEIGHT = localSize / rows;
  setIconSize([DELTA_WIDTH, DELTA_HEIGHT]);



  let newArr = []
  wordlist?.map((obj, inx) => {  newArr.push(mixArray(obj?.split(""))); });

  setRightBase64(mixArray(newArr))
  setLeftBase64(get2DnullMatrix(rows,cols,null))


  setLoadContent(false);
}
 

const onDown = function(event, inx,key,side) {

var icon=element(key+"x"+inx+"_dragImg_"+side+DIVID)
if(isEmpty(icon)) return

let singleObj=(side==="Right")? RightBase64[key][inx] : LeftBase64[key][inx]
setDragObj([ singleObj, key+"x"+inx+"_dragImg_"+side+DIVID])

  let shiftX = event.clientX - icon?.getBoundingClientRect().left;
  let shiftY = event.clientY - icon?.getBoundingClientRect().top;

  icon.style.position = 'absolute';
  icon.style.zIndex = 10;
   document.body.append(icon);

  moveAt( event.pageX, event.pageY);


  function moveAt(pageX, pageY) {
    icon.style.left = pageX - shiftX + 'px';
    icon.style.top = pageY - shiftY + 'px';

  }

 
  function onMouseMove(movEvent) {
    moveAt(movEvent.pageX, movEvent.pageY);
  }
  function onTouchMove(movEvent) {
    moveAt(movEvent.changedTouches[0].pageX, movEvent.changedTouches[0].pageY);
  }
  

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('touchmove', onTouchMove);


  icon.onmouseup = function(movEvent) {
    document.removeEventListener('mousemove', onMouseMove);
    clearStyle(icon)
    icon.hidden = true;
    let elemBelow = document.elementFromPoint(movEvent.clientX, movEvent.clientY);
    icon.hidden = false;
    dropCard(elemBelow)
  };

  icon.ontouchend = function(movEvent) {
    document.removeEventListener('touchmove', onTouchMove);
    clearStyle(icon)
    icon.hidden = true;
    let elemBelow = document.elementFromPoint( movEvent.changedTouches[0].clientX, movEvent.changedTouches[0].clientY );
    icon.hidden = false;
    dropCard(elemBelow)
  };


  function dropCard(elemBelow){

    if ((!elemBelow)|| isEmpty(elemBelow?.id)) return;
    
    let targetElm=(elemBelow?.id).split("_")
    if(targetElm?.length===1) return
    let NewSide=targetElm[2], tempPos=targetElm[0].split("x"), NewElmDIVID=targetElm[3], NewInx=parseInt(tempPos[1]), NewKey=parseInt(tempPos[0])
    if( isEmpty(NewSide) || isEmpty(NewInx) || isEmpty(NewElmDIVID) || isEmpty(NewKey) || ("_"+NewElmDIVID)!==DIVID    ) return
 
  
 

    let localLeft=LeftBase64, localRight=RightBase64
 
    if(NewSide===side){
if(NewSide==="Left"){
  if(isNotEmpty(localLeft[NewKey][NewInx])) return
  setActionCount(o => o+1)
  localLeft[NewKey][NewInx]=localLeft[key][inx]
  localLeft[key][inx]=null
}
if(NewSide==="Right"){
  if(isNotEmpty(localRight[NewKey][NewInx])) return
  setActionCount(o => o+1)
  localRight[NewKey][NewInx]=localRight[key][inx]
  localRight[key][inx]=null
}
    }
    else{

    if(NewSide==="Left") {
    if(isNotEmpty(localLeft[NewKey][NewInx])) return
    setActionCount(o => o+1)
     localLeft[NewKey][NewInx]=localRight[key][inx]
     localRight[key][inx]=null
    }

    if(NewSide==="Right") {
      if(isNotEmpty(localRight[NewKey][NewInx])) return
      setActionCount(o => o+1)
     localRight[NewKey][NewInx]=localLeft[key][inx]
     localLeft[key][inx]=null
    }}
    


    setLeftBase64(localLeft)
    setRightBase64(localRight)
    
  }
  


};

const ondragstart = function() {
  return false;
};
 
 

  return (
    <div   className="flex w-full flex-col justify-center">

<center><div className=" puzzleTips flex flex-row text-[#0A2551] break-all font-bold text-lg sm:text-2xl mt-[1rem] sm:mt-3 justify-center">
          {Settings?.puzzleHint}</div></center>
  <div draggable={false} className="flex flex-col sm:flex-row  h-[86vh] items-center relative w-full m-auto noselect"   id={"gamePanel"+DIVID}  style={{touchAction: "none"}} 
   >

<div draggable={false}  className="w-[100%] sm:w-[50%] p-2 sm:p-3 h-full flex justify-center sm:justify-end items-center max-w-[100%] overflow-hidden noselect"> 
    <div  draggable={false} className="w-full h-full bg-white rounded-md p-1 sm:p-2 noselect" style={{width:boxSize[0]+"px", height:boxSize[1]+"px"}} ref={LeftRef}>
    <div draggable={false} id={"DNDLeft"+DIVID} className="grid   h-full noselect"   style={{     gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }}  >
    {
    LeftBase64?.map((word,key)=>{
   return <div key={key} className="grid   h-full noselect" style={{ gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`}}>
    {
      word?.map((char,inx)=>{
        let id=key+"x"+inx+"_dragImg_Left"+DIVID, par_id=key+"x"+inx+"_dragPar_Left"+DIVID

      return <div  key={inx+(dragObj[1] || 0)} id={par_id}   className={` flex justify-center items-center border-1 rounded-sm shadow-sm border-gray-200 noselect h-full w-full `}
     >
      {
       (isNotEmpty(char))    &&   (dragObj?.length>0 ? dragObj[1]!==id : true) &&
        <div onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,key,"Left")}  id={id}  alt="Puzzle"  className="  w-full h-full shadow-md rounded-md cursor-pointer noselect p-[0.5rem] flex justify-center items-center text-lg sm:text-xl font-bold" style={{color:(Settings?.textColor || "white"), backgroundColor:(Settings?.cardbColor), maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}} draggable={false} 
       >{char}</div>
      }
      </div>
      })
    }
    </div>
    })
  }

  </div>
  </div>
</div>
 <div  draggable={false} className="w-[100%] sm:w-[50%] p-2 sm:p-3 h-full flex justify-center sm:justify-start items-center noselect"> 
    <div draggable={false} className="w-full h-full bg-white rounded-md p-1 sm:p-2 noselect" style={{width:boxSize[0]+"px", height:boxSize[1]+"px"}}>
    <div  draggable={false} id={"DNDRight"+DIVID}  className="grid   h-full noselect"     style={{      gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }} >
      
    {
    
    RightBase64?.map((word,key)=>{ 
      return <div key={key} className="grid   h-full noselect" style={{ gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`}}>
      {
        word?.map((char,inx)=>{
let id=key+"x"+inx+"_dragImg_Right"+DIVID, par_id=key+"x"+inx+"_dragPar_Right"+DIVID

        return <div key={inx+(dragObj[1] || 0)} id={par_id} className=" flex justify-center items-center noselect border-[2px] border-gray-200  h-full w-full "   draggable={false} 
        >
        {
          (isNotEmpty(char))  &&  (dragObj?.length>0 ? dragObj[1]!==id : true) &&
          <div onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,key,"Right")}   id={id}  className="  w-full h-full shadow-md rounded-md cursor-pointer noselect p-[0.5rem] flex justify-center items-center text-lg sm:text-xl font-bold" style={{color:(Settings?.textColor || "white"), backgroundColor:(Settings?.cardbColor), maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}} draggable={false} 
          >{char}</div>
          
      }</div>
      })
      }
      </div>
    
    })
  }
    </div>
    </div>
 </div>
 </div>
 </div>
  ); };
 

export default React.forwardRef(CharDragAndDrop);