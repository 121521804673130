import { findMaxCountElementsIn2DArray, is2DArrayEmpty, isEqual, isNotEqual, strToInt } from "./helper";

export function EcomProductFilter({ productlist={}, UserAns={}, FilterCondition={}}){

    return new Promise ((resolve, reject)=>{
 
        let   finalList=[], finalObj=[]

        Object.keys(FilterCondition).map((key)=>{ // Condition Loop
            let list=[], list2=[]
            let cnd=FilterCondition[key];
            let answer = UserAns[cnd?.question];

            productlist?.map((prod)=>{ // Product Loop

                let prodTarget=prod[(cnd?.Target==="title"? "productName" : cnd?.Target)]

                if(isEqual(cnd?.Target,"price")){
                    prodTarget=strToInt(prodTarget)
                     answer=strToInt(answer)
                }

              switch((cnd?.operator).toLowerCase()){

                case "equal to":
                    if(isEqual(prodTarget,answer)===true) list.push(prod?.id)
                break;

                case "not equal to":
                    if(isNotEqual(prodTarget,answer)===true) list.push(prod?.id)    
                break;

                case "contains":
                    if(String(answer).includes(String(prodTarget))) list.push(prod?.id)
                   
                break;

                case "not contains":
                    if(!String(answer).includes(String(prodTarget))) list.push(prod?.id)
                break;

                case "less than":
                    if(prodTarget < answer) list.push(prod?.id)    
                break;

                case "greater than":
                    if(prodTarget > answer) list.push(prod?.id)  
                break;

                default:
                    list2.push(prod?.id)
              }

            })
            finalList.push(list?.length>0 ? list : list2)

        })

        // console.log(finalList,"finalList")

        if(is2DArrayEmpty(finalList)){
            finalObj=productlist; // if there is no FilterCondition or finalList
        } else { 
             const maxElements =findMaxCountElementsIn2DArray(finalList)
        
            // Making the final product obj list
            maxElements.map((id)=>{
                let a=productlist.filter(item => item.id === id)
                finalObj.push(a[0])
            })

        }  

        resolve(finalObj)
    })
}