// import axios from "axios";
import { isEmpty } from "../Components/Utils/helper";
import Axios from "./axios"; 
  


  // const getImageBase64 = (imageUrl, width, height)=>{
  //     return new Promise(async (resolve, reject) => {
  //         try {   
  //           axios.get(imageUrl, { responseType: "blob" }).then(function (response) {
  //                 var reader = new window.FileReader();
  //                 reader.readAsDataURL(response.data);
  //                 reader.onload = function () {
  //                     let image = new Image(); 
  //                     image.crossOrigin = "anonymous";
  //                     image.onload = (async () => {
  //                         let canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
  //                         canvas.width = width; canvas.height = height;
  //                         ctx.drawImage(image, 0, 0, width, height); 
  //                         resolve(canvas.toDataURL('image/png'))
  //                     });
  //                     image.src = reader.result;
  //                 }
  //             });
  //         } catch (err) {
  //             console.log(err);
  //             reject(err);
  //         }
  //     })
  // }
  
const getImageBase64 = (imageUrl, width, height)=>{
  return new Promise(async (resolve,reject)=>{
      try{
        let params= {  method: 'GET',  headers: { "Cache-Control": 'no-cache' } }
        fetch(imageUrl,params).then( response => response.blob() ).then( blob =>{
          var reader = new FileReader() ;
          reader.onload = function(){
            let image = new Image(); 
            image.crossOrigin = "anonymous";
            image.onload = (async () => {
              let canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
              canvas.width = width; canvas.height = height;
              ctx.drawImage(image, 0, 0, width, height); 
              resolve(canvas.toDataURL('image/png'))
            });
            image.src = reader.result;
          } ; 
          reader.readAsDataURL(blob) ;
        }) ;
      } catch (err) {
      console.log(err);
      reject(err);
      } 
  })
}


const getCampaign = (campaign_id) =>{

    return new Promise (async (resolve,reject)=>{

      if(isEmpty(campaign_id)) resolve({})

        try{
            const res = await Axios.get(`/rendertemplate?campaign_id=${campaign_id}`);
          //  console.log(JSON.parse(res.data?.data?.fileds));
            if(res.status === 200) resolve(res.data);

        }catch(err){
            console.log(err);
            // reject(err);
            resolve({
              resolve:false,
              message:"Oops! No Campaign Found.",
              data:{}
            })
        }
    })
}


const getTemplate = (template_id) =>{
  
    return new Promise (async (resolve,reject)=>{
        try{

            if(isEmpty(template_id)) resolve({})

            const res = await Axios.get(`/rendertemplate/preview?template_id=${template_id}`);
          //  console.log(JSON.parse(res.data?.data?.fileds));
            if(res.status === 200) resolve(res.data);

        }catch(err){
            console.log(err);
            // reject(err);
            resolve({
              resolve:false,
              message:"Oops! No Template Found.",
              data:{}
            })
        }
    })
}

 



export  { getImageBase64, getCampaign, getTemplate}