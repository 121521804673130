import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import userEvent from "@testing-library/user-event";


export const Personalization = ( { data }, ref ) => {

    // const {  fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null, setScore = null, score = 0, position = 0, SetUserAns = null,setLoadContent = null } = data;
    const { Settings, setActionCount, StartTime, GameFinsh, setLoadContent, DIVID, setStartTime, SetUserAns } = data
    
    const { Fields, campaignSettings } = useSelector((state) => ({ Fields: state.fields, device: state.device, campaignSettings: state.campaignSettings }));

    const { fontSize } = campaignSettings;

    // const templateData = Fields[DIVID];
    // const Settings = templateData?.filedContent;

    const mainPageContent = Settings;

    const questions = mainPageContent?.questions ?? [];

    const DisPatch = useDispatch();

    const [top, SetTop] = useState('top-[0%] opacity-100');

    const [ activeScreen, setActiveScreen ] = useState(0);
    const [ ContentState, setContentState] = useState(false);
    const [ userAns, setuserAns] = useState([]);

    useEffect( ()=> {
        if(!ContentState)gameSetup()
    },[] )

    useEffect( ()=> {

        if(StartTime) {

            setActiveScreen(0);
            SetTop('top-[0%] opacity-100')
        }
    },[StartTime] )
    
    
    
    const gameSetup = () => {

        setContentState(true);
        setLoadContent(false);

    }

    const pushData =  ( val ) => {
        
        let temp = [ ...userAns ];
        ( temp.length <= activeScreen ) ? temp.push(val) : temp[activeScreen] = val ;

        setuserAns( temp )

        console.log(temp);

        if(activeScreen < (questions.length - 1)) setActiveScreen( activeScreen + 1 );
        else {
            GameFinsh(true);
            SetUserAns( temp );
        }
    
    }

    const OncClickOption = ( val ) => {
        
        SetTop('top-[100%] opacity-0');

        setTimeout(() => {

            SetTop('top-[-100%] opacity-0');
            pushData( val );

        }, 800);

        setTimeout(() => {
            if((activeScreen < (questions.length - 1) ) )  SetTop('top-[0%] opacity-100');
        }, 1300)

    }

    const QueSize = {
        "small": "text-base md:text-lg lg:text-xl",
        "medium": "text-lg md:text-xl lg:text-2xl",
        "large": "text-xl md:text-2xl lg:text-[26px]",
      }

      const parSize = {
        "small": "text-sm md:text-13px",
        "medium": "text-13px md:text-base",
        "large": "text-base md:text-[16px]",
      }

    return (

        <div className={`W-full h-full flex overflow-y-hidden`}  >

            <div className={`W-full h-full overflow-y-hidden flex flex-col md:flex-row  absolute transition-all duration-700 left-0 right-0  ${top} `} >

                <div className={`  w-full h-[35%] md:w-1/2 md:h-full   relative`}  >

                    <img className="w-full h-full object-cover" src={(questions[activeScreen]?.image != "") ? questions[activeScreen]?.image : 'https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1aee9339f72b0002337c7af7a895b0fc.png' } alt="img" />

                </div>

                <div className={` w-full h-[65%] p-8 md:w-1/2 md:h-full md:p-16 flex overflow-y-auto relative`} >

                    <div className="w-full h-auto m-auto rounded-lg space-y-4 " >

                        {

                                    <>

                                        <h3 className={ `  ${QueSize[fontSize]} text-white font-semibold`} >{questions[activeScreen]?.question}</h3>

                                        <div className="w-full grid grid-cols-1 gap-5" >

                                            {
                                                questions[activeScreen]?.options.map(val => {
                                                    return (

                                                        <p className={`px-5 py-3 flex border-1 rounded-lg transition-all duration-500 transform scale-100 hover:scale-95 hover:shadow-bgshadow hover:shadow-inner text-left bg-white hover:bg-gray-50 font-semibold cursor-pointer ${parSize[fontSize]}`} onClick={()=>OncClickOption(val?.option)} > {val?.option} </p>
                                                    )
                                                })
                                            }


                                        </div>
                                    </>
                                // )
                            // })
                        }



                    </div>

                </div>

            </div>
        </div>

    )

}