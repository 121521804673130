import React, { useState, useRef, useEffect } from "react";
import {Games} from "./Games"; 
import {Poll} from "./Poll";  
import {Survey} from "./Survey"; 
import {Giveaway} from "./Giveaway"; 
import {Calculator} from "./Calculator"; 
import {Ecommerce} from "./Ecommerce"; 
import {Quiz} from "./Quiz"; 

export const TemplatetypeBlock = ({fieldID, filedsObj} ) => {
  
    const [category,setcategory] = useState(filedsObj?.category)
   
  return (
    <>
      { 
            (category === "games" ||  category === "spinWheel" )?   <Games fieldID={fieldID} filedsObj={filedsObj}/> : 
            category === "poll" ?   <Poll fieldID={fieldID} filedsObj={filedsObj}/> :  
            category === "surveys" ?   <Survey fieldID={fieldID} filedsObj={filedsObj}/> : 
            category === "giveaway" ?   <Giveaway fieldID={fieldID} filedsObj={filedsObj}/> : 
            category === "calculater" ?   <Calculator fieldID={fieldID} filedsObj={filedsObj}/> : 
            category === "ecommerce" ?   <Ecommerce fieldID={fieldID} filedsObj={filedsObj}/> : 
            category === "quizzes" ?   <Quiz fieldID={fieldID} filedsObj={filedsObj}/> : 
            null
      }
    </>
  )

 
} 