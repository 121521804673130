import React from "react";
import { useSelector } from "react-redux";  
import { isEmpty } from "../Utils/helper";

export const Image = ({fieldID='',fieldsobj}) => {

    const titleContent = fieldsobj; 
    let imgSrc=(titleContent?.filedContent?.imageSrc || "asset/images/templateCollect/Match_the_Animal_Names.png")

    return (
        <div className={`relative bg-white flex flex-col py-6 group `}> 

            <div className="flex w-fit h-auto max-w-full mx-auto py-4">
                <img src={imgSrc} className={'w-auto h-auto object-cover'} />
            </div>
        </div>

    )
}