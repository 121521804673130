import React, { useState, useEffect } from 'react';

export const  CountDownTimer = ({
  actionCount = 0,
  StartTime = false,
  timeCount = 30,
  Callback = null, 
  setSecond = null,
  setMin = null
}) => {


  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);


  useEffect(()=>{
    if(StartTime){
      var initialMinute =  ~~(timeCount / 60),initialSeconds =timeCount % 60
      setMinutes(initialMinute);
      setSeconds(initialSeconds)
    }else{
      setMinutes(0);
      setSeconds(0)
    }
  },[StartTime])

  useEffect(()=>{ if(StartTime){       
    setSecond(seconds);setMin(minutes); } 
  },[seconds,minutes,StartTime])


  useEffect(() => {
    let countdownInterval;

    if (minutes >= 0 && seconds >= 0) {
      countdownInterval = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(countdownInterval);
            // Handle timer completion here, e.g., trigger an action.
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
      if(seconds===1 && minutes===0) Callback();
    }

  }, [minutes, seconds]);

  return (
<div className="w-full flex justify-between py-4 px-3 border-1 rounded-lg bg-white mb-4">
<p className="noselect">
  Number of action : <span>{actionCount}</span>
</p>
<div className="flex my-auto ">
  <p className="space-x-2 capitalize noselect">
    timer :{" "}
    <span className="w-6 inline-block">{minutes < 10 ? `0${minutes}` : minutes}</span>
    <span>:</span>
    <span className="w-6 inline-block">
      {seconds < 10 ? `0${seconds}` : seconds}
    </span>
  </p>
  </div>
</div>
  );
}
