

import React, { useState } from "react"; 
import { Chrono } from "react-chrono";
 

export const Timeline = ({fieldID='',fieldsobj}) => { 
    const Settings={
        items:fieldsobj?.filedContent?.items,
        mode:fieldsobj?.filedContent?.mode,
        theme:fieldsobj?.filedContent?.theme,        
        fontSizes:fieldsobj?.filedContent?.fontSizes,
        hideControls:true,
        timelinePointShape	:fieldsobj?.filedContent?.timelinePointShape
    }
    return (
        <div  className="w-full   p-3  sm:p-5  ">
 

<Chrono   {...Settings}/>



</div> 
    )

}