import React, { useEffect } from "react";
import { isNotEmpty } from "../Utils/helper";
import WebFont from 'webfontloader' 

export const Description = ({fieldID='',fieldsobj}) => {

    const titleContent = fieldsobj;
    const font=isNotEmpty(titleContent?.filedContent?.FontFamily)? titleContent?.filedContent?.FontFamily : "ABeeZee"
    
    useEffect(()=>{
        WebFont.load({ google : { families : [font] }})  
    },[])  

    return (

        <div className="relative group bg-white flex flex-col py-8">

            <p className={`w-4/5 mx-auto font-medium capitalize text-center`} 
            style={{color:titleContent?.filedContent?.TextColor,fontFamily:font}} >
                {titleContent?.filedContent?.Content === '' ? 'Grow your business organically without Ads by creating SEO friendly content, building audience, automating engagement and sales' : titleContent?.filedContent?.Content}  </p>

        </div>

    )
}