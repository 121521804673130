import React from "react";

export const QuestionOptionBlock = ({
    onClick=null,
    isSelect = false,
    textSize = "",
    primaryColor = "#ffffff",
    secondaryColor = "#000000",
    Value=''

}) => {

    return (

        <div className={`cursor-pointer flex px-4 py-3 border-1 min-w-[150px] rounded-xl  ${textSize} ${(isSelect) ? "bg-white" : "bg-[rgba(250,250,250,0.2)]"}  text-white font-medium space-x-3 `}

            style={{ background: isSelect ? primaryColor?? "white" : primaryColor+ "30" ?? "#FFFFFF30", borderColor: primaryColor?? "white" }}

            onClick={onClick} >
            <div className={`my-auto cursor-pointer ${(isSelect) ? "border-white bg-black" : ""} rounded-full w-5  h-5 border-1`}

                style={{ background: isSelect ? secondaryColor?? "black" : primaryColor+ "30" ?? "#FFFFFF30", borderColor: isSelect ? secondaryColor?? "black" : primaryColor?? "white" }}

            >
                <div className={` rounded-full w-2.5 h-2.5 m-1  ${(isSelect) ? "bg-white" : ""}`}
                    style={{ background: isSelect ? (primaryColor?? "white") : "" }}

                ></div>
            </div>
            <p className={` cursor-pointer break-all flex items-center ${(isSelect) ? "text-black" : ""}`} style={{ color: isSelect ? secondaryColor?? "black" : primaryColor?? "white" }} >{Value}</p>
        </div>

    )

}