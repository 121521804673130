import React from "react";
import { useDispatch, useSelector } from "react-redux";


export const FormProcessBarBlock = ({ childBlock, primaryColor, secondaryColor, Question = [], AnsCount = 0, currentScr = 0 }) => {

    const { campaignSettings } = useSelector( state => ({ campaignSettings: state.campaignSettings }) );

    const { questionsBlockcontent, answeredBlockcontent } = campaignSettings?.defaultContent;

    return (

        <div className="flex flex-col space-y-2 w-full bg-white" style={{ background: primaryColor ?? "white", color: secondaryColor ?? 'black' }} >

            <div className={`w-full h-2 bg-[rgba(0,0,0,0.3)] grid justify-items-start`} style={{ background: secondaryColor + '40' ?? "#00000040" }} >
                <div className={`h-2 w transition-all duration-500 bg-black rounded-r-full`} style={{ background: secondaryColor ?? "black", width: `${((currentScr + 1) / Question.length) * 100}%` }} ></div>
            </div>

            <div className="w-full py-2 px-2 flex justify-between" >

                <p className="capitalize font-medium">{questionsBlockcontent ?? "questions"} : <span>{currentScr + 1}</span>/<span>{Question.length}</span></p>

                {childBlock}

                <p className="capitalize font-medium">{ answeredBlockcontent ??"Answered"} : <span>{AnsCount}</span></p>

            </div>

        </div>
    )

}