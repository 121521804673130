import React, { useState, useEffect, useImperativeHandle } from "react";
import Konva from 'konva';  
import { mixArray, isEqual, sleep , isEmpty, element, isNotEmpty, uniqueArray, removeUndefinedFrmArray} from "../../../Utils/helper";
import "../../Common/commonStyle.css";  
import { innerGroupPos, innerGroupSize, outerCircleRadius } from "./wordConnectCircleConfig";
const WordConnect = (  { data },ref ) => {
const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data
const [score, setScore] = useState(0);  
const [frameIndex, setFrameIndex] = useState(1); 
const [frameQuiz, setFrameQuiz] =useState([]); 
const [strokeWidth, setStrokeWidth] = useState(6);  
const [userAnswer, setUserAnswer] = useState([]);  
const [selectedChar, setSelectedChar] = useState("");  
const [isGameOver, setGameOver] =useState(false);   
const totalFrames= Object.keys(Settings)?.length || 0
let innerCircleColor=Settings?.innerCircleColor || '#49D57A';

useImperativeHandle(ref, () => ({
    GameStart: () => {  gameSetup();  },
    getScore: () => { return score; }
   }));

useEffect(() => {  if (Settings?.Frame1 > 0) gameSetup(); setLoadContent(false) }, []);  
const gameSetup = () => { setFrameIndex(1);   }; 
 



useEffect(() => { 
  let frameName="Frame"+frameIndex, quiz=Settings[frameName];  
     quiz.answer=mixArray(quiz?.answer); 

// fill matrix to ?
let ansTemp=[]
quiz?.answer?.map((obj,inx)=>{   ansTemp.push((new Array(obj.length)).fill("?")) }) 
setUserAnswer(ansTemp) 

setFrameQuiz(quiz) 

    },[frameIndex])

 
   

    useEffect(()=>{
       
// DRAWING THE MAIN CIRCLE FOR EACH FRAME
    try{
      let selected=[null,null], allSelectedArray=[], selectedCount=0 , finalAnswer="", waitTime;
      // GET DIV INITIAL SIZES
   let width= document.querySelector("#gameLeftPanel"+DIVID)?.clientWidth, height= document.querySelector("#gameLeftPanel"+DIVID)?.clientHeight; 
    let gamePanelWidth=document.querySelector("#gamePanel"+DIVID).clientWidth,radius= (width/2)-17
    width/=1.1;  if(gamePanelWidth<640 && radius>=160)   width=width/1.6
   
    // OUTER CIRCLE DRAW
    radius=outerCircleRadius(width,gamePanelWidth) 
    var stage = new Konva.Stage({  container: 'gameLeftPanel'+DIVID,   width: width, height: width, }),  layer = new Konva.Layer();
    layer.add(new Konva.Circle({  x: stage.width() / 2,  y: stage.height() / 2, width:width,  height:height,  radius: radius ,  fill: 'white', stroke: 'lightgray',  strokeWidth: 1, }));
  
    // INNER CIRCLE VARIABLE
    let  groupWidth= stage.width() / 2, sizeData=innerGroupSize(groupWidth),
     edgeX=sizeData?.edgeX, charCircle=sizeData?.charCircle, 
    charFont=sizeData?.charFont, charPos=sizeData?.charPos,   groupPosArr=[] ;
    groupPosArr=innerGroupPos(frameQuiz?.letter?.length, groupWidth, edgeX,stage.height())
    setStrokeWidth(sizeData.strokeWidth)
     
    
    // LINES UI PREPARE
    let lineGroup = new Konva.Group();
    layer.add(lineGroup)

    // INNER CIRCLE UI PREPARE
    mixArray(frameQuiz?.letter)?.map((text,inx)=>{ 
    if(isNotEmpty(text)){  
      let name="innerShape_"+inx+"_"+text.toUpperCase()+"_"+ groupPosArr[inx]?.x+"_"+ groupPosArr[inx]?.y
    let charGroup = new Konva.Group({x: groupPosArr[inx]?.x, isSelected:false, y: groupPosArr[inx]?.y , name: name});
    let circleShap=new Konva.Circle({id:"circleShape"+inx, isSelected:false, x:0,  y: 0, width:charCircle,  height:charCircle, name: name, radius: (charCircle/2.6)-10 ,  fill: innerCircleColor, stroke: 'lightgray',  strokeWidth: 1 })
    charGroup.add(circleShap)
    charGroup.add(new Konva.Text({ x:charPos?.x, isSelected:false,  y:charPos?.y, text: text.toUpperCase(), name:name, fontSize: charFont, fontFamily: 'DM Sans', fill: "white",  fontStyle:"bold",  }))
  
    layer.add(charGroup) 
  


charGroup.on('pointerclick', (e) => {
  let name=e?.target?.attrs?.name,
  isSelected=e?.target?.attrs?.isSelected; 
  if(isNotEmpty(name) && isSelected!==true){ 
    setActionCount((obj) => obj + 1);      
    let data=name.split("_"), 
    shape = stage.find('#circleShape'+data[1])[0];shape.fill("red"); 
    stage.find("."+name).map((obj)=>{    obj.attrs.isSelected=true });
      
      let temp=selected;  
      if(isEmpty(temp[0])){
        temp[0]=name;
      }
      else{
        temp[1]=name
      };
      selected=temp;  
      drawLine()

  finalAnswer=""
  allSelectedArray=removeUndefinedFrmArray(uniqueArray(allSelectedArray)); 
  allSelectedArray?.map((val,inx)=>{ let temp=val?.split("_"); finalAnswer+=temp[2]  }) 
setSelectedChar(finalAnswer)  
      if(allSelectedArray.length>=frameQuiz?.letter?.length) checkAnswer()
       
    //   waitTime=Date.now()
    // setTimeout(()=>{
    //   let difference=(Date.now()-waitTime)/1000  
    //   if(difference>=0.8){ 
    //     checkAnswer() 
    //   }  
    // },800)
  } 
}); 



function drawLine(){
  if(isNotEmpty(selected[0]) && isEmpty(selected[1])){  allSelectedArray.push(selected[0])} //the initial select
  if(isNotEmpty(selected[0]) && isNotEmpty(selected[1])  ){  
     
  allSelectedArray.push(selected[0],selected[1]);  
  allSelectedArray=removeUndefinedFrmArray(uniqueArray(allSelectedArray));

    let axisX=selected[0].split("_"),  axisY=selected[1].split("_"),
    line = new Konva.Line({
     stroke: "red",
     strokeWidth:strokeWidth, 
     listening: false, 
     points: [axisX[3], axisX[4], axisY[3], axisY[4],]
   });  
    
  lineGroup.add(line); 
  selected=[selected[1],null]
     }  
}

async function checkAnswer(){
  // console.log(finalAnswer)
  frameQuiz?.answer?.map(async(val,inx)=>{
 if(val.toUpperCase()===finalAnswer.toUpperCase()){

 }

})

await sleep(1000)
clearSelect()
}




function clearSelect(){
  lineGroup.removeChildren();  

  mixArray(frameQuiz?.letter)?.map((text,inx)=>{ 
    let shape = stage.find('#circleShape'+inx)[0];shape.fill(innerCircleColor);  
    stage.find("."+shape?.attrs?.name).map((obj)=>{    obj.attrs.isSelected=false });
  })
  selected=[null,null]; allSelectedArray=[]; selectedCount=0; finalAnswer=""; 
  setSelectedChar("")
}


    }   })  
     

    stage.add(layer); 
    } catch(e){  console.log(e?.message)  }  
 
    },[frameQuiz])
 
return (
<div className="flex flex-col sm:flex-row mb-4  h-[27.5em] sm:h-[23em]  items-center"   id={"gamePanel"+DIVID}  >
    <div className="w-[100%] sm:w-[60%] mt-6 h-fit">  
    
    
        <div className="flex flex-row justify-center">
        {Array.from(selectedChar)?.map((text,inx2)=>{
        return <div key={inx2} className="noselect text-xl font-bold mb-1" style={{color:innerCircleColor}}>{text}</div> 
        })} 
        {(Array.from(selectedChar).length===0) && <div className="noselect text-xl font-bold mb-1" style={{opacity:0}}>{"-"}</div> }
          </div>
    { userAnswer?.map((obj,inx)=>{
        return <div className="flex flex-row justify-center" key={inx}>
        {obj?.map((text,inx2)=>{
        return <div key={inx2} className="noselect px-5 py-4 mx-1 my-1 sm:px-6 sm:py-5 md:px-7 md:py-6 lg:px-8 lg:py-7 xl:px-8 xl:py-7 text-base sm:text-lg xl:text-2xl font-semibold border-1 border-[#8A929E] rounded-md shadow-md" style={{background:"#FFFFFF"}}>{text}</div> 
        })}
        </div>})}   
    </div> 
    <div className="w-[100%]  sm:w-[40%]   h-[80%] sm:h-full flex mt-5 sm:mt-3  items-center justify-center "  id={"gameLeftPanel"+DIVID}> 
    </div>
</div> 
);
};
export default React.forwardRef(WordConnect);
