import React from "react";
import Iframe from "react-iframe";

export const Video = ({fieldID='',fieldsobj}) => { 

    const titleContent =fieldsobj;   

    return (
        <div className={`relative bg-white flex flex-col py-2 sm:py-3 md:py-4 xl:py-5 2xl:py-6 group h-[95%] `}>            
 

            <div className="flex w-full h-full max-w-full mx-auto py-2 md:py-4" > 
                {titleContent?.filedContent?.VideoUrl !== "" && <Iframe className="w-full h-auto min-h-[460px]" style={{width:"100%"}} autoPlay={true} title="youTube_Embed"   src={"https://www.youtube.com/embed/"+titleContent?.filedContent?.VideoUrl} >
                </Iframe>  }            
            </div>
        </div>

    )
}
 
   
