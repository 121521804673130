import { useEffect, useRef, useState } from "react"
import lib from 'google-libphonenumber'
import { CountryCode } from "../../../../content/countrycode"
import Input from "./input"

export const RePhoneFiled = ({
    primaryColor = "white",
    secomdaryColor = 'black',
    textvalue,
    textsize = "text-[16px]",
    error= null,
    onchange = null,
    onkeyup=()=>{}
}) => {
    const countryCodeData = CountryCode()
    const [DropMenuState, setDropMenuState] = useState(false)
    const [ActiveInd, setActiveInd] = useState(0)
    const menuref = useRef()


    const Validation = (number,countryCode='US',dial_code='+1') => {
        try {
        if(number?.length===0) onchange("")
        if(number.length > 2) {
            const phoneUtil = lib.PhoneNumberUtil.getInstance()
            const Data = phoneUtil.isValidNumberForRegion(
                phoneUtil.parse(number, countryCode.toUpperCase()),
                countryCode.toUpperCase()    
            )
               if(!Data) return error({status:false,msg:"Invalid number"})
               else onchange (`(${dial_code}) - ${number}`)
        }
        }catch(err){
           return  error({status:false,msg:"Invalid number"})
        }

    }

    useEffect(() => {
        let handleAction = (event) => {

            if (menuref.current && !menuref.current?.contains(event.target)) {
                // alert("test-out")
                setDropMenuState(false);
            }
        };

        document.addEventListener("mousedown", handleAction);
    }, [])

    return (
        <div className="flex space-x-3 w-full">
            <div className="relative">
                <div className="flex  space-x-2 bg-white py-2 px-3 cursor-pointer rounded-md border-1" style={{ background: primaryColor == 'white' ? '#FFFFFF30' : primaryColor, borderColor: primaryColor }} onClick={()=>setDropMenuState(true)} >
                    <span>{countryCodeData[ActiveInd].flag}</span>
                    <span className="text-sm flex my-auto">({countryCodeData[ActiveInd].code})</span>
                    <span className="text-sm flex my-auto">({countryCodeData[ActiveInd].dial_code})</span>
                    <span className="relative -right-2 inline-block">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2097_16399)">
                                <path d="M7.98417 10.7345L10.3583 13.1087C10.7158 13.4662 11.2933 13.4662 11.6508 13.1087L14.025 10.7345C14.6025 10.157 14.19 9.16699 13.3742 9.16699H8.62584C7.81001 9.16699 7.40667 10.157 7.98417 10.7345Z" fill={primaryColor} />
                            </g>
                            <defs>
                                <clipPath id="clip0_2097_16399">
                                    <rect width="22" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </div>

                {DropMenuState && <div style={{ background: primaryColor, color: secomdaryColor, borderColor: primaryColor, zIndex: '10' }} className="absolute outline-none top-10 w-full border-1 overflow-x-hidden bg-white rounded-b-md border-t-0 list-industry max-h-[200px] overflow-y-auto" ref={menuref} >
                    {/* {ref={menuref}} */}
                    {
                        countryCodeData.map((list, index) => {
                            return (
                                <p key={index} style={{ color: secomdaryColor }} className="flex space-x-2 w-full px-4 py-2 capitalize text-base hover:bg-gray-100 cursor-pointer whitespace-nowrap text-ellipsis"
                                onClick={()=>{setActiveInd(index);setDropMenuState(false)}}
                                ><span>{list?.flag}</span><span className="text-sm flex my-auto">({list?.code})</span><span className="text-sm flex my-auto" >({list.dial_code})</span></p>
                            )
                        })
                    }
                </div>}
            </div>
            <div className="flex flex-col space-y-2 w-full">
                <Input style={{ color: primaryColor ?? "white" }} margin={''} type={'number'} textsize={textsize} textvalue={textvalue} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="Enter Value" background='bg-transparent' onchange={(e)=>Validation(e.target.value,countryCodeData[ActiveInd].code,countryCodeData[ActiveInd].dial_code)} onKeyUp={onkeyup} />
                <hr className="transition-all border-white" style={{ borderColor: primaryColor ?? "white" }} />
            </div>
        </div>
    )
}