import React, { useState, useImperativeHandle, useEffect } from "react";
import { mixArray, element, sleep, isEmpty } from "../../../Utils/helper";
import { getMatrixClip } from "../../../Utils/canvas";
import { getImageBase64 } from "../../../../backend/templates";
import "../../Common/commonStyle.css";

const ImageSwap = ({ data }, ref) => {
  const { Settings, showcardtime, setActionCount, GameFinsh, setLoadContent, DIVID, setPreStartTime, gameInit, GameShffleImg, StartTime } = data

  const MATRIX = Settings?.layoutrange?.split("x"), cols = isEmpty(MATRIX) ? 0 : parseInt(MATRIX[0]), rows = isEmpty(MATRIX) ? 0 : parseInt(MATRIX[1]);

  var CDmove = 0, answerArray = [], orderArray = [], cardA = "", selected = false;
  const [score, setScore] = useState(0);
  const [url, setUrl] = useState("");
  const [localInit, setlocalInit] = useState(false)
  const [width, setWidth] = useState(400)
  const [height, setHeihght] = useState(400)
  const [DELTA_WIDTH, setDELTA_WIDTH] = useState(100)
  const [DELTA_HEIGHT, setDELTA_HEIGHT] = useState(100)

  const [ switchcontent, SetSwithContent ] = useState(false)
  const [ Refreshcontent, SetRefreshContent ] = useState(false)

  useImperativeHandle(ref, () => ({

    GameStart: () => { TriggerGameSetup(); },

    getScore: () => { return score }

  }));

 
  useEffect(() => { loadBase64(); }, []);

  const TriggerGameSetup = () => {
    
    setPreStartTime(true)

    gameSetup();

  }

  useEffect(()=>{

    if(StartTime) SetSwithContent(false);

    else {
      element("gameFrame" + DIVID).innerHTML = '';

      SetSwithContent(true);
    }

  },[StartTime])

  useEffect(()=> { 

    if(!switchcontent && StartTime) generatePuzzle()

   } ,[switchcontent])



  async function loadBase64() { 

    setLoadContent(true)

    let tempwidth = element("gameFrame" + DIVID).clientWidth, tempHeight = element("gameFrame" + DIVID).clientHeight, tempDELTA_WIDTH = tempwidth / cols, tempDELTA_HEIGHT = tempHeight / rows;
    
    setDELTA_WIDTH(tempDELTA_WIDTH); setDELTA_HEIGHT(tempDELTA_HEIGHT); setWidth(tempwidth); setHeihght(tempHeight);

    let localUrl = (isEmpty(Settings?.image_src)) ? "" : await getImageBase64(Settings?.image_src, tempwidth, tempHeight);

    setUrl(localUrl)

    setLoadContent(false)

  }

  const gameSetup = () => { setScore(0); setActionCount(0); 
    // element("gameFrame" + DIVID).innerHTML = ""; 
  // generatePuzzle(); 
};

  const delayClear = async (data) => { await sleep(data); let gameFrame = element("gameFrame" + DIVID); gameFrame.style = `background-image:none`; gameFrame.innerHTML = ''; }


  const setShadow = (dragImage) => {

    element(dragImage).style["zIndex"] = "10";
    
    element(dragImage).style["border"] = "1px SOLID lightgray"; element(dragImage).style["transform"] = "scale(1.2)"; element(dragImage).className = "transition-all duration-500  cursor-pointer shadow-xl noselect p-1 rounded-xl";
  
  }

  const removeShadow = (dragImage) => { element(dragImage).style["zIndex"] = "0"; element(dragImage).style["border"] = "none"; element(dragImage).style["transform"] = "scale(1)"; element(dragImage).className = "transition-all duration-500 cursor-pointer  shadow-none noselect p-1 rounded-xl"; }

  const generatePuzzle = async () => {

    if (isEmpty(url)) { await loadBase64(); }

    element("gameFrame" + DIVID).innerHTML = '';

    // element("gameFrame" + DIVID).style = `background-image:url(` + url + `)`;

    var posX = 0, posY = 0, base64 = []

    for (var z = 0; z < (rows * cols); z++) { orderArray.push(z); answerArray.push(z) }; orderArray = mixArray(orderArray); z = 0;

    base64 = await getMatrixClip(url, rows, cols, DELTA_WIDTH, DELTA_HEIGHT);

    let image = new Image(); image.onload = (async () => { 

      // CUT AND MIX IMAGE
      // await delayClear(((showcardtime || 1000)*1000)+1100);

      // await delayClear(((1) * 1000) + 1100);


      z = 0; posY = 0;
      for (let i = 0; i < rows; i++) {
        posX = 0;
        for (let j = 0; j < cols; j++) {
          setImage(posX, posY, base64[orderArray[z]], DELTA_WIDTH, DELTA_HEIGHT, orderArray[z])
          posX += DELTA_WIDTH; z += 1;
        }
        posY += DELTA_HEIGHT;
      }
    }); image.src = url;
  }


  const setImage = (posX, posY, image, DELTA_WIDTH, DELTA_HEIGHT, order) => {
    let imageObj = new Image();
    imageObj.onload = function () {
      imageObj.setAttribute("id", "dragImage" + DIVID + "_" + order);
      imageObj.setAttribute("class", "transition-all duration-500 cursor-pointer  shadow-none noselect p-1 rounded-xl");
      imageObj.setAttribute("draggable", false);
      imageObj.setAttribute("val", order);
      imageObj.style = ` position:absolute;width:` + DELTA_WIDTH + `px;height:` + DELTA_HEIGHT + `px;top:` + posY + `px;left:` + posX + `px;background-image:url(` + imageObj + `);`
      imageObj.onclick = function (e) { setAction("dragImage" + DIVID + "_" + order) };
      element("gameFrame" + DIVID).appendChild(imageObj)
    }; imageObj.src = image;
  }


  const setAction = (dragImage) => {
    if (dragImage === cardA) {
      removeShadow(cardA); cardA = ""; selected = false;
    } else {
      if (selected) { swapImage(cardA, dragImage); removeShadow(cardA); cardA = ""; selected = false; }
      else { setShadow(dragImage); cardA = dragImage; selected = true; }
    }
  }


  const swapImage = async (cardA, cardB) => {
    CDmove += 1; setActionCount(CDmove);
    let cardAElm = element(cardA), cardBElm = element(cardB)
    let valB = cardBElm.getAttribute("val"), valA = cardAElm.getAttribute("val"); cardBElm.setAttribute("val", valA); cardAElm.setAttribute("val", valB)
    let topA = cardAElm.style.top, leftA = cardAElm.style.left, topB = cardBElm.style.top, leftB = cardBElm.style.left;
    cardAElm.style.setProperty("top", topB, ""); cardAElm.style.setProperty("left", leftB, "");
    cardBElm.style.setProperty("top", topA, ""); cardBElm.style.setProperty("left", leftA, "");

    var isCorrect = true, tempScore = 0
    orderArray.map((val, inx) => {
      let idVar = element("dragImage" + DIVID + "_" + inx).getAttribute("val")
      if (parseInt(idVar) !== parseInt(val)) {
        isCorrect = false;
      }
      if (parseInt(idVar) === parseInt(val)) {
        tempScore += 1
      }
    })
    setScore(tempScore)
    if (isCorrect) GameFinsh(true)

  }




  return (

    <div className="w-full mt-5 h-full flex justify-center items-center overflow-auto " id={"gamePanel" + DIVID}  >

      {Settings?.timerstatus}

      <div className={` aspect-[1.5/1]  min-h-[250px] max-h-[93%] h-[93%]  min-w-[250px] max-w-[95%] rounded-md relative transition-all duration-500 flex justify-center items-center `} id={"gameFrame" + DIVID} >

      { switchcontent && <img src={Settings?.image_src} alt="" title="" className={` transition-all duration-1000 ${ GameShffleImg ? "opacity-0" : "opacity-100"} w-full h-full rounded-lg`}/> }


      </div>
    </div>

  );
};

export default React.forwardRef(ImageSwap);