import React, { useEffect, useState, useImperativeHandle } from "react"; 
import "../../Common/commonStyle.css";
import { mixArray,sleep ,isEmpty,element, isNotEmpty, getImageOrignalSize} from "../../../Utils/helper"; 
import { getImageBase64 } from "../../../../backend/templates";

const RearrangeProducts = ( { data },ref ) => { 
  const {Settings,showcardtime,setActionCount,GameFinsh,setLoadContent,DIVID,setStartTime,setPreStartTime,gameInit} = data 
  
  const LayoutRange = Settings?.layputRange?.split("x"); 
  const noOfCards = LayoutRange[0] * LayoutRange[1];  
  const [cardArrangement, setCardArrangement] = useState([]);  
  const [selected, setSelect] = useState([null,null]); 
  const [selectCount, setselectCount] = useState(0);  
  const [isShowing, setIsShowing] = useState(true);  
  const [score, setScore] = useState(0); 
  const [isPreloaded, setPreloaded] = useState(true); 
  const [doActivate, setDoActive]=useState("activecard")
  const [loadedImages, setLoadImages] = useState([])
  const [localInit, setlocalInit]= useState(false)


  useEffect(() => { setLoadContent(false); 
    
    if (Settings?.pairItems?.length === noOfCards){ setCardArrangement([...Array(noOfCards).keys()]);

      if(isPreloaded){
        // preLoad();
        setPreloaded(false) }
    }
     }, []); 
    
  useImperativeHandle(ref, () => ({ 
    GameStart: () => {   gameSetup() },
    getScore : ()=>{return score}
  })); 

  useEffect(()=>{
    if(gameInit===true && localInit===true) setPreStartTime(true)
},[gameInit,localInit])

  const preLoad = async () =>{
    setLoadContent(true);     
  let tempSetting=Settings?.pairItems,tempLoaded=[]

  await tempSetting.map(async (val,inx)=>{
    let size=await getImageOrignalSize(val?.src);
    tempLoaded.push(await getImageBase64(val?.src, size[0],size[1]))
  setLoadImages(tempLoaded)
  setLoadContent(false); 
  })
  setLoadImages(tempLoaded)

  }

 const gameSetup = async () => {
  setlocalInit(true)
    setIsShowing(true); 
    if (Settings?.pairItems.length !== noOfCards){console.log("pairItems and noCard is not equal!"); return;}
    setCardArrangement([...Array(noOfCards).keys()])
  

    setDoActive("")
    // await sleep(400);

    setDoActive("activecard")
    await sleep(showcardtime *1000);
    setDoActive("")
    await sleep(1000);

    setselectCount(0); setScore(0);
    setCardArrangement(mixArray([...Array(noOfCards).keys()]))
    setDoActive("activecard")
    setIsShowing(false)
    }; 

  const flidcard = (e, value, index) => {    
    if(isShowing) return;
    setActionCount((obj) => obj + 1);   
    let temp=selected; 
    if(temp[0]===index) temp=[null,null]
    else (isEmpty(temp[0]))? temp[0]=[index] : temp[1]=[index];
    setSelect(temp); setselectCount(o=>o+1) 
    }; 

  useEffect(()=>{  
    if(isNotEmpty(selected[0]) && isNotEmpty(selected[1])  ){ 
    let cardArrangementTemp=cardArrangement;
    let temp=cardArrangementTemp[selected[0]]
    cardArrangementTemp[selected[0]]= cardArrangementTemp[selected[1]]
    cardArrangementTemp[selected[1]]=temp 
    setCardArrangement(cardArrangementTemp)
    setSelect([null,null])

    let isCorrect=true, tempScore=0; // Checking answer
    cardArrangement.map((val,inx)=>{ if(inx!==val) isCorrect=false; else tempScore+=1  })
    if(isCorrect) {GameFinsh(true);  setDoActive("")}
    setScore(tempScore)
    }  },[selected,selectCount])

  return ( 
      <div  className={`w-[90%] sm:w-[85%] md:w-[75%] m-auto h-[60%] sm:h-[75%] min-h-[250px] min-w-[250px] md:h-[85%] gap-[1rem] sm:gap-[1.5rem] md:gap-[2rem] grid flex-col`}   id={"gamePanel"+DIVID}   style={{ gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`,    gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)`,  }}  >
        { 

          (cardArrangement?.map((list, index) => {  
            let CardObj = Settings?.pairItems[list];   
            // let imageSrc=isEmpty(loadedImages[list]?.base64?.slice(0, 5))?  CardObj?.src :  loadedImages[list]?.base64
            if (CardObj)
              return (
            
                <div key={index}  className={`relative transition-all scale-100 transform ${(selected[0]?.[0] === index) ? " scale-90 " : ""}   `} data-active="0"  >
                <div key={index} style={{backgroundColor:Settings?.filpCardBgColor || "#DCE0E4"}} className={`
                 rounded-[16px!important]

                 p-[1.7rem]  sm:p-[2rem] 

                 h-[100%] 
                 gamecard transition-all duration-700  ${(selected[0]?.[0]===index) ? "  scale-50 " : ""}  ${doActivate}`}   data-active="0" onClick={(e) => flidcard(e, CardObj.pairValue, index)}  >
                  <div className={`gamecardfront   rounded-[16px!important] `}  >
                    <span className="gamecardinner m-auto ">
                      <img  draggable={false} src={ Settings?.filpCardBgImage === ""   ? "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/basket.png" : Settings?.filpCardBgImage }  alt="test" className="w-full h-full noselect" />
                    </span>
                  </div>
                  <div className={`gamecardback w-full  rounded-[16px!important]  `} >
                    {/* <span className="gamecardinner m-auto"> */}    
                   <span className="flex m-auto p-2 sm:p-4 max-w-full max-h-full">
                      {CardObj.type === "image" ? (  
                      <img draggable={false} src={CardObj?.src} alt="test" className=" noselect" /> ) : (
                      <p  className={ "w-fit max-w-full max-h-full  text-center m-auto outline-none resize-none overflow-hidden capitalize text-sm sm:text-lg font-medium noselect"} >{CardObj.content}  </p>
                      )}
                    </span>
                  </div>
                </div>
                </div>
              );
          })
          )
        }
      </div> 
  );
};

export default React.forwardRef(RearrangeProducts);
