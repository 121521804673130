import React, { useState, useEffect, useImperativeHandle } from "react"; 
import MatchSvg from "./MatchSvg"   
import { mixArray, isEqual,filterJson, element, isNotEmpty, allElement, removeSvgTag, isInArray, uniqueArray } from "../../../Utils/helper";
import "../../Common/commonStyle.css"; 
 



const Matching = ({ data },ref) => {   
const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data
  
  const [iconSize, seticonSize] = useState({width:100,height:100});  
  const [puzzleX, setPuzzleX] = useState([]);   
  const [puzzleY, setPuzzleY] = useState([]);   
  const [score, setScore] = useState(0);      
  const [selected, setSelect] = useState([[],[]]); 
  const [selectCount, setselectCount] = useState(0);    
  
  useImperativeHandle(ref, () => ({ 
    GameStart: () => {     gameSetup(); },
    getScore : ()=>{return score}
    }));  
 

  useEffect(() => { if (Settings?.pairItems?.length > 0) gameSetup();  setLoadContent(false)}, []); 
 
useEffect(()=>{ 
if(selected[0].length>0 && selected[1].length>0  ){ 
if(puzzleX[selected[0][1]]?.selected===false && puzzleY[selected[1][1]]?.selected===false ){
  puzzleX[selected[0][1]].selected=true
  puzzleY[selected[1][1]].selected=true 
  checkAnswer() 
} 
  setSelect([[],[]])
}  },[selected,selectCount])



  const gameSetup =()=>{   
removeSvgTag("matching_svg_"+DIVID,"line")

    let x=[], y=[], temp=[] 
 
    Settings?.pairItems?.map((val, inx) => { 
      if(!isInArray(val?.pairValue, temp)){
      let obj = filterJson(Settings?.pairItems, "pairValue", val?.pairValue)
      if (isNotEmpty(obj)) {
          obj[0]=Object.assign({},obj[0], {selected:false,isCorrect:false})
          obj[1]=Object.assign({},obj[1], {selected:false,isCorrect:false})

          x.push(obj[0]);
          y.push(obj[1]);
                      
          temp.push(val?.pairValue)
          temp=uniqueArray(temp)
          }
      }
  })
    setPuzzleX(mixArray(x)); setPuzzleY(mixArray(y)) 
    setselectCount(0); setScore(0);   
    seticonSize({
      width:(element("matching_svg_"+DIVID)?.clientWidth)/3 || 150,
      height:(element("matching_svg_"+DIVID)?.clientHeight)/((Settings?.pairItems?.length)/2) || 100
    })   
    setLoadContent(false); 
  }


  const checkAnswer=()=>{  
    let elmA=element(selected[0][0])
    let elmB=element(selected[1][0]) 

    let isCorrect =isEqual(puzzleX[selected[0][1]].pairValue,puzzleY[selected[1][1]].pairValue)
    if(isCorrect) setScore(obj => obj+1) 
    puzzleX[selected[0][1]].isCorrect=isCorrect
    puzzleY[selected[1][1]].isCorrect=isCorrect 

    let svgElement = element("matching_svg_"+DIVID); 
    let newLine =document.createElementNS("http://www.w3.org/2000/svg", "line"); 
    newLine.setAttribute("stroke", (isCorrect)?"rgb(172,255,179,1)":"rgb(255,124,124,1)");
    newLine.setAttribute("className", "path path"+DIVID); 
    newLine.setAttribute("stroke-width", "4"); 
    newLine.setAttribute("x1",elmA.getAttribute("cx") );
    newLine.setAttribute("y1",elmA.getAttribute("cy"));
    newLine.setAttribute("x2",elmB.getAttribute("cx"));
    newLine.setAttribute("y2",elmB.getAttribute("cy") );
    svgElement.appendChild(newLine);  

    setActionCount((obj) =>{ 
      obj=obj+1; 
      if((obj>=(Settings?.pairItems?.length)/2)) GameFinsh(true)
      return obj;
    } ) 

  
  }     
  
  
async function onOptionSelect(id,cardB,type){ 
let temp=selected; temp[type]=[id,cardB,type];setSelect(temp)  
setselectCount(o=>o+1)
}
  
  
  return ( 
        <div className="flex flex-col m-auto p-3 sm:p-4 w-[70%] h-[99%] inner-frame rounded-xl smMax:p-5 bg-white relative max-h-[550px]"  id={"gamePanel"+DIVID} >
<MatchSvg DIVID={DIVID} iconSize={iconSize} puzzleX={puzzleX} puzzleY={puzzleY}selected={selected}   onOptionSelect={onOptionSelect}></MatchSvg>

             </div> 
 
  );
}; 

export default React.forwardRef(Matching);