
import { useState } from 'react' 
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { upload } from '@testing-library/user-event/dist/upload';
import { LoadingSpinner } from '../Loading';
import PrimaryButton from '../Button/PrimaryButton';
import { uploadFile } from '../../../../backend/user';
import { useSelector } from 'react-redux';


export const ReFileupload = ({
    PrimaryColor='white',
    secondaryColor='black',
    onchange=null,
}) => {
    const {campaignID,primary_Site_id,renderType} = useSelector((state) => ({campaignID:state.campaign_id,primary_Site_id:state.primary_Site_id,renderType:state.renderType}))


    const [image, setImage] = useState("");
    const [name, setName] = useState("")
    const [file_, setFile_] = useState()
    const [loading,setLoading] = useState(false)
    const [fileupload,setfileupload] = useState(false)

    const handleImageUpload = (event) => {
        const fileList = event.target.files;
        const fileName = event.target.value
        // setBinary(fileList)

        setFile_(fileList[0])
        setName(fileName)
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];

            const reader = new FileReader();

            reader.onload = () => {
                const imageDataUrl = reader.result;
                setImage(imageDataUrl);
            };

            reader.readAsDataURL(file);
        }
    };

    // const ToBinary = (str) => {
    //     let binary = ''
    //     for (let i = 0; i < str.length; i++) {
    //         const binaryvalue = str.charCodeAt(i).toString(2);
    //         binary += binary.padStart(8, '0')
    //     }

    //     return binary

    // }

    const uploadImage = async () => {

        const data = new FormData()
        const binary = image.split('').map(i => i.charCodeAt(0).toString(2)).join('')
        // cosn
        data.append('image', file_)
        setLoading(true)
        const dataType = await uploadFile(data,campaignID,primary_Site_id)
        if (dataType?.success) {toast.success("successfully Upload");onchange(dataType.data.src)}
        setLoading(false)
        setfileupload(true)
    }


    return (
        <div className=' w-full h-[250px]  my-auto overflow-hidden  flex items-center justify-center pt-4 z-100'>
            <div style={{background:PrimaryColor === 'white'?"#ffffff30":PrimaryColor+"30",color:secondaryColor,borderColor:PrimaryColor}} className={`relative w-full h-full my-auto bg-[#F9FAFB] flex  items-center overflow-hidden border-2 border-dotted  rounded-lg  ${image === "" ? "" : "p-2"}`}>


                {
                    image.length == "" ?

                        <input
                            type="file"
                            onChange={handleImageUpload}
                            id='file'
                            // accept='image/*'
                            className=' absolute w-full h-[90%] opacity-0  cursor-pointer text-white '
                            name='file'
                        /> :
                        <div className='overflow-auto w-full h-[90%]  '>

                            <img src={image} alt="show Image" className='opacity-80 w-full h-full ob hidden' />
                        </div>
                }
                <div className='flex absolute  my-auto flex-col  w-full  '>

                    {!Boolean(image) && <div className='flex flex-col mx-auto justify-center'>
                        <span className='flex mx-auto'>
                            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M77.7607 65.8983L39.9661 59.2054C39.6942 59.1574 39.5197 58.8536 39.5774 58.5271L46.2075 20.7644C46.2652 20.4375 46.5321 20.212 46.8035 20.2605L84.5981 26.9529C84.8704 27.0014 85.0435 27.3043 84.9863 27.6316L78.3563 65.3944C78.299 65.7204 78.0326 65.9468 77.7607 65.8983Z" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.78 4.78" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M69.3557 70.2077L18.6202 79.1912C18.2559 79.256 17.897 78.9531 17.82 78.5148L8.91997 27.8226C8.84287 27.3834 9.07686 26.9764 9.44113 26.9121L60.1766 17.9277C60.5418 17.8629 60.8989 18.1654 60.976 18.6046L69.8764 69.2972C69.953 69.735 69.7208 70.143 69.3557 70.2073V70.2077Z" fill="white" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.78 4.78" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M44.0669 42.4351C43.2486 42.0924 42.0291 42.1078 41.127 42.7398C40.0648 43.4837 39.7208 44.9494 40.2497 46.3629C40.8177 47.8843 42.3709 48.5897 43.7301 48.0753C45.1458 47.5401 45.8351 45.9124 45.465 44.3069C45.2756 43.486 44.8085 42.7448 44.0674 42.4351" fill="#2563EB" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M52.4529 55.5879C50.7792 53.6019 48.9338 51.7686 46.9383 50.1094C46.6362 49.8581 46.1917 49.9826 45.9798 50.2796L43.144 54.2518C42.9908 54.4664 42.1238 56.0629 41.8551 56.057C41.5476 56.0507 40.2141 54.3528 39.9977 54.1191L33.3789 46.9626C32.9912 46.5433 32.4403 46.6728 32.2076 47.1699C30.4309 50.9778 29.8164 55.2737 29.0094 59.5746L25.8008 41.3006C25.8008 41.3006 42.1527 38.3417 49.4716 37.0219C50.5364 43.3589 51.8037 49.1966 52.4529 55.5875V55.5879ZM54.69 57.442C54.6945 57.3936 52.3727 42.8432 51.1406 35.6138C51.0792 35.257 50.838 35.075 50.5748 35.0388C50.4427 34.9645 50.2884 34.9404 50.1401 34.9708L25.7579 39.3673C25.7273 39.3723 25.7011 39.3831 25.6732 39.3922L24.2936 39.6367C23.8644 39.7123 23.7075 40.0976 23.7625 40.4508C23.7585 40.5133 23.762 40.5761 23.7729 40.6378L27.702 63.0166C27.7556 63.3222 28.27 63.195 28.5662 63.1434C34.1395 62.1704 45.4695 60.3375 54.4732 58.5694C54.9118 58.4119 54.6914 57.4434 54.69 57.442Z" fill="#2563EB" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M69.8438 17.6202C71.813 16.8347 71.7007 12.2005 71.4816 11.7002C70.6823 12.3422 69.8438 17.6202 69.8438 17.6202Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M73.5243 15.3947C72.9589 13.973 68.565 13.7855 68.2864 13.9802C68.0083 14.1749 72.2795 15.7153 73.5243 15.3947Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.5 66.7999C6.11084 68.1822 10.1391 65.9188 10.4777 65.4909C9.53901 65.0811 4.5 66.7994 4.5 66.7994" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.1783 69.0288C9.16654 67.8634 7.28023 63.8726 6.97907 63.7168C6.67792 63.5606 7.31495 68.073 8.1783 69.0288Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M76.1455 77.5803C78.2644 77.5613 79.8239 73.1975 79.7991 72.6523C78.8235 72.962 76.1455 77.5803 76.1455 77.5803Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M80.3774 76.8374C80.3612 75.3065 76.3302 73.5398 76.0006 73.6209C75.6715 73.7014 79.1015 76.6857 80.3774 76.8374Z" fill="white" />
                            </svg>
                        </span>
                        <p className='flex mx-auto font-semibold text-lg' >Drag & drop</p>
                        <p className='flex mx-auto text-sm'>Upload Only JPG, PNG. Up to 4MB.</p>
                    </div>}
                    <span className={` text-center ${image === "" ? "hidden" : "inline-block"}`}>{name.split('\\').pop()} </span>
                    <div className='flex w-full pt-6  justify-center space-x-4' >

                        {!Boolean(image) ?
                            <label style={{background:PrimaryColor,color:secondaryColor}} className='py-2 px-4 font-medium text-sm bg-primary rounded-lg text-white cursor-pointer' for='file' >Browse Image</label>
                        : loading? <LoadingSpinner text={"File Uploading..."} /> : <>
                        <PrimaryButton style={{background:PrimaryColor,color:secondaryColor,borderColor:PrimaryColor}} OnClick={() => setImage("")} text={"cancel"} />

                        { !fileupload && <PrimaryButton style={{background:PrimaryColor,color:secondaryColor,borderColor:PrimaryColor}} OnClick={() => uploadImage()} text="Upload" disabled={!Boolean(image)} />}
                        </>}

                    </div>
                </div>

            </div>






        </div>
    );
}
