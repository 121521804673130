import { isEmpty, isNotEmpty } from "../Components/Utils/helper";


export function winMsgStatCookie({siteId="", campaignId="", cookieType="", scriptElmId=""} ){
    if(isEmpty(siteId) || isEmpty(campaignId) || isEmpty(cookieType) || isEmpty(scriptElmId)) return;
    window.parent.postMessage({  'type': 'engegeStatCookieInc',  siteId:siteId,  campaignId:campaignId, cookieType:cookieType,  scriptElmId:scriptElmId    }, "*");  
}


export const  getEngageScriptInfo = async (obj) =>{
   
    var data=obj;

    return new Promise ((resolve, reject)=>{
    try{

        function engageScriptMessages(message) {
            message=message?.data
    
            if (isNotEmpty(message?.type) && message?.type==="engageScriptInfo") {  
    
            if(isNotEmpty(message?.userAgent)) data.deviceInfo.userAgent=message?.userAgent
            if(isNotEmpty(message?.origin)) data.deviceInfo.origin=message?.origin
            if(isNotEmpty(message?.render_path)) data.deviceInfo.render_path=message?.render_path 
            if(isNotEmpty(message?.scriptElmId)) data.deviceInfo.scriptElmId=message?.scriptElmId 
    
            window.removeEventListener("message", engageScriptMessages, false);     
                
            resolve(data)
    
            } else { resolve(data) }   
        }
        
        window.addEventListener("message", engageScriptMessages, false);
        var timeout = setTimeout(function() {
            window.removeEventListener("message", engageScriptMessages, false);
          }, 10000);              
        window.parent.postMessage({ 'type': 'envInfo'}, "*"); 
        }
        catch(e){
            console.log(e)
            resolve(data)
        }
    
    })

}


export const GetScriptCampInfo = () => {

    return new Promise (async (resolve,reject)=>{
        try{
      

            let data = {
                "site_id":"",
                "campaign_id":""
            }            

            function engageScriptCampMessages(message) {

                message=message?.data

                if (isNotEmpty(message?.type) && message?.type==="engageScriptCampInfo") {  

                if(isNotEmpty(message?.site_id)) data.site_id=message?.site_id
                if(isNotEmpty(message?.campaign_id)) data.campaign_id=message?.campaign_id

                window.removeEventListener("message", engageScriptCampMessages, false);  
                
                resolve(data)

                } else { resolve(data) }   
            }
            
            window.addEventListener("message", engageScriptCampMessages, false);
            var timeout = setTimeout(function() {
                 window.removeEventListener("message", engageScriptCampMessages, false);
                 resolve(data)
              }, 4000);              
            window.parent.postMessage({ 'type': 'campInfo'}, "*");  
            
            // if(res.status === 200) resolve(res.data);

        }catch(err){
          //  console.log(err);
            reject(err);
        }
    })
}
