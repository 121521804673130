import React, { useState, useEffect, useImperativeHandle } from "react";
import Konva from 'konva';  
import {  getOffset, element } from "../../../Utils/canvas";
import {EllerRow} from './EllerRow' 
import "../../../Common/commonStyle.css";
import "./style.css"


const FindPath = ( { data },ref) => { 
   const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID,setStartTime,gameInit} = data

  let levels={ "easy":5,  "normal":7,  "medium":10, "hard":13 } 
  const BOX = levels[Settings?.gameLevel];  
  const [score, setScore] = useState(0);  
  const strokColor = (Settings?.strokeColor || "white")

    
  let width, height;
 
  useImperativeHandle(ref, () => ({ 
    GameStart: async () => {   await  gameSetup();  },
    getScore: () => {     return score;   }
  }));

   
  useEffect(() => { gameSetup() }, []);

 
const gameSetup = async () => {  setActionCount(0); setScore(0);  await drawMaze(); 

};

 
const drawMaze = async ()=>{  
 width= document.querySelector("#gamePanel"+DIVID).clientWidth; height= document.querySelector("#gamePanel"+DIVID).clientHeight; 
 let lessSize=150
 if( width < height) lessSize = width
 else lessSize = height

 document.querySelector("#gamePanel"+DIVID).style.width=lessSize+"px"
 document.querySelector("#gamePanel"+DIVID).style.height=lessSize+"px"

 height=lessSize
 width=lessSize

const DELTA_WIDTH = width/BOX, DELTA_HEIGHT = height/BOX;
var stage = new Konva.Stage({container: 'gamePanel'+DIVID,width: width,height:height});
stage.getContainer().style.margin = 'auto';

//  stage.getContainer().style.backgroundColor = '#FAFCFE';
var layer = new Konva.Layer(), oldMazeRow = null; stage.add(layer); 
 var imageObj1 = new Image(); imageObj1.onload = function () {
  let square = new Konva.Image({x: 0, y: 0, height: DELTA_HEIGHT*0.7, width: DELTA_WIDTH*0.7, image: imageObj1,offsetX:-DELTA_WIDTH*0.15,offsetY:-DELTA_HEIGHT*0.15});
  
  
var imageObj = new Image(); imageObj.onload = function () {let target = new Konva.Image({ x:(BOX-1)*DELTA_WIDTH, y:(BOX-1) *DELTA_HEIGHT,image: imageObj, width: DELTA_WIDTH,height: DELTA_HEIGHT, scaleX:0.75,scaleY:0.75,offsetX:-DELTA_WIDTH*0.25,offsetY:-DELTA_HEIGHT*0.2});layer.add(target);}; imageObj.src = Settings?.targeticon;
let lineStyle={stroke: '#204056',strokeWidth: 2,lineCap: 'round',lineJoin: 'round'};
let curCell={row:0,column:0} ,temp=[],movePathArray=[]; 

// GENERATE AND DRAW THE MAZE
for (var i=0;i<BOX;i++){  
    let mazeRow=new EllerRow({ length: BOX, previousRow: oldMazeRow, closeMaze: (i+1===BOX)})  
    let object=mazeRow.exportToObj()  
    oldMazeRow = mazeRow;   
    temp.push(object.walls) 
    let lineStyleOuter = { stroke: strokColor, strokeWidth: 5, lineCap: 'round', lineJoin: 'round' };
    for(var j=0; j<BOX;j++){
      let cellData=object.walls[j] 
      if(cellData.left)  {
        let points = { points: [(j * DELTA_WIDTH) , (i * DELTA_HEIGHT), (j * DELTA_WIDTH) , ((i + 1) * DELTA_HEIGHT)] }
       let blackLine = new Konva.Line( Object.assign({}, lineStyleOuter, points));
       layer.add(blackLine);} 
     if(cellData.up)  {
       let points = { points: [(j * DELTA_WIDTH), (i * DELTA_HEIGHT) , (j * DELTA_WIDTH) + DELTA_WIDTH, (i * DELTA_HEIGHT) ] }
       let blackLine = new Konva.Line( Object.assign({}, lineStyleOuter, points));
       layer.add(blackLine);} 
      if(cellData.right)  {
        let points={points: [ (j*DELTA_WIDTH)+DELTA_WIDTH, (i*DELTA_HEIGHT),  (j*DELTA_WIDTH)+DELTA_WIDTH, ((i+1)*DELTA_HEIGHT)   ]}
        let blackLine = new Konva.Line( Object.assign({}, lineStyleOuter, points));
        layer.add(blackLine);} 
      if(cellData.down)  {
        let points={points: [ (j*DELTA_WIDTH), (i*DELTA_HEIGHT)+DELTA_HEIGHT,  (j*DELTA_WIDTH)+DELTA_WIDTH,  (i*DELTA_HEIGHT)+DELTA_HEIGHT,    ]}   
        let blackLine = new Konva.Line( Object.assign({}, lineStyleOuter, points));
        layer.add(blackLine);} 
   }}

   setLoadContent(false); 

// MOVE FUNCTIONS
const moveLeft =(type)=>{
  if( curCell.column<=0 ) return;   
  if(temp[curCell.row][curCell.column-1].right===false){
 // square.x(square.x() - DELTA_WIDTH);  
  // movePathArray.push("L")           
  animateMove("L")
  curCell.column-=1; 
  if(type==="mouse") setActionCount(obj=>obj+1)


  
}}
const moveRigth =(type)=>{
  if((curCell.column+1)>=BOX) return;   
  if(temp[curCell.row][curCell.column].right===false){
 // square.x(square.x() + DELTA_WIDTH);   
//  movePathArray.push("R");
  animateMove("R")
  curCell.column+=1; 
  if(type==="mouse") setActionCount(obj=>obj+1)

  
}}
const moveUp =(type)=>{
  if( curCell.row<=0 ) return;        
  if(temp[curCell.row-1][curCell.column].down===false){   
 // square.y(square.y() - DELTA_HEIGHT);  
  // movePathArray.push("T");      
  animateMove("T")
  curCell.row-=1; 
  if(type==="mouse") setActionCount(obj=>obj+1)
}}
const moveDown =(type)=>{
  if( (curCell.row+1)>= BOX) return;   
  if(temp[curCell.row][curCell.column].down===false){
 // square.y(square.y() + DELTA_HEIGHT); 
//  movePathArray.push("B");   
  animateMove("B")    
  curCell.row+=1; 
  if(type==="mouse") setActionCount(obj=>obj+1)
}} 
  


async function moveAt(layerX, layerY) { 
  layerX=Math.floor(layerX) 
  layerY=Math.floor(layerY) 

if(( layerY>(curCell.row*DELTA_HEIGHT)&& layerY<(curCell.row+1)*DELTA_HEIGHT) ){
  if( layerX>(curCell.column*(DELTA_WIDTH)-DELTA_WIDTH)&& layerX<(curCell.column*DELTA_WIDTH)-DELTA_WIDTH/20){ moveLeft("mouse")  } //LEFT  
  if( layerX>((curCell.column+1)*DELTA_WIDTH)+DELTA_WIDTH/20 && layerX<((curCell.column+1)*DELTA_WIDTH+DELTA_WIDTH) ){ moveRigth("mouse") } // RIGHT
}
if(( layerX>(curCell.column*DELTA_WIDTH)&& layerX<(curCell.column+1)*DELTA_WIDTH) ){
  if( layerY>(curCell.row*(DELTA_HEIGHT)-DELTA_HEIGHT)&& layerY<(curCell.row*DELTA_HEIGHT)-DELTA_HEIGHT/20){  moveUp("mouse") } //TOP
  if( layerY>((curCell.row+1)*DELTA_HEIGHT)+DELTA_HEIGHT/20 && layerY<((curCell.row+1)*DELTA_HEIGHT+DELTA_HEIGHT)){  moveDown("mouse") } //BOTTOM 
}
if(curCell.row===(BOX-1) && curCell.column===(BOX-1) ) {
  curCell={row:0,column:0};
  GameFinsh(true)
} 
}  

var fired = false;
// KEYBOARD EVENT
var container = stage.container(); container.tabIndex = 1;
container.focus({preventScroll: true});
container.addEventListener('keydown', function (e) {
  e.preventDefault();
  if (e.repeat) { return }
  if(!fired) {
    fired = true;
    if (e.keyCode === 37 ) moveLeft("key")
    else if (e.keyCode === 39)  moveRigth("key")      
    else if (e.keyCode === 38) moveUp("key")
    else if (e.keyCode === 40) moveDown("key")
    else return;

    if(curCell.row===(BOX-1) && curCell.column===(BOX-1) ) {
    curCell={row:0,column:0};
    GameFinsh(true)
  }}
});

container.onkeyup = function() {
fired = false;
};

// MOUSE EVENT  
square.on('mousedown', function (event) {
    stage.on('mousemove', function(e){moveAt(e.evt.layerX,e.evt.layerY)});       
    stage.on('mouseup', function () { stage.off("mousemove"); });
  });   
square.on('mouseenter', function () {stage.container().style.cursor = 'pointer';}); 
square.on('mouseleave', function () {stage.container().style.cursor = 'default';});

 
// MOBILE EVENT
square.addEventListener("touchstart", function (event) {
    stage.addEventListener("touchmove",function(e){
      let offset=getOffset( document.querySelector("#gamePanel"+DIVID));
      moveAt(e.changedTouches[0].pageX-offset.left , e.changedTouches[0].pageY-offset.top) });
    stage.addEventListener("touchend",  function () { stage.removeEventListener('touchmove'); });
}); 

  function animateMove(direction) {
      switch (direction) {
          case "L":
              square.x(square.x() - DELTA_WIDTH);
              break;
          case "R":
              square.x(square.x() + DELTA_WIDTH);
              break;
          case "T":
              square.y(square.y() - DELTA_HEIGHT);
              break;
          case "B":
              square.y(square.y() + DELTA_HEIGHT);
              break;
          default: break;
      }
  }


// var anim = new Konva.Animation(async function (frame) {
//   if (movePathArray.length>0){
//   switch(movePathArray.shift()){
//     case "L":
//        square.x(square.x() - DELTA_WIDTH);  
//     break;
//     case "R":
//       square.x(square.x() + DELTA_WIDTH);   
//     break;
//     case "T": 
//     square.y(square.y() - DELTA_HEIGHT);  
//     break;
//     case "B":
//       square.y(square.y() + DELTA_HEIGHT);  
//     break;
//     default: break;    
//   }
//   } else { return false; }  
// }, layer);
// anim.start();

  
layer.add(square);}; imageObj1.src = Settings?.pointericon;


}  


function actionCnt (e){
  e.preventDefault();
  if (e.repeat) { return }
  if (e.keyCode === 37 || e.keyCode === 39 ||e.keyCode === 38 || e.keyCode === 40) setActionCount( obj => obj+1)
}

  return ( 
    <div className="w-full h-full flex flex-col"><div
    // className="flex h-[80%] w-4/5 m-auto " id={"gamePanel" + fieldID} style={{ border: " 1px solid #204056", borderRadius: "5px" }}>

         className="flex w-[95%] h-[90%] m-auto outline-none " id={"gamePanel" + DIVID} onKeyDown={actionCnt} style={{ border: " ", borderRadius: "5px" }}> 
    </div> </div>
    
    
            );
};
 

export default React.forwardRef(FindPath);