import toast from 'react-hot-toast'; 
import Axios from './axios';


export const Registeruser = (payload) =>  {

    return new Promise (async (resolve,reject)=>{
        

        Axios.post(`/register`,payload,{headers: {platform:"engage"}}).then (resdata => {
            resolve(resdata.data);

        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    
    })



}

export const EmailTokenVerify = (token) =>  {

    return new Promise (async (resolve,reject)=>{

        Axios.post(`/email/verify/${token}`,{headers: {platform:"engage"}})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            // console.log( err.response.data );
            reject(err.response.data)
        } )

    })

}

export const getUser = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await Axios.get('/user/me');
            
            // console.log(res);

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            console.log(err);
            reject(err);
        }
    })
}


export const assetuploadFile = (file) => {
    return new Promise(async (resolve, reject) => {
        try {

            const res = await Axios.post('/editor/assert/upload?type=image', file);


            // console.log(res);

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}

export const uploadFile = (file,campaign_id,primary_id) => {

    return new Promise(async (resolve, reject) => {

        try {

            let header={headers:{
                "x-ps-id":primary_id
            }}

            const res = await Axios.post(`/assert/file/upload?type=image&campaign_id=${campaign_id}`, file,header);


            // console.log(res);

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}

export const getAssetFile = () => {
    return new Promise(async (resolve, reject) => {
        try {

            const res = await Axios.get('/editor/getAllAssets');


            // console.log(res);

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}
export const getDashboardInfo = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await Axios.get('/user/dashboard');
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}

export const updateUser = (payload) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await Axios.post('/user/me',payload);
            
            // console.log(res);

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}

export const templatelist = (industry,category) => {
    return new Promise (async (resolve,reject)=>{
        try{
            let url = `/templates/list?industry=${industry}&category=${category}`
            const res = await Axios.get(url);
            // console.log(res);
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            console.log(err);
            reject(err);
        }
    })
}

export const Createcampaign = (industry,category,template_id) => {
    return new Promise (async (resolve,reject)=>{
        try{
            let url = `/campaign/create`;
            let payload = {
                industry:industry,
                category:category,
                template_id:template_id,
            };

            const res = await Axios.post(url,payload);
            
            // console.log(res);
            if(res.status == 200) resolve(res.data);
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something when to wrong, please Contacrt Support")

            console.log(err);
            reject(err);
        }
    })
}