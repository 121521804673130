import React, { useState, useEffect, useImperativeHandle } from "react";  
import { mixArray, isEqual,element, isEmpty, sleep, isNotEmpty } from "../../../Utils/helper";
import "../../Common/commonStyle.css";
import { getImageBase64 } from "../../../../backend/templates";
import { QuizOptionBtn, YesOrNoOptionBtn } from "../Quiz/OptionBtn";
 
const MemoryObject = ({ data },ref) => { 
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data
  
  const [curQuizIndex, setCurQuizIndex] = useState(0); 
  const [curMemoryIndex, setCurMemoryIndex] = useState(0);
  const [showMemory, setShowMemory] = useState(true);
  const [isPreLoad, setPreLoad] = useState(true);

  const [score, setScore] = useState(0);  
  const [progress, setProgress] = useState(0);  
  const [questions, setQuestions] = useState([]);
  const progressStep = (100 / questions.length).toFixed(2);   

  
  useImperativeHandle(ref, () => ({ 
    GameStart: () => {   gameSetup(); },
    getScore : ()=>{return score}
    })); 
    

  useEffect(() => { if (Settings?.questions.length > 0) gameSetup();}, []);
  useEffect(() => { if ( questions.length!==0 && curQuizIndex >= questions.length) {GameFinsh(true) } }, [curQuizIndex]);

  
  async function preLoad(){
    setLoadContent(true)
    let temp=Settings?.questions, newList=[],newMemoryList=[];
  
   for(let i=0;i<temp?.length;i++){ 
      let base64 = isEmpty(temp[i].image)? "" : await getImageBase64(temp[i].image, 300,300);  

      newMemoryList=[];
      for(let j=0;j<temp[i]?.memoryData?.length;j++){ 
        let memoryBase64=isEmpty(temp[i].memoryData[j])? "" : await getImageBase64(temp[i]?.memoryData[j]?.image, 300,300);  
        newMemoryList.push({...temp[i]?.memoryData[j],memoryBase64});
      } 

    newList.push({...temp[i],base64,newMemoryList});
    setQuestions(newList);
     setCurQuizIndex(0);
    slideShow(0)
     setLoadContent(false);
   }}

  const gameSetup = async ()=>{ setLoadContent([]); setCurMemoryIndex(0); setScore(0);   setProgress(0); await preLoad();   }

 


const slideShow =(qinx) =>{ 
  if(isNotEmpty(questions[qinx]?.newMemoryList)){
 
  setShowMemory(true);   
  
  questions[qinx]?.newMemoryList?.map((val,inx)=>{
    setTimeout(()=>{ setCurMemoryIndex(inx); },inx*5000)
  })  
  
   setTimeout(()=>{ setShowMemory(false); },questions[qinx]?.newMemoryList?.length * 5000)

}  
}
 


 
 
  const checkAnswer = async (userAnswer) => { 
    setProgress(parseInt(progress) + parseInt(progressStep));
    if (isEqual(userAnswer.toUpperCase() , (questions[curQuizIndex]?.answer).toUpperCase())) setScore(score + 1);
    setCurQuizIndex(curQuizIndex + 1);
    slideShow(curQuizIndex+1)
    setActionCount(curQuizIndex)
  };  

  return ( 
        <div className=" w-full inner-frame p-6 h-full smMax:p-4  relative"   id={"gamePanel"+DIVID} >
           {/* <div className="w-full h-6 smMax:h-4 bg-gray-200 rounded-full dark:bg-gray-700 grid justify-items-start mb-11 smMax:mb-9"> <div   className="h-6 smMax:h-4 bg-blue-600 rounded-full dark:bg-blue-500"   style={{ width: progress + "%", transition: "width 2s" }} ></div></div> */}
         



{
  questions[curQuizIndex]?.newMemoryList?.length>0 && showMemory ?
  <div id={"memoryArea"+DIVID}>
  <div className={`question   
  text-lg font-semibold
  sm:text-xl  
  md:text-2xl  
  lg:text-3xl lg:font-bold
  
  mb-5 smMax:mb-8 flex  justify-center `}>  {questions[curQuizIndex]?.newMemoryList[curMemoryIndex]?.title}  </div>
  
<center> <div className="flex justify-center  h-[10rem] w-[90%] sm:w-[63%] sm:h-[18rem]">  <img src={questions[curQuizIndex]?.newMemoryList[curMemoryIndex]?.memoryBase64} alt="" className="   " /> </div></center>


   </div> 


  :

  <div id={"quizArea"+DIVID}>
  <div className={`question   
  text-lg font-semibold
  sm:text-xl  
  md:text-2xl  
  lg:text-3xl lg:font-bold
  
  mb-5 smMax:mb-8 flex  justify-center `}>  {questions[curQuizIndex]?.question}  </div>
  
<center> <div className="flex justify-center  h-[10rem] w-[90%] sm:w-[63%] sm:h-[18rem]">  <img src={questions[curQuizIndex]?.base64} alt="" className="   " /> </div></center>

   {questions[curQuizIndex]?.optionType==="yerorno"  ? <YesOrNoOptionBtn  className=" inset-x-0 bottom-0 h-16 mt-[3rem]"  style={{ marginBottom: "17em" }}  checkAnswer={checkAnswer}></YesOrNoOptionBtn> :
   <QuizOptionBtn  className=" inset-x-0 bottom-0 h-16"  style={{ marginBottom: "17em" }}  checkAnswer={checkAnswer} options={questions[curQuizIndex]?.options}></QuizOptionBtn> 
   }
   </div>

}


         
        


    




             </div> 
 
  );
}; 

export default React.forwardRef(MemoryObject);