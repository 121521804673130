import { useEffect, useState } from "react"
import { getDivId, isNotEmpty, isTrue } from "../../Utils/helper"
import { getImageBase64 } from "../../../backend/templates"
import ScratchCard from "simple-scratch-card"
import { CgSpinner } from "react-icons/cg"

export function NonGameScratchCard ({ThankYouPageObj={}}) {
    const [isScratchOpened, SetscratchOpened]= useState(false)
    const [scratchSize, setScratchSize]=useState([250,250])
    const [isBase64Loading, setBase64Loading]=useState(false)
    const [cnt, steCnt]=useState(0)  
    const [base64, setBase64]=useState("")
    const [isCopied,setCopyStatus] = useState(false) 
    const [DIVID, setDivId] = useState(getDivId())


    const couponBg=(ThankYouPageObj?.couponBgType==="image")? "url("+(ThankYouPageObj?.couponBgImage || "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/9288f5c06385de9b9a6e45841f0da03c.png")+") 0% 0% / 100% 100% no-repeat" : ThankYouPageObj?.couponBgColor || "#f5f5dc" 

    // Loading Scratch Card Image and Size
    useEffect(()=>{ 
        setBase64Loading(true);
        // reSize();
        loadBase64();
        SetscratchOpened(true) 
    },[])

    const copy = () => { 
    navigator.clipboard.writeText(ThankYouPageObj?.couponCode);
    setCopyStatus(true); setTimeout(()=>{  setCopyStatus(false) },2100) 
    } 

    function reSize(){
        var couponWidth=document.querySelector("#scratchCardStyle"+DIVID)?.clientWidth || 150;  
        var couponHeight=document.querySelector("#scratchCardStyle"+DIVID)?.clientHeight || 150
        setScratchSize([couponWidth, couponHeight])  
    }

    async function loadBase64(){
        // reSize();
        let base64Temp= await getImageBase64(ThankYouPageObj?.couponScratchImage || "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1c0488c69a9f0088281046f49729e841.png",600,600)
        setBase64(base64Temp)
        steCnt(o => o+1);

        reSize();
        setTimeout(()=>{
        setBase64Loading(false) },200)
      }

return (
<>{isScratchOpened && isTrue(ThankYouPageObj?.couponStauts)   &&

    <div className="flex justify-center" >
    <div  id={"scratchCardStyle"+DIVID} className="scratchCardParent flex h-full w-full"  key={cnt+"-"}>
    {isBase64Loading ? <div className="flex items-center m-auto"> <CgSpinner className="w-8 h-8 animate-spin text-primary" /> <p className="text-sm font-medium m-2" > {"Loading"}  </p> </div>
    :
    <ScratchCard key={cnt+"-"} cover={base64}  percent={45} width={scratchSize[0]} height={scratchSize[1]}   onComplete={(e) => {
        // console.log(e)
        }}  > 
        <div className="scratchCardInner flex flex-col justify-center items-center "  style={{background:couponBg}} >
            {isNotEmpty(ThankYouPageObj?.couponTitle) && <div><h3 className=" text-[#06152D] mb-[10px] font-bold noselect" >{ThankYouPageObj?.couponTitle}</h3></div>}
            {isNotEmpty(ThankYouPageObj?.couponCode) && <div className="text-[14px] text-[#06152D] mt-[12px] noselect">{isCopied? "Copied!" :  "Coupon Code" }</div> }
            {isNotEmpty(ThankYouPageObj?.couponCode) && <div className="w-full mt-[5px] cursor-pointer mb-[10px]"  onClick={()=>copy()}><p className="flex-row flex-wrap text-[#06152D] border-dashed border-2 border-gray-300 m-auto   text-[24px] w-fit flex justify-center font-bold bg-gray-200 p-[3px] rounded-md  min-w-[150px] " > {((ThankYouPageObj?.couponCode).split(""))?.map((text,inxKey)=>{ return <span  key={inxKey} className="ml-[4px] mr-[4px]">{text}</span> })} </p></div>}
            {isNotEmpty(ThankYouPageObj?.couponDescription) && <div className="w-full mt-[15px] text-[#06152D] noselect">  <center>  {ThankYouPageObj?.couponDescription}</center></div>}
        </div>
    </ScratchCard>
    }
    </div>
    </div>
    }</>
)
}