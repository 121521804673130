import React, { useState } from "react";
import PrimaryButton from "./Button/PrimaryButton"; 
import { isNotEmpty, onImageError } from "../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";

export const WelComePage = ({  StartAction = null,  gaemstatus = false, bannerAction= null, WelComePageObJ = {} }) => {  

    const { campaignSettings } = useSelector(state => ({ campaignSettings: state.campaignSettings }));

    const { fontSize } = campaignSettings;

    const [start, setStart] = useState(false);

    const GameStartACtion = () => {

        setStart(true);
        bannerAction(true);

        setTimeout(() => { StartAction(); }, 700);

    }

    const TitleSize = {
        "small": "text-[16px] 2xl:text-[18px]",
        "medium": "text-[18px] 2xl:text-[22px]",
        "large": "text-[22px] 2xl:text-[24px]"
    }

    const DescriptionSize = {
        "small": "text-[12px] md:text-sm",
        "medium": "text-sm md:text-base",
        "large": "text-base md:text-[20px] font-medium"
    }

    const ButtonSize = {
        "small": "text-[12px]",
        "medium": "text-sm",
        "large": "text-lg"
    }

    return (

        <div className={` transform transition-all duration-700  ${gaemstatus ? 'hidden' : ''} ${ start ? "#ffffff00" :"bg-[#ffffff50]"} absolute flex flex-col left-0 h-full top-0 w-full overflow-auto `} style={{ zIndex: '1' }} >

            <div className={`transform transition-all duration-700 ${start ? 'scale-0' : 'scale-100'} relative w-[80%] h-fit min-h-[300px] flex flex-col  bg-no-repeat border-1 rounded-2xl px-8 py-10 space-y-6 my-auto mx-auto justify-center bg-white shadow-lg`} >

                {isNotEmpty(WelComePageObJ?.imageUrl) && <div className=" flex mx-auto rounded-full w-auto h-full max-w-[300px] object-cover  shadow-xl" >

                    <img src={WelComePageObJ?.imageUrl} onError={onImageError} alt="thumbnail" className="max-w-[360px] min-w-[280px] mx-auto rounded-xl" />

                </div>}

                <div className="w-[95%] sm:w-3/5 mx-auto flex flex-col space-y-6">


                    {isNotEmpty(WelComePageObJ?.titleContent) && <p className={` w-full mx-auto text-center font-semibold ${TitleSize[fontSize]} `}>{WelComePageObJ?.titleContent || "Play the game and win a coupon code!"}</p>}

                    {isNotEmpty(WelComePageObJ?.descriptionContent) && <p className={`text-center ${DescriptionSize[fontSize]} break-words`}>{WelComePageObJ?.descriptionContent}</p>}

                    <PrimaryButton height={"h-fit"} classNames={"p-[10px] break-words"} textsize={ButtonSize[fontSize]} text={WelComePageObJ?.buttonText} style={{ background: WelComePageObJ?.buttonBgcolor ?? "bg-black" }} width={'w-fit min-w-[7rem] max-w-[100%]'} margin={'mx-auto'} OnClick={GameStartACtion} />

                </div>

            </div>

        </div>

    )
}