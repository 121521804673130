import React from "react";
import { StringToHtml } from "./Common/StringToHtml";

export const Embed = ({ fieldID = '', position ,priview=false, fieldsobj }) => {


    const titleContent = fieldsobj;   

    return (
        <div id={"gameFrameBorder"+fieldID}  className={`relative bg-white flex flex-col py-6 group `} >
       
            <StringToHtml str={titleContent?.filedContent?.EmbedCode}> </StringToHtml>      
           
        </div>

    )
}