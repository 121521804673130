import React, { useState, useEffect, useImperativeHandle } from "react"; 
import { useSelector } from "react-redux";
import MobileWeelSideTab from "./MobileWeelSideTab"
import DesktopWheelSideTab from "./DesktopWheelSideTab"
import "./style.css"

const SpinWheelSideTab = ({ data },ref) => { 
const [isMobile, setIsMobile]= useState(false)



useEffect(() => {
  function updateSize() {
  setIsMobile(window.innerWidth<550)
  }
  window.addEventListener('resize', updateSize);
  updateSize();
  return () => window.removeEventListener('resize', updateSize);
}, []);


  return ( <>
      {isMobile ? 
            <MobileWeelSideTab  data={data} ref={ref} /> :
            <DesktopWheelSideTab  data={data} ref={ref}  />
      }</>);
  }; 
export default React.forwardRef(SpinWheelSideTab);