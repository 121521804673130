import Main from "./pages/main";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import { Ridir } from "./pages/redir";
import './App.css';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Ridir/>} />
          <Route path='/:id' name="main" element={<Main renderType={"liveRender"}/>} />
          <Route path='preview/:id' name="Campaign Preview" element={<Main renderType={"preview"}/>} />
          <Route path='template-preview/:id' name="Template Preview" element={<Main renderType={"template-preview"}/>} />
        </Routes>          
      </Router>
  );
}

export default App;
