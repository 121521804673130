import React, { useEffect, useState } from "react";
import {   useSelector } from "react-redux";   
import { Polloption } from "./polloptionCard";     
import PrimaryButton from "../Common/Button/PrimaryButton";
import { WelComePageNonGame } from "../Common/WelComePageNonGame";
import Input from "../Common/input/input";
import { ResultPage } from "../Common/ResultPage";
import { CampaignResponseEvent } from "../../Utils/Reponse";
import { Rangeinput } from "../Common/input/rangeInput";
import { element, getVisiblePercentage, isEmpty, isNotEmpty } from "../../Utils/helper";
import { getCampaign, getTemplate } from "../../../backend/templates";
import { GetDEviceInfo } from "../../../backend/GetDeviceInfo";
import { CampaignEvents } from "../../../backend/campaignEvents";
import { RatingIcon } from "../Common/input/StartRating copy";
import { FormProcessBarBlock } from "../../Common/processbarBanner";
 

export const Poll = (data) => {

    const ratingindex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const {fieldID,filedsObj}=data
    const {campaignID,siteId,renderType, primary_Site_id, campaignSettings} = useSelector((state) => ({campaignID:state.campaign_id,siteId:state.site_id,renderType:state.renderType, primary_Site_id:state.primary_Site_id, campaignSettings:state.campaignSettings}))
    const templateData = filedsObj;
    const Settings = templateData?.filedContent
    const startScreenContent = Settings?.game_startscreen_json;
    const mainPageContent = Settings?.game_mainpage_json;
    const resultScreenContent = Settings.game_resultcreen_json;
    const startScreenContentview = startScreenContent?.view ?? true;
    const ArrQuestion = mainPageContent?.questions
    const BrandLabel = Settings?.brandLabel ?? true
    let PayLoad ={};

    const { backButtonContent, nextButtonContent, fieldRequied } = campaignSettings?.defaultContent;

    const { fontSize } = campaignSettings;

    const QueSize = {
        "small": "text-base",
        "medium": "text-lg",
        "large": "text-[22px]",
    }

    const parSize = {
        "small": "text-sm",
        "medium": "text-base",
        "large": "text-[18px]",
    }

    const ButtonSize = {
        "small": "text-[14px]",
        "medium": "text-[16px]",
        "large": "text-[18px]",
    }


    const [ActiveScr, setActiveScr] = useState(0)
    const [progress, setProgress] = useState(15)
    const [UserAns, setUserAns] = useState()
    const [StartGame, setStartGame] = useState(!startScreenContentview);
    const [resultSce, setresultSce] = useState(false)
    const [startSce, setstartSce] = useState(startScreenContentview)
    const [voteCount, setVoteCount] = useState({})
    const [RequiredErr,SetRequiredErr] = useState(false)
    const [Required,SetRequired] = useState(isEmpty(ArrQuestion[ActiveScr]?.required)? true : ArrQuestion[ActiveScr]?.required)
    const [aditionalInfo, setAditionalInfo] = useState({})
    const [anseredCnt, setTotalAnsered] = useState(0)
    const [isClicked, setIsClicked] = useState(false)
    const ProcessBar = Settings?.processbar ?? true

    useEffect(()=>{ sendClickAnalytics(true) },[isClicked])

    
    const getEmptyPollResponse = () =>{
        let NewVoteCount=[]
        ArrQuestion.map(list => {
            if(list?.optionType== 'multioption'){
                return NewVoteCount.push({
                    total: 0,
                    option: list?.options.map((obj)=>{return 0}),
                })
            }
            return NewVoteCount.push({
                total: 0,
                option: [0, 0],
            })
        })
        return NewVoteCount;
     }

    const [SingleUserAns, setSingleUserAns]=useState(getEmptyPollResponse())


    const loadAditionalInfo = async () =>{
         
        var campaign = (renderType==="template-preview") ? await getTemplate(campaignID) : await getCampaign(campaignID); campaign=campaign?.data
        
        let additional_info=isNotEmpty(campaign?.additional_info)?JSON.parse(campaign?.additional_info): {}
        
        let  tempv=isEmpty(additional_info[fieldID]?.pollResp) ?  getEmptyPollResponse() : additional_info[fieldID]?.pollResp 

        setAditionalInfo(additional_info)
        
        setVoteCount(tempv)
    }

    useEffect(()=>{ loadAditionalInfo() },[])

    const GameStart = async () => {
        setStartGame(true)
        setstartSce(false)
        setresultSce(false)
        setUserAns([])
        setActiveScr(0)
        setSingleUserAns(getEmptyPollResponse())  
        sendClickAnalytics(false)

    } 
    
    const previewAction = (index) => {
        if (index === 0) {
            setStartGame(!startScreenContentview)
            setstartSce(startScreenContentview)
            setresultSce(false)
        }
        setActiveScr(index > 0 ? index - 1 : 0)
        SetRequired(ArrQuestion[(index > 0 ) ? index - 1 : 0]?.required ?? false)
        SetRequiredErr(false) 
        sendClickAnalytics(false)
    }

    const sendClickAnalytics = async (toreturn=false)=>{
        if(toreturn || isClicked) return;
        if(renderType==="liveRender"){
            const DEviceData = await GetDEviceInfo()
            const CampEvent = new CampaignEvents({campaignId:campaignID,site_id:siteId, primary_Site_id:primary_Site_id})  
            CampEvent.clicked(DEviceData)
            setIsClicked(true)
        } 
    }

    const nextAction = async (index) => {
        sendClickAnalytics(false)

        
        if(Required && (UserAns[ArrQuestion[index]?.question] == "" || UserAns[ArrQuestion[index]?.question] == undefined ) ) SetRequiredErr(true) 
        else{
        if (index === ArrQuestion.length - 1) {            
            setStartGame(false)
            setstartSce(false)
            setresultSce(true)
        SetRequired(ArrQuestion[0]?.required ?? false)


            if(renderType==="liveRender"){
                let obj={}
                obj[fieldID]={"pollResp":SingleUserAns}
                
                const CampRule = new CampaignResponseEvent({ category:templateData?.category,
                    additional_info:aditionalInfo, pollResp:obj,
                    payload:UserAns,campaignId:campaignID,site_id:siteId,primary_Site_id:primary_Site_id })

                PayLoad = Object.assign(PayLoad,UserAns)
                CampRule.subcripe(PayLoad)

            }
            else console.log("In Preview")
        }
        setActiveScr(index < ArrQuestion.length - 1 ? index + 1 : ArrQuestion.length - 1)
        let a=ArrQuestion[index < ArrQuestion.length - 1 ? index + 1 : ArrQuestion.length - 1]?.required
        SetRequired( isEmpty(a)? true : a)
        SetRequiredErr(false) 
    }

    }

    const yesOrNo = [
        {
            icon: (<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="70" height="70" rx="35" fill="#49D57A" />
                <path d="M32.3582 45.5C32.0128 45.5003 31.6708 45.4323 31.3517 45.3001C31.0326 45.1678 30.7428 44.9739 30.4988 44.7294L22.6089 36.8395C22.1317 36.343 21.8683 35.6793 21.8751 34.9908C21.882 34.3022 22.1585 33.6438 22.6454 33.157C23.1323 32.6701 23.7907 32.3936 24.4793 32.3868C25.1678 32.38 25.8315 32.6435 26.3279 33.1207L32.1811 38.9738L43.4882 25.4053C43.9398 24.8855 44.5772 24.5635 45.2636 24.5084C45.95 24.4534 46.6306 24.6695 47.1593 25.1106C47.6881 25.5517 48.0229 26.1825 48.0918 26.8676C48.1607 27.5527 47.9583 28.2375 47.5279 28.7751L34.3782 44.555C34.1444 44.8356 33.8546 45.0644 33.5273 45.2267C33.2 45.389 32.8425 45.4813 32.4776 45.4976C32.4378 45.5 32.398 45.5 32.3582 45.5Z" fill="white" />
            </svg>
            ),
            type: "yes"
        },
        {
            icon: (<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="70" height="70" rx="35" fill="#DC2626" />
                <path d="M38.7003 35L44.7196 28.9874C44.9658 28.7445 45.1615 28.4553 45.2955 28.1366C45.4294 27.8178 45.4989 27.4757 45.5 27.1301C45.5011 26.7844 45.4337 26.4419 45.3018 26.1223C45.1698 25.8027 44.9759 25.5123 44.7312 25.2679C44.4865 25.0234 44.1958 24.8298 43.8758 24.698C43.5559 24.5662 43.213 24.4989 42.8669 24.5C42.5208 24.5011 42.1784 24.5706 41.8593 24.7044C41.5402 24.8382 41.2507 25.0337 41.0076 25.2797L34.9883 31.2921L28.9691 25.2797C28.4753 24.7953 27.8101 24.5254 27.1181 24.5285C26.4261 24.5316 25.7633 24.8076 25.274 25.2964C24.7847 25.7852 24.5084 26.4472 24.5052 27.1384C24.5021 27.8297 24.7724 28.4942 25.2572 28.9874L31.2763 35L25.2571 41.0125C24.77 41.5053 24.4978 42.1705 24.5 42.863C24.5022 43.5555 24.7786 44.219 25.2688 44.7087C25.759 45.1984 26.4233 45.4744 27.1166 45.4766C27.8098 45.4787 28.4758 45.2068 28.9691 44.7203L34.9883 38.7078L41.0076 44.7204C41.2507 44.9664 41.5402 45.1619 41.8593 45.2957C42.1784 45.4295 42.5209 45.4989 42.867 45.5C43.213 45.5011 43.5559 45.4338 43.8759 45.302C44.1958 45.1702 44.4865 44.9765 44.7312 44.732C44.9759 44.4876 45.1698 44.1972 45.3017 43.8776C45.4337 43.558 45.501 43.2155 45.4999 42.8698C45.4988 42.5241 45.4293 42.1821 45.2953 41.8633C45.1614 41.5446 44.9656 41.2554 44.7194 41.0125L38.7003 35Z" fill="white" />
            </svg>
            ),
            type: "no"
        }
    ]
    
    const UserAction = (value, type, quetype = "") => {
        PayLoad = Object.assign(PayLoad, UserAns)
        if (quetype === "email") PayLoad["email"] = value
        PayLoad[type] = value
        setUserAns(PayLoad)
    }


    const VoteCC = (index,Innerindex) => {                
        let tempSingleAns=SingleUserAns;
        tempSingleAns[index].option[Innerindex]=1
        tempSingleAns[index].total=1 

        let tempVC=voteCount;
        tempVC[index].option[Innerindex]+=1
        tempVC[index].total+=1  
        setVoteCount(tempVC) 

        setSingleUserAns(tempSingleAns) 
    } 
    
    // Keyboard Handling
    useEffect(() => {

        let InPutType=ArrQuestion[ActiveScr]?.optionType, allowNext=true
        if(InPutType==="phone-number" || InPutType==="date"  || InPutType==="text"  || InPutType==="number"  || InPutType==="email" || InPutType==="range" || InPutType==="rating" || InPutType==="starrating") allowNext=false

        const handleKeyDown = (event) => {
            // if only visable greater than 40%        
            let percentage = getVisiblePercentage(element("gameFrameBorder"+fieldID))
            if(percentage<=40) return;
            if (event.key === 'ArrowLeft' && allowNext  )   previewAction(ActiveScr)
            else if (event.key === 'ArrowRight' && allowNext)   nextAction(ActiveScr)
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown); };
        
    }, [ActiveScr, UserAns]);

    useEffect(()=>{
        let queCnt=0;
        Object.keys(UserAns || {}).map((txt)=>{
            if(isNotEmpty(UserAns[txt]) && txt!=="email") queCnt+=1
        })
        setTotalAnsered(queCnt)
    },[UserAns])

    return (
        <div className="relative flex flex-col w-full h-full" id={"gameFrameBorder"+fieldID}  >

    
        {( startScreenContentview && startSce) && <WelComePageNonGame WelComePageObJ={startScreenContent} primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "black"} title={startScreenContent.titleContent} background={Settings?.background} buttenText={startScreenContent.buttonText} StartAction={() => GameStart()} />}

        {StartGame && !resultSce && <div className="w-full h-full transition-all duration-700">

            <div class={`w-full flex h-full relative min-h-[600px] opacity-100  space-y-5`} style={{ background: Settings?.background?.type == "color" ? Settings?.background?.color ?? "#7ED321" : `url(${Settings?.background?.src ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat`,backgroundSize:"cover" }} >
                <div className="relative w-full h-full bg-[rgba(0,0,0,0.3)] flex flex-col overflow-auto list-industry" >

                { ProcessBar && <FormProcessBarBlock primaryColor={ Settings.primaryColor } secondaryColor={ Settings.secondaryColor } currentScr={ActiveScr} AnsCount={anseredCnt} Question={ArrQuestion} childBlock={( <></> )} /> }


                    <div className="w-4/5 flex flex-col space-y-16  m-auto p-4 rounded-lg  ">
                        {
                            ArrQuestion.map((list, index) => {

                                if (ActiveScr === index) return (

                                    <div className="w-full flex flex-col space-y-10 mt-[26px]">

                                        <p className={`w-full transition-all duration-700 font-medium mx-auto text-white ${ QueSize[ fontSize ] } `} style={{color:Settings?.primaryColor ?? "white"}} >{list?.question}{ list?.required && <span>*</span>}</p>
                                        {
                                            String(list?.optionType).toLocaleLowerCase() === 'range' ?

                                                <div className=" w-full">
                                                    <Rangeinput step={list?.step ?? 1}  primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "black"} color="white" minValue={list?.range?.min ?? 0} maxValue={list?.range?.max ?? 100} OnChange={(e) => UserAction(e.target.value, list?.question)} />

                                                    {/* <input type="range" className="w-full" max={100} min={0} onChange={(e)=>UserAction(e.target.value,list?.question)} /> */}
                                                </div>
                                                :
                                                String(list?.optionType).toLocaleLowerCase() === "yesorno" ?

                                                    <div className="grid w-full grid-row sm:grid-cols-2 px-4 gap-4 mx-auto">

                                                        {
                                                            yesOrNo.map((listobj, Innerindex) => {
                                                                let isAnswed = (UserAns?.[list?.question] ?? null) === listobj?.type ? true : false//UserAns[index] === list ? true : false

                                                                let UserAnswer = (UserAns?.[list?.question] ?? null) ? true : false

                                                                let PollVotePer = voteCount[index]?.total !== 0 ? (voteCount[index]?.option[Innerindex] / (voteCount[index]?.total)) * 100 : 0

                                                                return (
                                                                    <Polloption textSize={parSize[fontSize]} primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "black"} pollPer={PollVotePer.toFixed(2)} OptionContent={listobj?.type} Answed={isAnswed} isAnswed={UserAnswer} progress={progress} OnClick={() => {
                                                                        if (!UserAnswer) {
                                                                            UserAction(listobj?.type, list?.question)
                                                                            VoteCC(index, Innerindex)
                                                                        }
                                                                    }} />
                                                                )
                                                            })
                                                        }

                                                    </div>


                                                    :
                                                    String(list?.optionType).toLocaleLowerCase() === 'multioption' ?
                                                        <div className="grid w-full grid-row sm:grid-cols-2 px-4 gap-4 mx-auto">{
                                                            list?.options?.map((listobj, Innerindex) => {
                                                                let isAnswed = (UserAns?.[list?.question] ?? null) === listobj ? true : false//UserAns[index] === list ? true : false
                                                                // let isAnswed = UserAns[index] === list ? true : false
                                                                let UserAnswer = (UserAns?.[list?.question] ?? null) ? true : false

                                                                let PollVotePer = voteCount[index]?.total !== 0 ? (voteCount[index]?.option[Innerindex] / (voteCount[index]?.total)) * 100 : 0
                                                                return (
                                                                    <Polloption textSize={parSize[fontSize]} primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "black"} pollPer={PollVotePer.toFixed(2)} OptionContent={listobj} Answed={isAnswed} isAnswed={UserAnswer} progress={progress} OnClick={() => {
                                                                        if (!UserAnswer) {
                                                                            UserAction(listobj, list?.question)
                                                                            VoteCC(index, Innerindex)

                                                                        }
                                                                    }} />
                                                                )
                                                            })
                                                        }</div>
                                                        : 
                                                        String(list?.optionType).toLocaleLowerCase() === 'rating' ?
                                                        <div className="flex flex-wrap space-x-3 ">
                                                            {
                                                                ratingindex.map((listobj,Innerindex) => {
                                                                    let isAnswed = (UserAns?.[list?.question] ?? null) === listobj ? true : false

                                                                    return (<p className="flex mb-1 w-14 h-14 hover:bg-white hover:text-black cu rounded-lg  border-2 text-lg noselect cursor-pointer"
                                                                        style={{ background: isAnswed ? Settings?.primaryColor ?? "white" : Settings?.primaryColor + "30" ?? "#FFFFFF30", borderColor: Settings?.primaryColor ?? "white", color: isAnswed ? Settings?.secondaryColor ?? "black" : Settings?.primaryColor ?? "white" }}

                                                                        onClick={() => { 
                                                                            UserAction(listobj, list?.question)
                                                                            VoteCC(index, Innerindex)
                                                                         }} ><span className="flex text-center m-auto "  >{listobj}</span></p>)
                                                                })
                                                            }
                                                        </div>
                                                        
                                                        :
                                                        String(list?.optionType).toLocaleLowerCase() === 'starrating' ?
    
                                                        <div className=" w-full">
                                                                
                                                            <RatingIcon isActive={(UserAns?.[list?.question] ?? null) > 0 ? true : false} slectedindex = {(UserAns?.[list?.question] ?? null)} primaryColor={Settings?.primaryColor ?? 'white'} OnClick={(ind) => { UserAction(ind+1, list?.question);VoteCC(index, ind)  }}  width={40} height={40} strokeColor={Settings?.primaryColor ?? "white"} fillColor={ Settings?.primaryColor  } />

                                                       </div>
                                                        :
                                                        <div className="flex flex-col space-y-2.5">
                                                            <Input margin={''} style={{color:Settings?.primaryColor ?? "white"}} type={list?.InPutType} textSize={parSize[fontSize]} textvalue={(UserAns?.[list?.question] ?? null)} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="Enter Value" background='bg-transparent' onchange={(e) => UserAction(e.target.value, list?.question,list?.optionType)} onKeyUp={(e)=>{if(e?.key === 'Enter') nextAction(index)}} />
                                                            {/* <hr className="transition-all border-white" /> */}
                                                            <hr className="transition-all border-white" style={{borderColor:Settings?.primaryColor ?? "white"}}  />

                                                        </div>
                                        }
                                        {/* </div> */}
                                        {RequiredErr && <div className="flex w-full px-4 space-x-4">
                                            <p className="flex space-x-2 bg-[rgb(248,231,232)] text-[rgb(203,70,74)] p-2 w-fit rounded-lg">
                                                <span className="flex my-auto"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6663 11.6797 14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301ZM7.99967 11.333C7.63301 11.333 7.33301 11.033 7.33301 10.6663V7.99967C7.33301 7.63301 7.63301 7.33301 7.99967 7.33301C8.36634 7.33301 8.66634 7.63301 8.66634 7.99967V10.6663C8.66634 11.033 8.36634 11.333 7.99967 11.333ZM8.66634 5.99967H7.33301V4.66634H8.66634V5.99967Z" fill="#CB464A"></path></svg></span>
                                                <span className="flex my-auto">{fieldRequied}</span>
                                            </p>
                                        </div>}
                                        <div className="flex w-full px-4 space-x-4" >
                                            <PrimaryButton text={backButtonContent ?? "Previous"} style={{ color: Settings?.secondaryColor ?? "black", background: Settings?.primaryColor ?? "white", borderColor: Settings?.primaryColor ?? "white" }} fontStyle="font-semibold" classNames={"border-1 border-white noselect"} backgroundColor="bg-white" textcolor="text-black" textsize={ButtonSize[fontSize]} OnClick={() => previewAction(index)} />
                                            <PrimaryButton text={nextButtonContent ?? "Next"} style={{ color: Settings?.secondaryColor ?? "black", background: Settings?.primaryColor ?? "white", borderColor: Settings?.primaryColor ?? "white" }} fontStyle="font-semibold" classNames={"border-1 border-white noselect"} backgroundColor="bg-white" textcolor="text-black" textsize={ButtonSize[fontSize]} OnClick={() => nextAction(index)} />
                                        </div>
                                    </div>)
                            })
                        }

                    </div>
                    <div className="w-full  flex  bottom-8 2xl:bottom-14 mb-1" >
                        <div className="relative mx-auto w-4/5 flex flex-wrap justify-between" style={{color:Settings.secondaryColor??'black'}} >

                            <div className="flex flex-col space-y-2 w-[300px] bg-white rounded-lg py-3 px-4" style={{background:Settings.primaryColor ?? "white"}} >
                                <p className="capitalize font-medium">answered questions: <span>{anseredCnt}</span>/<span>{ArrQuestion.length}</span></p>
                                <div className={`w-full h-2 bg-[rgba(0,0,0,0.3)] rounded-full  grid justify-items-start`} style={{background:Settings.secondaryColor+'40' ?? "#00000040"}} >
                                    <div className={`h-2 w transition-all duration-500 bg-black rounded-full `} style={{ background:Settings.secondaryColor ?? "black", width: `${(anseredCnt / ArrQuestion.length) * 100}%` }} ></div>
                                </div>
                            </div>
                                
                                {BrandLabel &&
                                <div className="flex w-fit flex-col space-y-2  bg-white rounded-lg py-2 h-fit px-4 my-[5px] sm:my-auto" style={{background:Settings.primaryColor ?? "white" ,color:Settings.secondaryColor ?? 'black'}} >
                                    <p className="flex flex-row space-x-2  m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto text-lg">GoZen</span></p>
                                </div>
                            }

                                </div>
                        </div>
                    </div>
                
            </div>


        </div >}
        {resultSce && <ResultPage primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "black"} voteCount={voteCount} background={Settings?.background} ArrQuestion={ArrQuestion} type={'poll'} ThankYouPageObj={resultScreenContent?.results[0]} restartAction={() => GameStart()} />}
    </div>
    )
}
