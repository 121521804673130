import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputWithLabel from "./input/inputWithLabel";
import PrimaryButton from "./Button/PrimaryButton";
import { CampaignEvents } from "../../../backend/campaignEvents";
import { GetDEviceInfo } from "../../../backend/GetDeviceInfo";
import CheckBox from "./input/Checkbox";
import TextareaInput from "./input/textarea";
import { isNotEmpty, trimCommas } from "../../Utils/helper";
import "./commonStyle.css"; 
import DefaultDropMenu from "./input/droplist";

export const LeadFormBlock = (leadData) => {
    const { submit, Settings, DIVID, gamePlaySeconds, isDefaultSpinWheel=false,childStateRef=null, setformWheel=null,setWheelFormEvent=null,  isFor="" }=leadData

    const {campaignID,siteId,renderType, primary_Site_id} = useSelector((state) => ({campaignID:state.campaign_id,siteId:state.site_id,renderType:state.renderType, primary_Site_id: state.primary_Site_id}))
    const [formobject,setformobject] = useState({}) 

    let key=0; 
    const OnSSubmitForm = async (e) => { 
        console.log("first")
        e.preventDefault()
        if(isFor==="spinWheel") setWheelFormEvent(e)
        if(renderType==="liveRender" && isFor!=="spinWheel"){
            try{ 
                const CampaignEvent = new CampaignEvents({campaignId:campaignID,site_id:siteId, primary_Site_id:primary_Site_id}) 
                const DEviceData = await GetDEviceInfo()
            CampaignEvent.subcripe(formobject,encodeURIComponent(e),DEviceData )
                submit() 
                if(isDefaultSpinWheel ) childStateRef?.current?.SpinTheWheel()

            }catch(e){
                console.log(e)     
            }} else{
                console.log("In Preview")
                submit()
                if(isDefaultSpinWheel ) childStateRef?.current?.SpinTheWheel()

            }
             if(isFor!=="spinWheel")  setformobject({})
    }

    const Onchange = (e,name) => {

        let lowercasename = String(name).toLocaleLowerCase()

        let temp = formobject

        if( isNotEmpty(gamePlaySeconds) && gamePlaySeconds>0 ) temp["Play time in seconds"] = gamePlaySeconds;

        temp[lowercasename] =( e.target.type == "checkbox" ) ? e.target.checked :e.target.value;

        if(isFor==="spinWheel") setformWheel(temp) 
        else setformobject(temp) 
        
    }  

    const onSelect = (val,name) => {

        let lowercasename = String(name).toLocaleLowerCase()
        let temp = formobject
        if( isNotEmpty(gamePlaySeconds) && gamePlaySeconds>0 ) temp["Play time in seconds"] = gamePlaySeconds;

        temp[lowercasename] = val;
        if(isFor==="spinWheel")setformWheel(temp) 
         else setformobject(temp) 
        
    }  

    return (
        <div className="absolute overflow-auto flex left-0 h-full top-0  w-full    z-[2]" style={{ background: `rgba(250,250,250,0.4)` }}>

            <div className="w-[60%]
            sm:w-1/2
            lg:w-1/3
            overflow-auto h-fit flex flex-col bg-white  rounded-2xl mx-auto px-4 py-5 
            space-y-6 my-auto justify-center shadow-lg border-2">
                <div className="w-full overflow-auto space-y-4 flex flex-col">
{                   Settings?.imageStatus && <div className="w-fit mx-auto max-w-full">
                        <img draggable={false} src={Settings?.imageSrc} alt="WinnigPrice" className="w-auto" />
                    </div>}
                    {Settings?.titleStatus && <p className="w-fit text-lg sm:text-xl font-semibold mx-auto capitalize noselect text-center"  style={{color:Settings?.titleColor || "#000000"}}>{Settings?.title}</p>}
                </div>
                <form className="flex w-4/5 mx-auto flex-col space-y-4" onSubmit={OnSSubmitForm} >
                    {
                        Settings?.field?.map((list)=>{
                            let opts={}
                            if(list?.required) opts["required"]="required"

                            let tempList=[], textAreaVal=list?.textAreaVal
                            if(isNotEmpty(textAreaVal) && textAreaVal.includes(",")){
                                let temp=textAreaVal.split(",")
                                temp?.map((val)=>{
                                    val=val.trim()
                                    val=trimCommas(val)
                                    tempList.push(val)
                                })
                            }
                            

                            return <>
                            { list?.inputType === 'checkbox'  ?
                            <CheckBox  lableColor={Settings?.formTitleColor} key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} isUrl={isNotEmpty(list?.checkBoxRedirUrl)} redirUrl={list?.checkBoxRedirUrl}/> :
                            list?.inputType === 'textarea' ?
                            <TextareaInput  lableColor={Settings?.formTitleColor} key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> :
                            list?.inputType ==="select" && tempList?.length>0?
                            <DefaultDropMenu  lableColor={Settings?.formTitleColor} label={list?.Label} margin="mb-0 caret-transparent"
                            list={tempList}
                            SelectIndex={true}
                            onchange={(index) => onSelect(tempList[index],list?.Label)}
                          />   
                          
                          :
                            <InputWithLabel  lableColor={Settings?.formTitleColor} key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> 
                        
                            
                            }

                            </>
                        
                        })
                    } 
                    <PrimaryButton  style={{background:(Settings?.btnBgColor || "#2563EB"), color:(Settings?.btnTxtColor || "#FFFFFF")}} text={Settings?.buttenText} id={"submitBtn"+DIVID} type="submit" />
                </form>
            </div>
        </div>
    )
}