import React, { useState, useImperativeHandle,useEffect } from "react";
import Konva from 'konva';
import "../../Common/commonStyle.css";
import { mixArray, element, sleep,  isEqual,isEmpty,get2DnullMatrix, isNotEmpty, randomNumber} from "../../../Utils/helper";
import { getSquareXY,  getOffset} from "../../../Utils/canvas";  


const HiddenObject = ({ data },ref) => { 
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data 

  var width, height
  const [score, setScore] = useState(0);   

  useImperativeHandle(ref, () => ({ 
    GameStart: () => {    generatePuzzle(); setScore(0)},
    getScore : ()=>{return score} }));   
     
  useEffect(() => { generatePuzzle() ; setLoadContent(false)}, []);  

   
 

  const generatePuzzle = async () => {   
    width = element("gamePanel"+DIVID).clientWidth; height = element("gamePanel"+DIVID).clientHeight;
    var activeId=[]

    var imageList=[
      {
        quiz:"",
        imageUrl:"https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/apple.png",
        rotation:0,
        size:[100,90],
        axis:[randomNumber(130,165),randomNumber(45,75)],
        scale:[1,1], 
        id:"AXS1DF"
      },
      {
        quiz:"",
        imageUrl:"https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/carrot.png",
        rotation:0,
        size:[100,90],
        axis:[randomNumber(130,165),randomNumber(45,75)],
        scale:[1,1], 
        id:"AXS2DF"
      },
      {
        quiz:"",
        imageUrl:"https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/orange.png",
        rotation:0,
        size:[100,90],
        axis:[randomNumber(130,165),randomNumber(45,75)],
        scale:[1,1], 
        id:"AXS3DF"
      }
  ]
 
     
    var stage = new Konva.Stage({ container: 'gamePanel'+DIVID, width: width, height: height });   stage.listening(false); stage.getContainer().style.backgroundColor = '#f9f9f9';
    var layer = new Konva.Layer(); stage.add(layer);
    const group = new Konva.Group();    
var imgArray=[],trArray=[],icnArray=[]
    
    var background = new Image(); background.onload = function () {
      let bg = new Konva.Image({x: 0, y:0, height:height, width:width,image: background});  
           bg.on('pointerdown', function (e) {
          let lastClickIcn=imageList.findIndex(p => p.id === e?.target?.attrs?.id);
          activeId=imageList[lastClickIcn]
          imageList?.map((val,inx2)=>{
            disableSelect(icnArray[inx2],trArray[inx2] )           
          })    

        })

    group.add(bg)
    bg.zIndex(0);    
    }; background.src = "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/food.jpg";
    
   


     imageList?.map((val,inx)=>{
        imgArray[inx] = new Image(); imgArray[inx].onload = function () {
        icnArray[inx] = new Konva.Image({x: val?.axis[0], y: val?.axis[1], height:val?.size[0], width:val?.size[1],image: imgArray[inx],draggable: true,id:val?.id});  
        // icnArray[inx].zIndex(inx);       
        group.add(icnArray[inx])

        trArray[inx] = new Konva.Transformer({id:"trans_"+val?.id});
        // trArray[inx].zIndex(inx); 
        trArray[inx].borderStroke("blue")
        trArray[inx].anchorStroke("blue")
        trArray[inx].nodes([icnArray[inx]]); 
        group.add(trArray[inx]);    
          disableSelect(icnArray[inx],trArray[inx] )    

        icnArray[inx].on('pointerdown', function (e) {
          let lastClickIcn=imageList.findIndex(p => p.id === e?.target?.attrs?.id);
          activeId=imageList[lastClickIcn]
          imageList?.map((val,inx2)=>{
            disableSelect(icnArray[inx2],trArray[inx2] )           
          })
          enableSelect(icnArray[inx],trArray[inx] )           

        })
        icnArray[inx].on('pointerdblclick', function (e) {

          let lastClickIcn=imageList.findIndex(p => p.id === e?.target?.attrs?.id);
        if(lastClickIcn<imageList?.length-1) {
         
          let blanceIcn=imageList[lastClickIcn+1]
          imageList[lastClickIcn+1]=imageList[lastClickIcn]
          imageList[lastClickIcn]=blanceIcn 

// console.log(imageList)
imageList?.map((val,inx2)=>{
  stage.find('#'+val?.id)[0].zIndex(inx2)

})
        }

 
          
        })
        icnArray[inx].on('dragend', function (e) {
           
        })

        }; imgArray[inx].src = val?.imageUrl 
      
      

    })
     
    layer.add(group); 
    

      function fitStageIntoParentContainer() {
        var container = document.querySelector("#gamePanel"+DIVID);

        // now we need to fit stage into parent container
        var containerWidth = container.offsetWidth;
        var containerHeight = container.offsetHeight;

        // but we also make the full scene visible
        // so we need to scale all objects on canvas
        var scale = containerWidth / width;
        var hScale = containerHeight / height;

        stage.width(width * scale);
        stage.height(height * hScale);
        stage.scale({ x: scale, y: hScale });
      }

      fitStageIntoParentContainer();
      // adapt the stage on any window resize
      window.addEventListener('resize', fitStageIntoParentContainer);



    stage.listening(true); 
     

    function disableSelect(icon,transform){   
      icon.draggable(false);
      transform.resizeEnabled(false);
      transform.rotateEnabled(false);
      transform.borderEnabled(false);  
      stage.find("#"+transform?.attrs?.id)[0].zIndex(1)    
    }

    function enableSelect(icon,transform){
      icon.draggable(true);
      transform.resizeEnabled(true);
      transform.rotateEnabled(true);
      transform.borderEnabled(true);    
      stage.find("#"+transform?.attrs?.id)[0].zIndex(3)    
    }


    

 
}

  return ( 
      <div   className="flex w-full flex-col justify-center h-full">
      
      <div className="flex flex-row text-[#0A2551] break-all font-bold text-lg sm:text-2xl mt-[2rem] sm:mt-4 justify-center">
          {Settings?.puzzleHint}</div>
           <div className=" mx-auto mt-5 w-[100%] h-full  " id={"gamePanel"+DIVID} ></div>  
       </div>
  ); };
 

export default React.forwardRef(HiddenObject);

