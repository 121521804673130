import axios from "axios";
import { getEngageScriptInfo } from "./messanger";

export const GetDEviceInfo = () => {

    return new Promise (async (resolve,reject)=>{
        try{
            const info = { countryCode: "", ip: "" };
            const res = await axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            if (res.data) {

                const fields = res.data.split("\n");
    
                for (let i = 0; i < fields.length; i++) {
    
                    const line = fields[i];
    
                    const [key, val] = line.split("=");
    
                    if (key === "loc") {
                        info.countryCode = val;
                    }
    
                    if (key === "ip") {
                        info.ip = val;
                    }
                }
    
            }


            let data = {
                deviceInfo:{
                "userAgent": navigator.userAgent,
                "country": info.countryCode,
                "ip": info.ip,
                "origin": window.location.hostname,
                "render_path":"/"
            }
            }            


            data=await getEngageScriptInfo(data)

            resolve(data)
       
            
            // if(res.status === 200) resolve(res.data);

        }catch(err){
            console.log(err);
            reject(err);
        }
    })
}