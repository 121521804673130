import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignEvents } from "../backend/campaignEvents";
import { getCampaign, getTemplate } from "../backend/templates";
import { Actions } from "../store";
import { RenderErrorContent } from "../Components/Common/errorBlock";
import RenderComponents from "../Components";
import { GetDEviceInfo } from "../backend/GetDeviceInfo";
import { isEmpty, isEqual, isNotEmpty } from "../Components/Utils/helper";
import { EngageLoading } from "../Components/Common/EngageLoading";
import { GetScriptCampInfo } from "../backend/messanger";
import WebFont from 'webfontloader' 

const Main = ({status = true, renderType="preview"}) => {

    const Params = useParams();

    const Dispatch = useDispatch();

    const { campaignSettings } = useSelector( state => ({ campaignSettings:state.campaignSettings }) );

    const { fontFamily, backgroundType, spaceBetweenTheBlock, image, color } = campaignSettings;

    useEffect(()=>{

        WebFont.load({ google : { families : [ fontFamily ] }})  

    },[fontFamily])  

    const spaceBetween = [ "space-y-0", "space-y-1", "space-y-2", "space-y-3", "space-y-4", "space-y-5", "space-y-6", "space-y-7", "space-y-8", "space-y-9", "space-y-10", ];


    const [Errstatus, setErrstatus] = useState(false)
    const [loading, setloading] = useState(true)
    const [order, setOrder] = useState([]);
    const [fileds, setfileds] = useState({});
    const [Errmsg, setErrmsg] = useState('');

    useEffect(() => {  fetchCampaign(Params.id) }, [status])

    const fetchCampaign = async (id) => {  

        if(isEmpty(id)) return;

        setloading(true)
        
        const Campaign = (renderType==="template-preview") ? await getTemplate(id) : await getCampaign(id);

        let SiteId = await getScriptData(Campaign); 
        
        if (Campaign.success) {   

           setOrder(JSON.parse(Campaign.data.order))

           setfileds(JSON.parse(Campaign.data.fileds))

            document.title=Campaign?.data?.name ?? "GoZen Engage"

            Dispatch(Actions.insertSettings(Object.assign(Campaign.data,{renderType:renderType,scriptSiteId:SiteId})))

            Dispatch(Actions.putCampaignSettings( JSON.parse(Campaign.data?.settings ?? "") ))

            setloading(false) 


        if(renderType==="liveRender"){

            const DEviceData = await GetDEviceInfo()

            const CampEvent = new CampaignEvents({campaignId:id,site_id:SiteId, primary_Site_id:Campaign?.data?.site_id})  

            CampEvent.viewed(DEviceData)

        } else {

            console.log("In Preview");

        }

        }
        if(!Campaign.success) {
            setErrstatus(true);
            setErrmsg(Campaign?.message)
            setloading(false)
        }
    }

    const getScriptData =  (Campaign)=>{
        return new Promise(async (resolve, reject) => {   
            let campScriptData;
            if(renderType==="liveRender") campScriptData=await GetScriptCampInfo();
            let campaign_id=Campaign?.data?.campaign_id, site_id=Campaign?.data?.site_id;
            let new_campID=campScriptData?.campaign_id, new_siteID=campScriptData?.site_id;

            if(isNotEmpty(new_campID) && isNotEmpty(new_siteID)){
                if(isEqual(new_campID,campaign_id)){
                site_id= new_siteID
                }else console.log("Engage Campaign ID not match!")
            }
                
            resolve(site_id)

    }) }


    return (
        <section className="w-full h-full flex flex-col relative">

            {
                // loading ? <LoadingSpinner text={'Loading..'} className="my-auto" /> :
                loading ? <EngageLoading/> :

                    <div className={`w-full h-full relative ${spaceBetween[spaceBetweenTheBlock]}`} style={{ background: ( backgroundType == "color") ? (color ?? "#7ED321") : `url(${ image ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat`, backgroundSize: "cover",fontFamily:fontFamily }} >

                        { 

                        !Errstatus ?

                        <RenderComponents list={order} fileds={fileds} /> : 

                        <RenderErrorContent msg={Errmsg} />

                        }

                    </div>

            }

        </section>
    )

}

export default Main;