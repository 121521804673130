import { isEmpty } from "../Components/Utils/helper";
import Axios from "./axios";
import { winMsgStatCookie } from "./messanger";

export class CampaignEvents {
    constructor(prop) {
        this.campaignId = prop.campaignId
        this.site_id = prop.site_id
        this.primary_Site_id = prop.primary_Site_id
    }

    setheader(){
        return {
            headers:{
                "x-ps-id":this.primary_Site_id
            }
        }
    }
  
    viewed(payload) {
        return new Promise (async (resolve,reject)=>{
            try{
                let scriptElmId=payload?.deviceInfo?.scriptElmId
                delete payload.deviceInfo.scriptElmId

                let render_path= payload?.deviceInfo?.render_path
                if(isEmpty(render_path)) render_path="/"
                else delete payload.deviceInfo.render_path
               
                //const res = await Axios.get(`http://localhost:8080/v1/campaign/analytics?site_id=${this.site_id}&campaign_id=${this.campaignId}&view_count=1`,this.setheader());
                const res = await Axios.post(`/campaign/set/analytics?site_id=${this.site_id}&campaign_id=${this.campaignId}&view_count=1&render_path=${render_path}`, payload ,this.setheader());
               
                if(res.status === 200){
                    winMsgStatCookie({siteId:this.site_id,campaignId:this.campaignId,cookieType:"viewed",scriptElmId:scriptElmId})
                     resolve(res.data);
                }

            }catch(err){
                console.log(err);
                // reject(err);
                resolve({success:false,message:"Something went wrong!",data:{}});
            }
        })
    }

  
    clicked(payload) {
        return new Promise (async (resolve,reject)=>{
            try{
                let scriptElmId=payload?.deviceInfo?.scriptElmId
                delete payload.deviceInfo.scriptElmId

                let render_path= payload?.deviceInfo?.render_path
                if(isEmpty(render_path)) render_path="/"
                else delete payload.deviceInfo.render_path

                const res = await Axios.post(`/campaign/set/analytics?site_id=${this.site_id}&campaign_id=${this.campaignId}&click_count=1&render_path=${render_path}`, payload ,this.setheader());

                if(res.status === 200){ 
                    winMsgStatCookie({siteId:this.site_id,campaignId:this.campaignId,cookieType:"clicked",scriptElmId:scriptElmId})    
                    resolve(res.data);
                }

            }catch(err){
                console.log(err);
                // reject(err);
                resolve({success:false,message:"Something went wrong!",data:{}});
            }
        })
    }

    subcripe(payload,recaptcha_token, DEviceData ) {

        payload['customer'] = '';
        payload['campaign_id'] = this.campaignId;
        payload['site_id'] = this.site_id;
        payload['accepts_marketing'] = '1';
        let deviceInfo = DEviceData?.deviceInfo || {}
        
        let scriptElmId=deviceInfo?.scriptElmId
        delete deviceInfo?.scriptElmId
        
        let render_path= deviceInfo?.render_path
        if(isEmpty(render_path)) render_path="/"
        else delete deviceInfo?.render_path
        payload['render_path'] = render_path;

        return new Promise (async (resolve,reject)=>{
            try{
    
                const res = await Axios.post(`/client/user/subscribe?recaptcha_token=${recaptcha_token}` , {...payload,deviceInfo}, this.setheader());

                if(res.status === 200){ 
                    winMsgStatCookie({siteId:this.site_id,campaignId:this.campaignId,cookieType:"subscribed",scriptElmId:scriptElmId})
                    resolve(res.data); 
                }
    
            }catch(err){
              //  console.log(err);
                // reject(err);
                resolve({success:false,message:"Something went wrong!",data:{}});

            }
        })
    } 

    
}