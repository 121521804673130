import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


export const TimesUpBanner = ({
    ResultSrc = null,
    bannerAction = null,

}) => {

    const { campaignSettings } = useSelector(state => ({ campaignSettings: state.campaignSettings }));

    const { timesupImg } = campaignSettings;


    const [contentClosing, setcontentClosing] = useState(false);

    const [ color, setcolor ] = useState("radial-gradient(64.33% 112.71% at 50% 50%, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0.2948) 100%)")

    const [start, setStart] = useState(false);

    useEffect(() => {

        GameStartACtion()

    }, [start])


    const GameStartACtion = () => {

        setTimeout(() => { setStart(true); }, 200);

        setTimeout(() => { 
            setcolor("radial-gradient(64.33% 112.71% at 50% 50%, rgba(255, 244, 244, 0) 0%, rgba(255, 255, 255, 0) 100%) ");
            setcontentClosing(true); 
        }, 900);

        setTimeout(() => { ResultSrc();bannerAction(false) }, 2000);

    }

    return (

        <div className={` transform transition-all duration-700 ${!start ? "bg-[#00000001]" : contentClosing ? "bg-[#00000001]" : "bg-[#00000040]"} absolute flex flex-col left-0 h-full top-0 w-full overflow-auto `} style={{ zIndex: '11' }} >

            <div className={` absolute transform transition-all duration-500 ${!start ? 'scale-0' : 'scale-100'}  w-full h-full min-h-[300px] flex flex-col  px-8 py-10 space-y-6 my-auto mx-auto justify-center `} >

                <img src={timesupImg??"/asset/times-up.png"} className={ ` transition-all transform duration-500 ${contentClosing ? "scale-0" : "scale-100"}  max-w-[50%] flex m-auto`} alt="" />

            </div>

        </div>
    )

}