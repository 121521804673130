import Input from "../Common/input/input"
import { Rangeinput } from "../Common/input/rangeInput"


export const CalCulatorQueRender = ({ Settings = {}, fontSize="medium", questionObj={}, device="desktop", UserAction=null, UserAns={}, RequiredErr=false, fieldRequied }) => {
    const QueSize = {
        "small": "text-[10px]",
        "medium": "text-[12px]",
        "large": "text-[18px]",
    }

    const parSize = {
        "small": "text-sm",
        "medium": "text-base",
        "large": "text-[18px]",
    }

    const ButtonSize = {
        "small": "text-[14px]",
        "medium": "text-[16px]",
        "large": "text-[18px]",
    }
    return (
        <div className="h-fit space-y-6" >

            <p className={`w-full transition-all duration-700 text-lg font-medium mx-auto text-white ${QueSize[fontSize]} `} style={{ color: Settings?.primaryColor ?? "white" }} >{questionObj?.question}{questionObj?.required && <span>*</span>}</p>

            {
                questionObj?.InPutType === 'range' ?

                    <div className=" w-full h-[80px]">
                        <Rangeinput step={questionObj?.step ?? 1}  primaryColor={Settings?.primaryColor ?? "white"} secondaryColor={Settings?.secondaryColor ?? "back"} minValue={questionObj?.range?.min ?? 0} defaultValue={UserAns[questionObj?.question]} maxValue={questionObj?.range?.max ?? 100}  OnChange={(e) => UserAction(e.target.value, questionObj?.question)} />
                        {/* <input type="range" className="w-full text-purple-600 h-4" max={100} min={0} defaultValue={PayLoad[questionObj?.question] ? PayLoad[questionObj?.question] : "" } onChange={(e)=>UserAction(e.target.value,questionObj?.question)} /> */}
                    </div>

                    :

                    questionObj?.InPutType === 'multioption' ?

                        <div className={`grid w-full  ${device === "mobile" ? "grid-row" : "grid-cols-2"} px-4 gap-4 mx-auto`}>
                            {
                                questionObj?.options?.map((listobj, Innerindex) => {

                                    let isAnswed = UserAns[questionObj?.question] === listobj.value ? true : false

                                    return (
                                        <div className={`cursor-pointer flex px-4 py-3 border-1 min-w-[150px] rounded-xl text-lg ${(isAnswed) ? "bg-white" : "bg-[rgba(250,250,250,0.2)]"}  text-white font-medium space-x-3 `}
                                            style={{ background: isAnswed ? Settings?.primaryColor ?? "white" : Settings?.primaryColor + "30" ?? "#FFFFFF30", borderColor: Settings?.primaryColor ?? "white" }}
                                            onClick={() => {
                                                UserAction(listobj.value, questionObj?.question)
                                            }} >
                                            <div className={`my-auto cursor-pointer ${(isAnswed) ? "border-white bg-black" : ""} rounded-full w-5  h-5 border-1`}
                                                style={{ background: isAnswed ? Settings?.secondaryColor ?? "black" : Settings?.primaryColor + "30" ?? "#FFFFFF30", borderColor: isAnswed ? Settings?.secondaryColor ?? "black" : Settings?.primaryColor ?? "white" }}
                                            >
                                                <div className={` rounded-full w-2.5 h-2.5 m-1  ${(isAnswed) ? "bg-white" : ""}`}
                                                    style={{ background: isAnswed ? (Settings?.primaryColor ?? "white") : "" }}

                                                ></div>
                                            </div>
                                            <p className={`cursor-pointer ${(isAnswed) ? "text-black" : ""}`}  >{listobj.key}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :

                        <div className="flex flex-col space-y-2.5">
                            
                            <Input style={{ color: Settings?.primaryColor ?? "white" }} margin={''} type={questionObj?.InPutType} textsize={parSize[fontSize]} textvalue={UserAns[questionObj?.question]} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="Enter Value" background='bg-transparent' onchange={(e) => UserAction(e.target.value, questionObj?.question, questionObj?.InPutType)}  />
                            {/* <hr className="transition-all border-white" /> */}
                            <hr className="transition-all border-white" style={{ borderColor: Settings?.primaryColor ?? "white" }} />

                        </div>
            }
            {RequiredErr && <div className="flex w-full px-4 space-x-4">
                <p className="flex space-x-2 bg-[rgb(248,231,232)] text-[rgb(203,70,74)] p-2 w-fit rounded-lg">
                    <span className="flex my-auto"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6663 11.6797 14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301ZM7.99967 11.333C7.63301 11.333 7.33301 11.033 7.33301 10.6663V7.99967C7.33301 7.63301 7.63301 7.33301 7.99967 7.33301C8.36634 7.33301 8.66634 7.63301 8.66634 7.99967V10.6663C8.66634 11.033 8.36634 11.333 7.99967 11.333ZM8.66634 5.99967H7.33301V4.66634H8.66634V5.99967Z" fill="#CB464A"></path></svg></span>
                    <span className="flex my-auto">{fieldRequied}</span>
                </p>
            </div>}

        </div>
    )
}