import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Utils/helper";


export const Logo = ({ fieldID = '', fieldsobj }) => {


    const LogoContent = fieldsobj 
    const LogoPosition = String(LogoContent?.filedContent?.Position).toLowerCase()
 

    return (
        <div id={"gameFrameBorder"+fieldID}  className={`relative bg-white flex flex-col py-6 group `}>
       

            <div className={`flex w-fit h-fit max-w-full mx-auto py-4 ${LogoPosition === 'right' ? "justify-end" : LogoPosition === 'left' ? 'justify-start' :"justify-center" }`} >
                <img  alt="Logo"
                    src={isEmpty(LogoContent?.filedContent?.imageSrc) ? "/asset/images/templateCollect/Match_the_Animal_Names.png" : LogoContent?.filedContent?.imageSrc} 
                    className={'w-auto h-auto flex object-cover'} style={{width:LogoContent?.filedContent?.Scale+'%',borderRadius:LogoContent?.filedContent?.Radius+"px"}}
                    />
            </div>
        </div>

    )
}