import { configureStore, createSlice } from "@reduxjs/toolkit";

const cunerSlice = createSlice({
    name: "Engage",
    initialState: {
        campaign_id:"",
        category: "",
        name: "",
        primary_Site_id:"",
        site_id:"",
        status:"",
        renderType:"",
        allPayload:{},
        userDetail:{},
        campaignSettings:{
            "fontSize":"medium",
            "fontFamily":"DM Sans",
            "spaceBetweenTheBlock":2,
            "backgroundType":"image",
            "image":"",
            "color":"#ffffff",
            "letsStartImg":"/asset/lets-start.png", 
            "timesupImg":"/asset/times-up.png", 
            "readyImg":"/asset/ready.png", 
            "PlayImg":"/asset/play.png",
            "defaultContent":{
                "backButtonContent":"Previous",
                "nextButtonContent":"Next",
                "scoreContent":"Score",
                "TimingContent":"your Timing",
                "TimerContent":"Timer",
                "NOAContent":"Number of action",
                "correctAnswer":"Correct Answer is",
                "nextQuestion":"Next Question",
                "totalVote":"Total Vote",
                "voteCount":"Vote Count",
                "nextQuestionButtonContent":"Next Question",
                "questionsBlockcontent":"Questions",
                "answeredBlockcontent":"Answered",
                "fieldRequied":"This Field is Requied",
                "invaildNumber":"Invaild number",
                "invaildEmail":"Invaild Email"
            }
        },
    },
    reducers: {  
        insertSettings(state,action){    
            state.campaign_id=action.payload.campaign_id
            state.category=action.payload.category
            state.primary_Site_id=action.payload.site_id
            state.site_id=action.payload.scriptSiteId
            state.status=action.payload.status 
            state.renderType=action.payload.renderType 
            state.allPayload=action.payload 
        },
        insertUserDetails(state,action){
            state.userDetail=action.payload.userDetail
        },
        updateAditionalInfo(state,action){
            state.allPayload.aditional_info=action.payload.aditionalInfo
        },
        putCampaignSettings( state, action ) {

            const Settings =  action.payload;

            let GetKeys =  Object.keys( Settings );

            let GetSCkeys=  Object.keys( Settings?.defaultContent ?? {} );

            GetKeys.map( list => {

                if( list !== "defaultContent" ) state.campaignSettings[ list ] = Settings[ list ];

            } )

            GetSCkeys.map( list => {

                state.campaignSettings.defaultContent[ list ] = Settings?.defaultContent[ list ];
                
            } )

        },

    },

})

export const Actions = cunerSlice.actions;

export const store = configureStore({
    reducer: cunerSlice.reducer,
});