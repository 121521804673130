import axios from "axios"; 

let baseURL = process.env.NODE_ENV === "development" ?  
//  "https://api.dev.optinly.gozen.io/v1"
"http://localhost:8086/v1"
: process.env.REACT_APP_BACKEND_ENDPOINT ;

let Axios = axios.create( { baseURL } );

Axios.interceptors.request.use(config => {
    config.headers["x-gz-platform"] = "engage"; 
    return config;
}) 

export default Axios;