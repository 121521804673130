export const StringToHtml = ( {str=""} ) => {
  function resizeIframe(e) {
    let obj=e?.target,
    height=(obj?.contentWindow?.document?.documentElement?.scrollHeight || 200);
    if(height>1200) height=1200;
    obj.style.height = height + 'px';
  }

  return (
  
      <iframe
      key={str}
       url="about:blank"
        title="Embedded Content"
        width="100%"
        height="100%"
         srcDoc={str}
        onLoad={resizeIframe}
      ></iframe>
      
    
  );
}
