import React, { useEffect, useState, useImperativeHandle } from "react"; 
import { element} from "../../../Utils/helper";
import "../../Common/commonStyle.css"; 

const FindPair = ( { data },ref ) => { 
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data

  const Layout = Settings?.layputRange;
  const LayoutRange = Layout.split("x"); 
  const noOfCards = LayoutRange[0] * LayoutRange[1]; 
  const [cardArrangement, setCardArrangement] = useState([]); 
  const [checkPair, setCheckPair] = useState("");
  const [active, setActive] = useState([]);
  const [matchedPair, setMatchedPair] = useState([]);
  const [trigger, settrigger] = useState(false);
  const [isToShow, setisToShow] = useState(false);
  const [score, setScore] = useState(0); 
 
  useEffect(() => { if (Settings?.pairItems.length === noOfCards) gameSetup();  }, [Settings.pairItems, trigger]);

  useImperativeHandle(ref, () => ({ 
    GameStart: () => {   setMatchedPair([]); setActive([]); setScore(0);settrigger(!trigger);  },
    getScore : ()=>{return score}
   })); 


  const gameSetup = () => {
    setScore(0);
    let card = [];
    //console.log(card.length, noOfCards);
    while (card.length < noOfCards) {
      let RandomNumber = Math.round(Math.random() * noOfCards);
      if (card.indexOf(RandomNumber) < 0 && RandomNumber !== noOfCards)
        card.push(RandomNumber);
    } 
    setCardArrangement(card);
   setLoadContent(false);
   // if(Settings?.filpstatus)
  }; 

  const flidcard = (e, value, index) => {    
    setActionCount((obj) => obj + 1); 
    setActive((obj) => [...obj, index]); 
 
    if (checkPair === "") setCheckPair(value);
    else {
      if (checkPair === value && active.indexOf(index) < 0) { setMatchedPair((obj) => [...obj, value]); setScore(obj => obj+1); } 
      setTimeout(() => {
        //console.log(matchedPair.length + 1, noOfCards / 2 )
        if (matchedPair.length + 1 === noOfCards / 2) {GameFinsh(true); }
        setCheckPair("");  setActive([]);  }, 700);
    }
  
  }; 
  return ( 
      <div  className={`w-[90%] sm:w-[85%] md:w-[75%] m-auto h-[60%] sm:h-[75%] min-h-[250px] min-w-[250px] md:h-[85%] gap-[1rem] sm:gap-[1.5rem] md:gap-[2rem] grid flex-col`}   style={{  gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`,    gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)`,  }} id={"gamePanel"+DIVID} >
        { 
 (!Settings?.filpstatus && isToShow) ?
  [...new Array(noOfCards)].map((list, index) => {
    let CardObj = Settings?.pairItems[list]; 
    if (CardObj)
      return (
        <div key={index} style={{backgroundColor:Settings?.filpCardBgColor || "#DCE0E4"}} className={`
        rounded-[10px!important] md:rounded-[16px!important] p-[1.2rem] sm:p-[2rem]  
         gamecard transition-all duration-700 ${!Settings?.filpstatus ? "activecard" : ""}  `} data-active="0" >
          <div className={`gamecardfront   rounded-[10px!important] md:rounded-[16px!important]  `}  >
            <span className="flex m-auto p-1 sm:p-2 md:p-3 max-w-full max-h-full ">
              <img  draggable={false} src={ Settings?.filpCardBgImage === ""   ? "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/basket.png" : Settings?.filpCardBgImage }  alt="test" className=" w-full h-auto max-h-full p-1 md:p-2 rounded-[20px] noselect" />
            </span>
          </div>
          <div className={`gamecardback w-full    rounded-[10px!important] md:rounded-[16px!important]    `} >
            <span className="flex m-auto p-1 sm:p-2 md:p-3 max-w-full max-h-full">
              {CardObj.type === "image" ? (  
              <img draggable={false} src={CardObj.src} alt="test" className=" noselect" /> ) : (
              <p  className={ "w-fit max-w-full max-h-full  text-center m-auto outline-none resize-none overflow-hidden capitalize  text-sm sm:text-lg font-medium noselect"} >{CardObj.content}  </p>
              )}
            </span>
          </div>
        </div>
      );
  }) :




          (cardArrangement.map((list, index) => {
            let CardObj = Settings?.pairItems[list]; 
            if (CardObj)
              return (
                <div key={index} style={{backgroundColor:Settings?.filpCardBgColor || "#DCE0E4"}} className={`
                rounded-[10px!important] md:rounded-[16px!important]  p-[1.2rem] sm:p-[2rem]  
                 gamecard transition-all duration-700 ${!Settings?.filpstatus ? "activecard" : ""}  ${ matchedPair.indexOf(CardObj.pairValue) > -1 || active.indexOf(index) > -1 ? "activecard" : ""  } ${!Settings?.filpstatus && (matchedPair.indexOf(CardObj.pairValue) > -1 || active.indexOf(index) > -1) ? "border-4 border-green-400 " : ""} `} data-active="0" onClick={(e) => flidcard(e, CardObj.pairValue, index)}  >
                  <div className={`gamecardfront rounded-[10px!important] md:rounded-[16px!important]    `}  >
                    {/* <span className="gamecardinner m-auto "> */}
                    <span className="flex m-auto p-1 sm:p-2 md:p-3 max-w-full max-h-full">
                      <img  draggable={false} src={ Settings?.filpCardBgImage === ""   ? "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/basket.png" : Settings?.filpCardBgImage }  alt="test" className="noselect w-full h-auto max-h-full p-1 md:p-2 rounded-[20px]" />
                    </span>
                  </div>
                  <div className={`gamecardback w-full  rounded-[10px!important] md:rounded-[16px!important]     `} >
                    <span className="flex m-auto p-1 sm:p-2 md:p-3 max-w-full max-h-full">
                      {CardObj.type === "image" ? (  
                      <img draggable={false} src={CardObj.src} alt="test" className=" noselect" /> ) : (
                      <p  className={ "w-fit max-w-full max-h-full  text-center m-auto outline-none resize-none overflow-hidden text-sm sm:text-lg font-medium noselect text-ellipsis"} >{CardObj.content}  </p>
                      )}
                    </span>
                  </div>
                </div>
              );
          })
          )
        }
      </div> 
  );
};

export default React.forwardRef(FindPair);
