import React, { useEffect, useState } from "react";
import { isNotEmpty } from "../Utils/helper";
import WebFont from 'webfontloader' 

export const Title = ({fieldID='',fieldsobj}) => {

    const titleContent = fieldsobj; 
    const font=isNotEmpty(titleContent?.filedContent?.FontFamily)? titleContent?.filedContent?.FontFamily : "ABeeZee"
    
    useEffect(()=>{
        WebFont.load({ google : { families : [font] }})  
    },[])  

    return (

        <div className={` relative bg-white flex flex-col py-6 group  `}>

            <h2 className={`w-4/5 mx-auto font-normal sm:font-medium capitalize  text-center`} 
            style={{color:titleContent?.filedContent?.TextColor,fontFamily:font}} >
                { titleContent?.filedContent?.Content === '' ? '10X Your Sales And Revenue With an Organic Growth Platform Powered by AI' : titleContent?.filedContent?.Content}  </h2>

        </div>

    )

}
 