import { useEffect, useState } from "react";

export const RatingIcon = ({ 
    StarCount=5,
    isActive = false,
    primaryColor='white',
    slectedindex= 0 ,
    color='', 
    className, 
    style, 
    strokeColor="", 
    strokeWidth="",
    fillColor ="#ffffff",
    height,
    width,
    OnClick=null
}) => {
    let Data = []
    // const [setfile,setsetfile] = useState(isActive ? primaryColor !=="white"? primaryColor +"30" : "#FFFFFF30":"#FFFFFF30")
    // useEffect(()=>{
    //     if(isActive) setsetfile(primaryColor)
    // },[isActive])
    for (let i=0;i<StarCount;i++){
        Data.push(0)
    }
    return (
        <div className="flex space-x-2">
        {
            Data.map((list,index) => {
                // let setfile =  isActive ? primaryColor !=="white"? primaryColor + "30" : "#FFFFFF30":"#FFFFFF30"
                // console.log(setfile,isActive)
                return(
                    <span 
                    onClick={()=>OnClick(index)}
                    >
                    <svg
                    className={`${!isActive ? "text-primary" : color} ${className} cursor-pointer`}
                    style={style}
                    width={width ?? "28"}
                    height={height ?? "28"}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.0001 17.27L16.1501 19.78C16.9101 20.24 17.8401 19.56 17.6401 18.7L16.5401 13.98L20.2101 10.8C20.8801 10.22 20.5201 9.12001 19.6401 9.05001L14.8101 8.64001L12.9201 4.18001C12.5801 3.37001 11.4201 3.37001 11.0801 4.18001L9.19007 8.63001L4.36007 9.04001C3.48007 9.11001 3.12007 10.21 3.79007 10.79L7.46007 13.97L6.36007 18.69C6.16007 19.55 7.09007 20.23 7.85007 19.77L12.0001 17.27Z"
                        fill={  slectedindex > index ? primaryColor : primaryColor !=="white"? primaryColor +"30" : "#FFFFFF30"
                    }
                        stroke={strokeColor}
                        strokeWidth={strokeWidth}
                    />
                </svg>
                </span>
                )
            })
        }

        </div>
    );
};