import React, { useState, useEffect, useImperativeHandle } from "react";
import { QuizOptionBtn, YesOrNoOptionBtn } from "./OptionBtn";
import { mixArray, isEqual, element, isEmpty, getImageOrignalSize, isNotEmpty } from "../../../Utils/helper";
import "../../Common/commonStyle.css";
import { getImageBase64 } from "../../../../backend/templates";
import { AnswerPopup } from "./AnswerPopup";
import { useDispatch, useSelector } from "react-redux";

const GameQuiz = ({ data }, ref) => {
  const { Settings, setActionCount, GameFinsh, setLoadContent, DIVID, setStartTime } = data

  const { campaignSettings } = useSelector((state) => ({ campaignSettings: state.campaignSettings }));

  const { fontSize } = campaignSettings;

  const [curQuizIndex, setCurQuizIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionBase64, setQuestionsBase64] = useState([])
  const progressStep = (100 / questions.length).toFixed(2);
  const [fideout, setFideOut] = useState(false)


  const [UserAns, setUserAns] = useState({});

  const [showAnswerbanner, SetShowAnswerBanner] = useState(false)

  let PayLoad = {};

  useImperativeHandle(ref, () => ({
    GameStart: () => { gameSetup(); },
    getScore: () => { return score }
  }));




  const QueSize = {
    "small": "text-base md:text-lg lg:text-xl",
    "medium": "text-lg md:text-xl lg:text-2xl",
    "large": "text-xl md:text-2xl lg:text-[26px]",
  }



  const parSize = {
    "small": "text-base",
    "medium": "text-lg",
    "large": "text-xl",
  }

  useEffect(() => { if (Settings?.questions.length > 0) gameSetup(); }, []);
  useEffect(() => { if (questions.length !== 0 && curQuizIndex >= questions.length) { GameFinsh(true) } }, [curQuizIndex]);


  function preLoad() {
    return new Promise(async (resolve, reject) => {
      setLoadContent(true)
      let temp = Settings?.questions, newList = [];
      setQuestions(temp);

      for (let i = 0; i < temp?.length; i++) {
        // let size=isEmpty(temp[i].image)? [300,300]:await getImageOrignalSize(temp[i].image); 
        //   let base64 = isEmpty(temp[i].image)? "" : await getImageBase64(temp[i].image,  size[0],size[1]);     
        // newList.push({...temp[i],base64});
        // setQuestionsBase64(newList)
        setLoadContent(false);
      }
    })
  }

  const gameSetup = async () => {
    setQuestions([]); setQuestionsBase64([]); setLoadContent([]); setCurQuizIndex(0); setScore(0); setProgress(0);

    await preLoad();
  }


  const checkAnswer = async (userAnswer) => {

    setProgress(parseInt(progress) + parseInt(progressStep));

    UserAction(userAnswer, questions[curQuizIndex]?.question, questions[curQuizIndex]?.optionType ?? "")

    if (isEqual(userAnswer.toUpperCase(), (questions[curQuizIndex]?.answer).toUpperCase())) setScore(score + 1);

    SetShowAnswerBanner(true)

    // setCurQuizIndex(curQuizIndex + 1);

    setActionCount(curQuizIndex)

  };

  const UserAction = (value, type, quetype = "") => {


    PayLoad = Object.assign(PayLoad, UserAns)

    if (quetype === "email") PayLoad["email"] = value

    PayLoad[type] = value

    setUserAns(PayLoad)

  }

  let imageUrl = questions[curQuizIndex]?.image

  const nextAction = () => {

    setFideOut(true)

    setTimeout(() => {
      setCurQuizIndex(curQuizIndex + 1);
      SetShowAnswerBanner(false);
      setFideOut(false)
    }, [700])


  }
  return (
    <>

      {showAnswerbanner && <AnswerPopup questionObj={questions[curQuizIndex]} AnswerPayload={UserAns} scoreVal={score} totalQuestion={questions?.length ?? 5} forwardAction={nextAction} />}


      <div className=" w-full inner-frame p-6 h-full smMax:p-4  relative m-auto overflow-y-auto" id={"gamePanel" + DIVID} >

        <div className="w-full h-6 smMax:h-4 bg-gray-200 rounded-full dark:bg-gray-700 grid justify-items-start mb-11 smMax:mb-9"> <div className="h-6 smMax:h-4 bg-blue-600 rounded-full dark:bg-blue-500" style={{ width: progress + "%", transition: "width 2s", background: Settings?.primaryColor ?? "white" }} ></div></div>

        {/* Hided because too much scroll makes the game ugly
            <div className="flex flex-col h-[95%] w-4/5 m-auto  overflow-y-auto"> */}

        <div className="flex flex-col h-[95%] w-4/5 m-auto ">

          <div className={` transition-all duration-500 ${fideout ? "opacity-0" : "opacity-100"} flex flex-col w-full h-fit space-y-4 my-auto`} >

            <div className="flex flex-col w-full h-fit space-y-4 my-auto" >

              {(imageUrl !== "") && <div className={`flex mx-auto`}>  <img src={imageUrl} alt="" className=" w-  rounded-xl max-h-[150px]" /> </div>}

              <div className={`${QueSize[fontSize]} question font-semibold lg:font-bold  mb-5 smMax:mb-8 flex  justify-center `} style={{ color: Settings?.primaryColor ?? "white" }} >  {questions[curQuizIndex]?.question ?? questions[curQuizIndex]?.quistion}  </div>


              {questions[curQuizIndex]?.optionType === "yerorno" ? <YesOrNoOptionBtn className=" inset-x-0 bottom-0 h-16 mt-[3rem]" style={{ marginBottom: "17em" }} checkAnswer={checkAnswer}></YesOrNoOptionBtn> :
                <QuizOptionBtn className=" inset-x-0 bottom-0 " style={{ marginBottom: "17em" }} textColor={Settings?.secondaryColor ?? "black"} BgColor={Settings?.primaryColor ?? "white"} checkAnswer={checkAnswer} options={questions[curQuizIndex]?.options}></QuizOptionBtn>
              }</div>
          </div>

        </div>
      </div>
    </>
  );
};

export default React.forwardRef(GameQuiz);