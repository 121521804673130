import React from "react";
import { useNavigate } from "react-router-dom";

export const Button = ({ fieldID = '', fieldsobj }) => {


    const ButtonContent = fieldsobj; 
    const filedContent = ButtonContent?.filedContent


    return (

        <div id={"gameFrameBorder"+fieldID}  className={`relative bg-white flex flex-col py-6 group `} >


            <button className={`flex mx-auto  W-fit py-3 px-4 border-2 `} 

            onClick={() => window.location.href = filedContent?.RedirUrl === "" ? "https://gozen.io" : filedContent?.RedirUrl }
            
            style={{
                background:`${filedContent?.BgColor}`,
                borderColor:`${filedContent?.BorderColor}`,
                borderRadius:`${filedContent?.Radius}`,
                color:`${filedContent?.TextColor}`
            }}

             >
                
                { filedContent?.text  ?? "Visit" }

            </button>
        </div>

    )
}