// Inner circle size definition
export const innerGroupSize = (groupWidth)=>{
let data={"strokeWidth":6,"edgeX":(groupWidth/2-(groupWidth/100 * 0.7)), "charCircle":95, "charFont":26, "charPos":{x:-11,y:-13}}; 

    if(groupWidth>150) {data={"strokeWidth":6,"edgeX":(groupWidth/2-(groupWidth/100 * 0.7)), "charCircle":95, "charFont":26, "charPos":{x:-11,y:-13}}}
    if(groupWidth>160) {data={"strokeWidth":6,"edgeX":(groupWidth/2-(groupWidth/100 * 10)), "charCircle":92, "charFont":26, "charPos":{x:-11,y:-13}}}
    if(groupWidth>170) { data={"strokeWidth":6,"edgeX":(groupWidth/2-(groupWidth/100 * 0.5)), "charCircle":98, "charFont":27, "charPos":{x:-11,y:-13}}}
    if(groupWidth>180) {data={"strokeWidth":8, "edgeX":(groupWidth/2-(groupWidth/100 * 4)), "charCircle":117, "charFont":30, "charPos":{x:-12,y:-14} }}
    if(groupWidth>220){ data={"strokeWidth":8,"edgeX":(groupWidth/2-(groupWidth/100 * 5)), "charCircle":123, "charFont":33,"charPos":{x:-12,y:-14}  }}
    if(groupWidth>250){data={"strokeWidth":9, "edgeX":(groupWidth/2-(groupWidth/100 * 8)), "charCircle":123, "charFont":35, "charPos":{x:-12,y:-14}  } }
    if(groupWidth>300){  data={"strokeWidth":9, "edgeX":(groupWidth/2-(groupWidth/100 * 24)),    "charCircle":100, "charFont":33,"charPos":{x:-12,y:-14}  }} //for 2000 px width
return data;
}

// Inner circle position definition
export const innerGroupPos = (maxLetter, groupWidth, edgeX, height)=>{
    let groupPosArr=[]
    if(maxLetter===3)groupPosArr=[{x:groupWidth-edgeX,y:height/2+40}, {x:groupWidth,y:height/2-edgeX}, {x:groupWidth+edgeX,y:height/2+40}]
    if(maxLetter===4 || maxLetter===5)groupPosArr=[{x:groupWidth-edgeX,y:height/2}, {x:groupWidth,y:height/2-edgeX}, {x:groupWidth+edgeX,y:height/2}, {x:groupWidth,y:height/2+edgeX},]
    if(maxLetter===5) groupPosArr.push({x:groupWidth,y:height/2})
    return groupPosArr;
}

export const outerCircleRadius = (width,gamePanelWidth)=>{ 
    let radius= (width/2)-17 
    // outer circle desktop max radius 
    if(radius>160) radius= 160; 
    if(gamePanelWidth<640 )  radius=103;  
    if(gamePanelWidth<540 )  radius=120; 
    if(gamePanelWidth<440 ) radius=110;  
    return radius;
}