import React, { useState, useEffect } from "react";
export const CountUpTimer = ({
  actionCount = 0,
  StartTime = false,
  setSecond = null,
  setMin = null
}) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 8);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const hours = Math.floor(time / 360000);

  const minutes = Math.floor((time % 360000) / 6000);

  const seconds = Math.floor((time % 6000) / 100);

  const milliseconds = time % 100;



useEffect(()=>{ 
  if(StartTime)setTime(0);
  setIsRunning(StartTime); },[StartTime])
useEffect(()=>{ setTime(0); },[])
useEffect(()=>{ setSecond(seconds); setMin(minutes); },[time])
  

  const reset = () => {
    setTime(0);
  };

    const startAndStop = () => {
      setIsRunning(!isRunning);
    };
    
  return (
<div className="w-full flex justify-between py-4 px-3 border-1 rounded-lg bg-white mb-4">
<p className="noselect">
  Number of action : <span>{actionCount}</span>
</p>
<div className="flex my-auto ">
  <p className="space-x-2 capitalize noselect">
    timer :{" "}
    <span className="w-6 inline-block">{minutes < 10 ? `0${minutes}` : minutes}</span>
    <span>:</span>
    <span className="w-6 inline-block">
      {seconds < 10 ? `0${seconds}` : seconds}
    </span>
  </p>
  </div>
</div>
  );
};