import React, { useState, useEffect, useImperativeHandle } from "react"; 
import WordSearch from '@blex41/word-search';
import { element, uniqueArray, isNotEmpty, isEqual,isEmpty, longestWordLengthInArray} from "../../../Utils/helper";  
import "./style.css";
import "../../../Common/commonStyle.css";
const FindWords = ({ data },ref) => {  
  const {Settings,setActionCount,GameFinsh,setLoadContent,DIVID} = data
  
  var words = Settings.words, key = 0
  var BOX =longestWordLengthInArray(Settings.words); BOX= BOX<8 ? 8 : BOX
  words = words.map(f => { return (f.substring(0, BOX)).toLowerCase(); }); 
  const [score, setScore] = useState(0);  
  const [puzzleArray, setPuzzleArray] = useState([ ]);   
  const [isFinding , setFinding]=useState(false)  
  const [idArray , setIdArray]=useState([]) 
  const [CrtCardArray , setCrtCardArray]=useState([]) 
  const [answered , setAnswered]=useState([]) 
  const [answeredType , setAnsweredType]=useState(["",null]) 
  const [timeTrigger , setTimeTrigger]=useState(0) 

  useImperativeHandle(ref, () => ({ 
  GameStart: () => {    gameSetup(); },
  getScore : ()=>{return score}
  })); 
  useEffect(()=>{  gameSetup();  },[])
  
  const gameSetup = () => { 
    CrtCardArray.map((val,inx)=>{   element(val).style.backgroundColor=Settings?.backgroundContainer })
    words.map((val, inx)=>{  element("words_"+val+DIVID).className="  w-fit py-1.5  capitalize noselect"; })
    setScore(0); setPuzzleArray([]);  
     setFinding(false);    setIdArray([]);   
     setCrtCardArray([]); setAnswered([]); loadMaze();   };   

  function loadMaze() {   
    const options = {
      cols: BOX,
      rows: BOX, 
      dictionary: words,
      maxWords: words?.length,
      backwardsProbability: 0.3,
      upperCase: true,
      diacritics: true
    }; 
    const ws = new WordSearch(options);
    setPuzzleArray(ws.data.grid)  
    setLoadContent(false);
}

function getDirection(A,B){ // it used to get the diretion of 2 cell
  if(isEmpty(A) || isEmpty(B)) return 
  A = A.split("_"); 
  B = B.split("_");  
let direction, a=A[3].split("X"), b=B[3].split("X"); a[0]=parseInt(a[0]); a[1]=parseInt(a[1]); b[0]=parseInt(b[0]); b[1]=parseInt(b[1]);
// U-UP D-DOWN R-RIGHT L-LEFT 
if(a[1]===b[1]) direction="LR"; else // --
if(a[0]===b[0]) direction="UD"; else  // |
if(a[0]+1===b[0] && a[1]+1===b[1]) direction="ULR"; else  // \
if(a[0]-1===b[0] && a[1]+1===b[1]) direction="URL"; else  // / 
if(a[0]-1===b[0] && a[1]-1===b[1]) direction="DRL"; else  // \  
if(a[0]+1===b[0] && a[1]-1===b[1]) direction="DLR";else // / 
direction="NOT"  
return direction
}
 

useEffect(()=>{ 
  if(answeredType[0]==="Click"){ 
    let difference=(Date.now()-answeredType[1])/1000  
    if(difference>=0.8){ 
      checkAnswer()
      setIdArray([]);  
    }  
  }
},[timeTrigger,answeredType])
 


  function mouseDown(e, textChar ){   
     setAnsweredType(["Click",Date.now()])   
    let id="mainCard_"+DIVID+"_"+(e?.target)?.getAttribute("val"); 
    if(isEmpty(id)) return;      
    let temp=idArray; temp.push(id); setIdArray(temp)  
    element(id).style.backgroundColor=Settings?.pointer;
    setFinding(true); 
  }

  function mouseOver(e, textChar ){    
    if(isFinding){ 
      setAnsweredType(["Drag",null])   
    let id="mainCard_"+DIVID+"_"+(e?.target)?.getAttribute("val");   
    if(isEmpty(id)) return; 
    let temp=idArray; temp.push(id); setIdArray(temp) 
    if (isFinding)  element(id).style.backgroundColor=Settings?.pointer;
    else  setIdArray([]) 
    }
  }

  function onMouseUp( ){  
    setFinding(false); 
   if(isFinding && answeredType[0]==="Drag"){   
   setIdArray(idArray);
 checkAnswer(); 
  setIdArray([]);  
  }  
  }
 
  function charClicked(id){ 
   if(answeredType[0]==="Click"){   
    let temp=idArray; temp.push(id); setIdArray(temp)    
    setAnsweredType(["Click",Date.now()])
    setTimeout(()=>{setTimeTrigger(obj => obj+1)},800)
   }

  }
  
 
  function checkAnswer (){ 
    setActionCount(obj=>obj+1);
    let isCorrect=false,isCrtDirection=true, FinalAnswer="", uniqIdArr= uniqueArray(idArray)
    let directions=[]  
   
  // The Final Answer got by looking the unique id's text-val and Checking the direction is correct
   uniqIdArr.map((val,inx)=>{  
    let temp=element(val)?.getAttribute("text-val") 
    if(uniqIdArr.length>=2)  directions.push(getDirection(uniqIdArr[inx],uniqIdArr[inx+1]));  
    else isCrtDirection=true 
    if(isNotEmpty(temp))  FinalAnswer+=temp
  })    
  
  // if used direction is one the direction is correct
  if(uniqIdArr.length>=2 && directions.length>0){
    let tempDir=uniqueArray(directions) 
    if(tempDir.includes("NOT")) isCrtDirection=false  
    else isCrtDirection=(tempDir.length>1)? false:true   
  } 
  
  // If the answer is correct pussing to the Answered array
   if(isCrtDirection){
    words.map((val, inx)=>{   
       if(isEqual(val,(FinalAnswer).toLowerCase())){  
    element("words_"+val+DIVID).classList.add("strikeLine"); 
    let temp=answered; temp.push(val);  
    temp=uniqueArray(temp); setAnswered(temp)
    isCorrect=true; return true;}})
       }
   
    // animating after checking the answer
    if(isCorrect && isCrtDirection){ 
    setScore(obj => obj+1)
    animate("correct") 
    let temp=CrtCardArray; temp= temp.concat(idArray); setCrtCardArray(temp)
    }else  animate("wrong")  
  
    //if Answered array is full stoping the game
  if(answered.length===words.length)  GameFinsh(true) 
  } 
  
  function animate(type){
    idArray.map((val,inx)=>{     if(isNotEmpty(val)){ 
    let elem=element(val); elem.style.animation=((type==="wrong")?`wrongTextSelection`:`correctTextSelection`)+` 1s linear 1`;  
    setTimeout(function(){ elem.style.animation="";
          elem.style.backgroundColor=((type==="wrong")?Settings?.backgroundContainer:"#acffb3"); 
          elem.style.backgroundColor=((type==="wrong")?"transparent":"#acffb3"); 
          CrtCardArray.map((val,inx)=>{   element(val).style.backgroundColor="#acffb3" })  }, 1000); 
    }})
    }

  return ( 
  <div className="flex flex-col sm:flex-row mb-4  h-full items-center"   id={"gamePanel"+DIVID}  >
  
  <div className="w-[100%] sm:w-[30%]  h-fit flex flex-row sm:flex-col  gameSimpleScroll overflow-y-hidden ">
    {words.map((word, index) => { return (
    <div className={` w-3/5 mx-auto  space-x-1 flex flex-col space-y-5 min-w-[80px] sm:min-w-none`} key={"word" + (key += 1)}>
    <p className="flex font-medium text-lg text-white capitalize justify-center" >
      
    <span className=" w-fit py-1.5  capitalize noselect"  id={"words_" + word + DIVID}>{word}</span>

      </p>
    </div>
    ); })}</div>

    <div className="w-[80%] sm:w-[100%]  overflow-auto gameSimpleScroll my-auto   "> 
    <div className="masterGameArea h-fit w-[100%]"  style={{ "cursor": "pointer", borderRadius: "5px" }}  onMouseUp={(e)=>{onMouseUp()}}
      onMouseLeave={()=>{onMouseUp()}}>
    <br/>
    <div className={` w-full grid h-full flex-col  rounded-lg`} style={{  background:Settings?.backgroundContainer,  gridTemplateColumns: `repeat(` + BOX + `,minmax(0,1fr)`  }}> 
      {(puzzleArray.length>0)&&  puzzleArray?.map((ival,row)=>{  
      return <span key={"wordRow"+row} className="grid font-semiblod" style={{ gridTemplateRows: `repeat(` + BOX + `,minmax(0,1fr)` }}> {ival?.map((jval,column)=>{
      
      return  <div className="noselect flex "  key={row+"X"+column} val={row+"X"+column} text-val={jval} id={"mainCard_"+DIVID+"_"+row+"X"+column}
      onMouseDown={(e)=>{ mouseDown(e,  jval )}} onClick={()=>charClicked("mainCard_"+DIVID+"_"+row+"X"+column)}> 

      <span className=" puzzleText  m-auto flex p-[10%]" val={row+"X"+column} 
      onMouseOver={(e)=>{mouseOver(e,  jval )}}
      > { jval}</span></div> 
      })}</span>
      })
      } 
    </div> 
    </div>
    </div>
  </div> 
  );
};

export default React.forwardRef(FindWords); 