import React from "react";
import PrimaryButton from "./Button/PrimaryButton";


export const WelComePageNonGame = ({
    title = 'Start the Game',
    buttenText = 'start',
    StartAction = null,
    gaemstatus = false,
    primaryColor='white',
    secondaryColor="black",
    WelComePageObJ = {},
    background = {}
}) => {
    return (
        <div className={` ${gaemstatus ? 'hidden' : ''}  w-full h-full flex`} style={{ background: background?.type == "color" ? background?.color ?? "#7ED321" : `url(${background?.src ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat` }}>
            <div className="relative w-full h-full bg-[rgba(0,0,0,0.3)] flex" >

                <div className="w-full flex flex-col text-white  rounded-2xl px-8 py-10 space-y-4 my-auto mx-16 justify-center">
                    {(WelComePageObJ?.imageUrl !== "") && <div className="max-w-[360px] min-w-[280px] mx-auto rounded-xl">
                        <img src={WelComePageObJ?.imageUrl} alt={"thumbnail"} className="w-full rounded-xl" />
                    </div>}
                    <h3 className="text-center" style={{color:primaryColor}} >{title}</h3>
                    {(WelComePageObJ?.descriptionContent !== "") && <p className="mx-auto text-center" style={{color:primaryColor}} >{WelComePageObJ?.descriptionContent}</p>}
                    <PrimaryButton text={buttenText} height="min-h-[40px] max-h-[100px]" width={'min-w-[110px] max-w-[60%]'} margin={'mx-auto'} style={{color:secondaryColor,background:primaryColor,borderColor:primaryColor}} fontStyle="font-semibold" classNames={"border-1 border-white overflow-auto break-words"} backgroundColor="bg-white" textcolor="text-black" textsize={'text-[16px]'} OnClick={StartAction} />
                </div>
            </div>
        </div>

    )
}