import React, { useState } from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

export const ThenAndNow = ({fieldID='',fieldsobj}) => { 
    return (
        <div  className="w-full
        p-3
        sm:p-5
        ">
<ReactCompareSlider  position={50}
 className="w-full  
 h-[20rem]
 sm:h-[30rem]
 md:h-[36rem]
 lg:h-[40rem]
 xl:h-[48rem]"
  itemOne={<ReactCompareSliderImage src={fieldsobj?.filedContent?.leftImageSrc} style={{filter: 'contrast(200%) grayscale(1)'}} alt="Image one" />}
  itemTwo={<ReactCompareSliderImage src={fieldsobj?.filedContent?.rightImageSrc}  alt="Image two" />}
/>
</div>

    )

}