import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
export const Ridir = () => {
    const Navigate = useNavigate()
    useEffect( () => {
        window.location.href='https://gozen.io/engage'

    },[])
     

    return null
}