

import React, { useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
 

export const SlideShow = ({fieldID='',fieldsobj}) => { 
    const Settings={
        items:fieldsobj?.filedContent?.images,
        infinite:fieldsobj?.filedContent?.isInfinity,
        showPlayButton:fieldsobj?.filedContent?.showPlayButton,
        showThumbnails:fieldsobj?.filedContent?.showThumbnail,
        slideDuration:fieldsobj?.filedContent?.slideDuration,
        thumbnailPosition:fieldsobj?.filedContent?.thumbnailPosition,
        showNav:fieldsobj?.filedContent?.showNavButton,
        showFullscreenButton:fieldsobj?.filedContent?.showFullScreenButton,
        isRTL:fieldsobj?.filedContent?.isRightToLeft,
        showIndex:fieldsobj?.filedContent?.showIndex,
        autoPlay:fieldsobj?.filedContent?.autoPlay,
        slideInterval:fieldsobj?.filedContent?.playIntervel,
        showBullets:true
    }
    return (
        <div  className="w-full   p-3  sm:p-5  ">
 

<ImageGallery  {...Settings}/>



</div> 
    )

}