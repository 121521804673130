import React, { useState } from "react";
import { EditorTitle, Title } from "./title";
import { Description, EditorDescription } from "./description";
import { ThenAndNow } from "./ThenAndNow";
import { SlideShow } from "./SlideShow";
import { Timeline } from "./Timeline";
import { Image } from "./image";
import { isNotEmpty } from "../Utils/helper";
import { Video } from "./video";
import { AudioRe } from "./AudioRe";
import { Logo } from "./Logo";
import { Button } from "./Button";
import { Email } from "./Email";
import { Embed } from "./Embed";

export const UnTemplatetypeBlock = ({ fiedlId, filedsObj }) => {

    const [templateType,settemplateType] = useState(filedsObj?.type)
    const [DisplayView,setDisplayView] = useState((!filedsObj?.filedContent?.DisplayView))
    const [templateFieldContent,settemplateFieldContent] = useState(filedsObj?.filedContent)  
    
    return (
        <>
            {
                        templateType === "title" && DisplayView  ? <Title fieldID={fiedlId} fieldsobj={filedsObj} /> : 
                        templateType === "description" && DisplayView ? <Description fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "image" && DisplayView  ? <Image fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "audio" && (!filedsObj?.filedContent?.DisplayView) ? <AudioRe fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "video" && DisplayView    ? <Video fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "logo" && DisplayView    ? <Logo fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "embed" && DisplayView && isNotEmpty(filedsObj?.filedContent?.EmbedCode)    ? <Embed fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "button" && DisplayView    ? <Button fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "email" && DisplayView    ? <Email fiedlId={fiedlId} fieldsobj={filedsObj}  /> : 
                        templateType === "thenAndNow"? <ThenAndNow fiedlId={fiedlId} fieldsobj={filedsObj}  /> :                        
                        templateType === "slideShow"? <SlideShow fiedlId={fiedlId} fieldsobj={filedsObj}  /> :                        
                        templateType === "timeline"? <Timeline fiedlId={fiedlId} fieldsobj={filedsObj}  /> :                        
                        null
            }
        </>
    )
}