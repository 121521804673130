import { percentageToVal } from "../../../Utils/helper";


const MatchSvg = (props) => {  
  let iconWidth=props.iconSize.width, iconHeight=props.iconSize.height
  return (<svg width="100%" height="100%" id={"matching_svg_" + props.DIVID} className=" rounded-md  h-full"> 
         
  {props.puzzleX?.map((val, inx) => {
  let padding=percentageToVal(iconHeight,12);

   return <g key={inx}>   
      <g  onClick={e => props.onOptionSelect("matchCircleA_" + inx + "_" + props.DIVID, inx, 0)} className="noselect cursor-pointer">

            {val?.type === "image" ? <g>

  <defs> <pattern id={"image"+inx} x="22%" y="23%" height="100%"  width="100%" viewBox="0 0 950 950"> <image x="0%" y="0%" width="512" height="512" xlinkHref={val?.src}></image>  </pattern> </defs>
  <circle  cx={iconWidth/2}    cy={iconHeight * inx + iconHeight / 2}  width={iconWidth} height={iconHeight-padding} r={(iconHeight-padding)/2} fill={`url(#image${inx})`} stroke="#EDEFF1" strokeWidth="1px" />

              {/* <svg x="0" width={iconWidth} height={iconHeight-padding} y={(iconHeight * inx)+padding}>
              <circle cx="50%" cy="50%" r="20%" stroke="#EDEFF1" stroke-width="1" fill="#FFF"/>*/}

       
              {/* <image className="noselect" x="50%" y="0" width={"50%"} height={"90%"} inx={inx}  xlinkHref={val?.src} />  */}
              {/* </svg> */} 
              {/* <image className="noselect" inx={inx} x="0" width={iconWidth} height={iconHeight-padding} y={(iconHeight * inx)+padding} xlinkHref={val?.src} />  */}

            </g> : <g>
              <rect className="noselect"  fill="white" fill-opacity="0"  x="0" width={iconWidth} height={iconHeight} y={iconHeight * inx}></rect> 
              {/* <text className="noselect" x={iconWidth / 2 - iconWidth / 12} width={iconWidth} height={iconHeight / 2} y={iconHeight * inx + iconHeight / 2}>{val?.content}</text>  */}
                
            <switch>
            {/* SVG inside HTML used because to break the words */}
            <foreignObject  x="0" width={iconWidth} height={iconHeight} y={iconHeight * inx}>
            <div style={{height:"100%",display:"flex", justifyContent:"center", alignItems:"center", padding:"9px"}}><p xmlns="http://www.w3.org/1999/xhtml" className={props.device==="mobile"? "text-base" : "text-md"} style={{wordBreak:"break-word"}}>{val?.content}</p></div>
            </foreignObject>

            {/* If html not supported, the below code will run */}
            <text className="noselect" x={iconWidth / 2 - iconWidth / 12} width={iconWidth} height={iconHeight / 2} y={iconHeight * inx + iconHeight / 2}>{val?.content}</text> 
            </switch>

            </g>}  

            {/* <circle id={"matchCircleA_" + inx + "_" + props.DIVID} cx={iconWidth+  percentageToVal(iconWidth ,8)} cy={iconHeight * inx + iconHeight / 2} r="6" 
              fill={(val?.isCorrect)? "rgb(172,255,179,1)" : ((val?.selected || props.selected[0][1]===inx )? "#e17c7c" : "#DCE0E4") } /> */}
              <circle id={"matchCircleA_" + inx + "_" + props.DIVID} cx={iconWidth + percentageToVal(iconWidth, 8)} cy={iconHeight * inx + iconHeight / 2}  r="7" fill="#DCE0E4"/>
              <circle cx={iconWidth + percentageToVal(iconWidth, 8)} cy={iconHeight * inx + iconHeight / 2}  r="3.2" fill={(val?.isCorrect) ? "rgb(172,255,179,1)" : ((val?.selected || props.selected[0][1] === inx) ? "#e17c7c" : "white")}/>

      </g>

      <g onClick={e => props.onOptionSelect("matchCircleB_" + inx + "_" + props.DIVID, inx, 1)} className="noselect cursor-pointer">

          {props.puzzleY[inx]?.type === "image" ?  <g>
            
            
  <defs> <pattern id={"image2"+inx} x="22%" y="23%" height="100%"  width="100%" viewBox="0 0 950 950"> <image x="0%" y="0%" width="512" height="512" xlinkHref={props?.puzzleY[inx]?.src}></image>  </pattern> </defs>
  <circle  cx={(iconWidth * 2 )+(iconWidth/2)}    cy={iconHeight * inx + iconHeight / 2 }  r={(iconHeight-padding)/2} fill={`url(#image2${inx})`} stroke="#EDEFF1" strokeWidth="1px" />



            {/* <image  className="noselect" inx={inx} x={iconWidth * 2 -  percentageToVal(iconWidth ,3)} width={iconWidth} height={iconHeight-padding} y={(iconHeight * inx)+padding} xlinkHref={props?.puzzleY[inx]?.src} />  */}
          </g> : <g>
          <rect fill="white" fill-opacity="0" className="noselect" x={iconWidth * 2} width={iconWidth} height={iconHeight} y={iconHeight * inx}></rect>
          {/* <text className="noselect" x={iconWidth * 2 + iconWidth / 3} width={iconWidth} height={iconHeight / 2} y={iconHeight * inx + iconHeight / 2}>{props?.puzzleY[inx]?.content}</text>  */}
            
          <switch>
          {/* SVG inside HTML used because to break the words */}
          <foreignObject  x={iconWidth * 2} width={iconWidth} height={iconHeight} y={iconHeight * inx}>
          <div style={{height:"100%",display:"flex", justifyContent:"center", alignItems:"center", padding:"9px"}}><p xmlns="http://www.w3.org/1999/xhtml" className={"text-base sm:text-md"} style={{wordBreak:"break-word"}}>{props?.puzzleY[inx]?.content}</p></div>
          </foreignObject>

          {/* If html not supported, the below code will run */}
          <text className="noselect" x={iconWidth * 2 + iconWidth / 3} width={iconWidth} height={iconHeight / 2} y={iconHeight * inx + iconHeight / 2}>{props?.puzzleY[inx]?.content}</text> 
          </switch>

          </g>}
                    
          {/* <circle id={"matchCircleB_" + inx + "_" + props.DIVID} cx={iconWidth * 2 - iconWidth / 8} cy={iconHeight * inx + iconHeight / 2} r="6" 
          fill={(props?.puzzleY[inx]?.isCorrect)? "rgb(172,255,179,1)" :((props?.puzzleY[inx]?.selected|| props.selected[1][1]===inx )? "#e17c7c" : "#DCE0E4") } />
          */}
          <circle id={"matchCircleB_" + inx + "_" + props.DIVID} cx={iconWidth * 2 - iconWidth / 8} cy={iconHeight * inx + iconHeight / 2}  r="7" fill="#DCE0E4"/>
          <circle cx={iconWidth * 2 - iconWidth / 8} cy={iconHeight * inx + iconHeight / 2}  r="3.2" fill={(props?.puzzleY[inx]?.isCorrect) ? "rgb(172,255,179,1)" : ((props?.puzzleY[inx]?.selected || props.selected[1][1] === inx) ? "#e17c7c" : "white")}/>

          </g> 

   </g>;
  })}
       
          
</svg>);
}



export default MatchSvg;