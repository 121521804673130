import React, { useState } from "react";
import { CampaignEvents } from "../../backend/campaignEvents";
import { useSelector } from "react-redux";
import { GetDEviceInfo } from "../../backend/GetDeviceInfo";
import Input from "../Template/Common/input/input";
import { isEmpty } from "../Utils/helper";


export const Email = ({ fieldID = '', fieldsobj }) => {

    const {campaignID,siteId,renderType, primary_Site_id} = useSelector((state) => ({campaignID:state.campaign_id,siteId:state.site_id,renderType:state.renderType,primary_Site_id:state.primary_Site_id}))
    const [formobject,setformobject] = useState({}) 
    const [emailFrame,setemailFrame] = useState(true) 



    const ButtonContent = fieldsobj; 
    const filedContent = ButtonContent?.filedContent


    const OnSSubmitForm = async (e) => {

        e.preventDefault()
         
        if(renderType==="liveRender"){
            const CampaignEvent = new CampaignEvents({campaignId:campaignID,site_id:siteId, primary_Site_id:primary_Site_id})
            const DEviceData = await GetDEviceInfo()
            CampaignEvent.subcripe(formobject,encodeURIComponent(e),DEviceData )
        } 
        setemailFrame(false)
        // setTimeout(()=>{
        //     setemailFrame(true)
        // },3000)

    }

    const Onchange = (e,name) => {        
        let lowercasename = String(name).toLocaleLowerCase()
        let temp = formobject
        temp[lowercasename] = e.target.value;
        setformobject(temp) 
    }
    

    let opts={}
     if(filedContent?.Required) opts["required"]="required"
    let reqMesg=isEmpty(filedContent?.RequiredMessage)? "Kindly enter your email." :filedContent?.RequiredMessage

    function validate(e){
        if(filedContent?.Required)  e.target.setCustomValidity(reqMesg);
    }

    return (
        
        <div id={"gameFrameBorder"+fieldID}  className={`relative bg-white flex flex-col justify-center items-center py-6 group h-[160px] `}  >

        {emailFrame ? 
        <form className="w-full  h-full "  onSubmit={OnSSubmitForm} >
            
                <div className="w-1/2 flex flex-col mx-auto" >

                <Input  onInvalid={validate} ClassName="h-12" Placeholder={filedContent?.Placeholder} {...opts}  onchange={(e)=>Onchange(e,'email')} />

            <button className={` rounded-lg W-full py-3 px-4 border-2 `}  type="submit"    style={{
                background:`${filedContent?.BgColor ?? "#000"}`,
                borderColor:`${filedContent?.BgColor ?? "#000"}`,
                color:`${filedContent?.TextColor?? 'white'}`
            }}  >
                
         { filedContent?.buttonText  ?? "Visit" }

            </button>
            </div>
            </form>
:
        <div className="flex justify-center items-center fadeInUp text-[#06152D] font-bold text-[18px] sm:text-[22px]">
        <svg  className="mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256" style={{fill:"#40C057"}}>  <g fill="#40c057" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: "normal"}}><g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g></svg> 
        Thank you for subscribing!
        </div>
}

        </div>
    )
}