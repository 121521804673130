import { RenderErrorContent } from "./Common/errorBlock";
import { TemplatetypeBlock } from "./Template";
import { UnTemplatetypeBlock } from "./Untemplate"; 

const RenderComponents = ({list=[], fileds={}} ) => { 
  return (
    <>
      
      {

        list?.length !== 0 ? list?.map((list,index) => {

              if(fileds[list] && fileds[list].templatetype === 'template' )  return(<TemplatetypeBlock key={"TemplatetypeBlock_"+index} fieldID={list} filedsObj={fileds[list]} />)
              
              else return(<UnTemplatetypeBlock key={"UnTemplatetypeBlock"+index}  fiedlId={list} filedsObj={fileds[list]}/>)
        
            }) :
        
        <RenderErrorContent msg ={'Game Fields Empty'} />
      
      }
   
   </>
  )

 
}
export default RenderComponents; 
